import store from '@/store/index.js';
// import util from '@/common/util.js';
import {
	apisEnum
} from '@/common/http.api.js';
export function getUserInfo() {
	if (!store.getters.token) {
		return
	}
	apisEnum.CommonApi.getDictTree({
		parentId: 0
	}).then((res) => {
		store.commit("user/SET_DICT", JSON.stringify(res))
	})
	apisEnum.UserApi.getUserDetail().then(res => {
		store.commit('user/SET_SELF', res)
		// apisEnum.MessageApi.getUnreadMessagesNumber().then(
		// 	() => {
		// 		util.setBadgeNumber()
		// 	});
	})
}