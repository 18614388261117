/**
 * 无障碍改造接口
 */
export default class BarrierFreeApi {
  constructor(vm) {
    this.vm = vm
  }

  // 查询当前用户的无障碍改造记录（分页）
  getMineApplicationList(params = {}) {
    return this.vm.$u.get('/api/remould/village/person/detail', params)
  }

  // 根据id查询无障碍改造主数据
  getBarrierFreeDetailById({ id }) {
    return this.vm.$u.get(`/api/remould/by/${id}`)
  }
}
