<template>
  <div class="parent_rate_img" :style="{height:vh+'px'}">
    <vue-drag-resize
      :w="vw"
      :x="left"
      :parentLimitation="parentLimitation"
      :y="top" :z="vZ"
      :h="vh"
      :isDraggable="isDraggable"
      :isActive="isActive"
      v-on:resizing="resize"
      v-on:dragging="resize"
    >　
      <div class="box" :style="{width: + vw+ 'px',height:+vh+'px'}">
        　　　　<img :src="srcUrl" style="width: 100%;height: 100%">
      </div>
    </vue-drag-resize>
  </div>
</template>

<script>
  //移动端-缩放图片
  export default {
    name: "rateImg",
    props:{
      srcUrl:{
        type:String
      },
      isDraggable:{
        type: Boolean,
        default: true
      },
      isActive:{
        type: Boolean,
        default: true
      },
      parentLimitation :{
        type: Boolean,
        default: false
      },
      vTop:{
        type: Number,
        default: 0
      },
      vHeight:{
        type: Number,
        default: 200
      },
      vWidth: {
        type: Number,
        default: 200
      },
      index: {
        type: Number,
        default: -1
      },
     vZ:{
        type: Number|String,
        default: 3000
      }
    },
    data() {
      return {
        vw: 0,
        vh: 0,
        top: _.clone(this.vTop),
        left: 10
      };
    },
    created() {
      this.vw = 200;
      this.vh = _.clone(200/this.vWidth*this.vHeight)
    },
    methods:{
      //缩小
      resize(newRect) {
        this.vw = newRect.width;
        this.vh = newRect.height;
        this.top =  newRect.top
        this.left = newRect.left<10?10:newRect.left;
        this.$emit("getRect", { width: this.vw, height: this.vh,index:this.index });
      },
    }

  }
</script>

<style scoped scoped>
  .parent_rate_img{
    position: relative;
  }
</style>
