<template>
  <canvas
    ref="canvas"
    canvas-id="handWriting"
    class="signature__content"
    @touchstart="touchStart"
    @touchmove="touchMove"
    @touchend="touchEnd"
  ></canvas>
</template>

<script>
import Handwriting from "../../common/signature"
// 移动端-手写画板
export default {
  name: 'sz-ensign',
  props: {
    value: {
      type: Object,
      default: () => {
        return null
      }
    },
    width: {
      type: Number,
      default: 800
    },
    height: {
      type: Number,
      default: 300
    },
    lineWidth: {
      type: Number,
      default: 20
    },
    lineColor: {
      type: String,
      default: '#000000'
    },
    bgColor: {
      type: String,
      default: ''
    },
    isCrop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      resultImg: '',
      isDrawing: false,
    }
  },
  mounted() {
    this.handwriting = new Handwriting({
      lineColor: this.lineColor,
      slideValue: this.lineWidth,
      canvasName: 'handWriting',
    })
  },
  methods: {
    // mobile
    touchStart(e) {
      this.handwriting.uploadScaleStart(e)
    },
    touchMove(e) {
      this.handwriting.uploadScaleMove(e)
    },
    touchEnd(e) {
      this.isDrawing = true
      this.handwriting.uploadScaleEnd(e)
    },

    // 操作
    generate() {
      if (!this.isDrawing) return this.$u.toast('请先写好签名')
      return new Promise((resolve, reject) => {
        this.handwriting.saveCanvas().then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    reset() {
      this.handwriting.retDraw()
      this.isDrawing = false
    }
  }
}
</script>

<style scoped scoped>
  canvas {
    max-width: 100%;
    display: block;
  }

  .signature__content {
    width: 100%;
    background: #fff;
  }
</style>
