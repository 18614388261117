import { render, staticRenderFns, recyclableRender, components } from "./sz-select.vue?vue&type=template&id=073f0c25&scoped=true&"
var renderjs
import script from "./sz-select.vue?vue&type=script&lang=js&"
export * from "./sz-select.vue?vue&type=script&lang=js&"
import style0 from "./sz-select.vue?vue&type=style&index=0&id=073f0c25&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@dcloudio/vue-cli-plugin-uni/packages/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073f0c25",
  null,
  false,
  components,
  renderjs
)

export default component.exports