import { render, staticRenderFns, recyclableRender, components } from "./quick-reply.vue?vue&type=template&id=1d5197b1&scoped=true&"
var renderjs
import script from "./quick-reply.vue?vue&type=script&lang=js&"
export * from "./quick-reply.vue?vue&type=script&lang=js&"
import style0 from "./quick-reply.vue?vue&type=style&index=0&id=1d5197b1&lang=less&scoped=true&"
import style1 from "./quick-reply.vue?vue&type=style&index=1&id=1d5197b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@dcloudio/vue-cli-plugin-uni/packages/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d5197b1",
  null,
  false,
  components,
  renderjs
)

export default component.exports