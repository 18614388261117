import 'uni-pages';import 'uni-h5';import Vue from 'vue'
import App from './App'
import store from "./store";
import uView from "uview-ui";
import component from "@/components";
import config from "./config";
import "@/common/ArrayPrototype";
import "@/common/DatePrototype";
import "font-awesome/css/font-awesome.min.css";
import "./router";
import './elementConf'
import "@/config/axiosConfig";
import VueDragResize from 'vue-drag-resize'; //缩放、拖拽
import VueCompositionAPI from '@vue/composition-api' //视频通话

import MescrollBody from "@/components/mescroll-uni/mescroll-body.vue";
import MescrollUni from "@/components/mescroll-uni/mescroll-uni.vue";
import tagInput from "@/components/form-designer/tag-input"

Vue.use(config);
Vue.use(uView);
Vue.use(component);
Vue.component('vue-drag-resize', VueDragResize)
Vue.component("mescroll-body", MescrollBody);
Vue.component("mescroll-uni", MescrollUni);
Vue.component("tag-input", tagInput);
Vue.use(VueCompositionAPI)

Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
App.mpType = 'app'

const app = new Vue({
  ...App,
  store
})

import httpInterceptor from "@/common/http.interceptor.js";
import util from "@/common/util.js";
import Enum from "@/common/enum.js"

// 这里需要写在最后，是为了等Vue创建对象完成，引入"app"对象(也即页面的"this"实例)
Vue.use(httpInterceptor, app);
Vue.use(util, app);
Vue.use(Enum, app);

import httpApi from "@/common/http.api.js";

Vue.use(httpApi, app);

app.$mount()