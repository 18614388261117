/**
 * 辅助器具接口
 */
export default class ApplianceApi {
  constructor(vm) {
    this.vm = vm
  }

  // 查询残疾人器具适配活动的列表
  getAssistActivityList(params) {
    return this.vm.$u.get('/api/fore/assist/activity/info/list', params);
  }

  // 帮他人申请-查询残疾人器具适配活动的列表
  getHelpOtherAssistActivityList(params) {
    return this.vm.$u.get('/api/bak/assist/activity/info/show/running/activity', params);
  }

  // 根据当前残疾人查询其下可以选择的的辅助器具分类
  getAppliance({ id, userId }) {
    if (userId) {
      return this.vm.$u.get(`/api/fore/assist/equ/info/get/disabledType/category/${id}?userId=${userId}`, {}, { 'role': 'SERVER' });
    } else {
      return this.vm.$u.get(`/api/fore/assist/equ/info/get/disabledType/category/${id}`);
    }
  }

  // 帮他人申请--根据当前残疾人查询其下可以选择的的辅助器具分类
  getHelpOtherAppliance({ id, userId }) {
    return this.vm.$u.get(`/api/fore/assist/equ/info/get/disabledType/category/${id}?userId=${userId}`);
  }


  // 发起器具适配申请
  applicationAppliance({ activityId, body }) {
    return this.vm.$u.post(`/api/fore/assist/equ/apply/info/apply/${activityId}`, body)
  }

  // 发起器具适配申请
  helpOtherApplicationAppliance({ activityId, body, interFaceCate}) {
    return this.vm.$u.post(`/api/fore/assist/equ/apply/info/apply/${activityId}?interFaceCate=${interFaceCate}`, body)
  }
  // 帮他人申请-查询残疾人列表
  getHelpPerson(params){
    return this.vm.$u.get('/api/bak/assist/activity/info/get/person/by/params', params);
  }

  // 查询我发起的器具申请
  getMineApplicationList(params = {}) {
    return this.vm.$u.get('/api/fore/assist/equ/apply/info', params);
  }

  // 根据申请的id查询辅助器具申请的详情
  getApplicationDetailById({ applyId, role }) {
    if (role) return this.vm.$u.get(`/api/fore/assist/equ/apply/info/detail/${applyId}`, {}, { 'role': 'SERVER' });
    return this.vm.$u.get(`/api/fore/assist/equ/apply/info/detail/${applyId}`);
  }

  // 根据服务商id查询申请列表（分页）
  getServerApplicationList(params = {}) {
    return this.vm.$u.get('/api/bak/assist/equ/apply/info/serverList', params, { 'role': 'SERVER' });
  }

  // 现场拍照和图文记录-备货
  serviceProviderDelivery({ applyId, body }) {
    return this.vm.$u.post(`/api/bak/assist/equ/apply/info/photo/record/${applyId}`, body, { 'role': 'SERVER' })
  }

  //  现场拍照和图文记录-鉴定
  serviceProviderDelivery1({ applyId, body }) {
    return this.vm.$u.post(`/api/bak/assist/equ/apply/info/institute/photo/record/${applyId}`, body, { 'role': 'SERVER' })
  }

  //  驳回鉴定
  turnDownProvider({ applyId, body }) {
    return this.vm.$u.post(`/api/bak/assist/equ/apply/info/institute/back/${applyId}`, body, { 'role': 'SERVER' })
  }

  // 服务商器具适配申请
  serviceProviderAppliance({ activityId, body }) {
    return this.vm.$u.post(`/api/fore/assist/equ/apply/info/apply/server/${activityId}`, body, { 'role': 'SERVER' })
  }

  // 服务商根据适配活动id查询辅助器具申请列表
  getServiceProviderHandle(params = {}) {
    return this.vm.$u.get(`/api/bak/assist/equ/apply/info/apply/userList/service/${params.activityId}`, params, { 'role': 'SERVER' })
  }

  // 查询服务商残疾人器具适配活动的列表（分页）
  getServiceActivities(params = {}) {
    return this.vm.$u.get('/api/fore/assist/activity/info/list/service', params, { 'role': 'SERVER' })
  }
}
