/**
 * 微信API接口
 */
import wx from "weixin-js-sdk";

export default class WxApi {
  constructor(vm) {
    this.vm = vm;
    this.appId = window.api.wxAppId;
  }

  // 跳转微信授权地址 获取code
  getWxCode(redirect = null) {
    const redirect_url = redirect || window.location.origin;
	
	console.log('redirect_url',redirect_url);
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
      this.appId
    }&redirect_uri=${encodeURIComponent(
      redirect_url
    )}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
  }

  // 通过code获取微信用户信息
  getWxUserInfo({ code }) {
    return this.vm.$u.get(`/api/wechat/user/info/${code}`);
  }

  // 获取微信js-sdk签名
  async getWxSign(api) {
    // 兼容ios
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    const isIos = !!u.match(/\(i[^;]+;(u;)? CPU.+Mac OS X/);
    let url = null;
    if (isAndroid) url = window.location.href;
    if (isIos) url = window.location.href.split("#")[0];

    let {
      nonceStr,
      signature,
      timeStamp,
    } = await this.vm.$u.get("/api/wechat/sign", { url });
    wx.config({
      // debug:true,
      appId: this.appId,
      timestamp: timeStamp,
      nonceStr: nonceStr,
      signature: signature,
      jsApiList: [...api],
    });
  }
}
