<template>
  <section>
    <div style="display: flex;">
      <el-input
        :placeholder="placeholder"
        v-model="userNames"
        v-if="!isHiddenInput"
        class="input-with-select"
        :disabled="true"
      >
      </el-input>
      <el-button @click="showUserDialog" :disabled="readonly" class="add_btn" :class="[readonly?'add_btn_readonly':'']"
                 icon="el-icon-circle-plus-outline">
      </el-button>
    </div>

    <el-dialog
      :title="title"
      append-to-body
      class="user_tree"
      ref="user_select_dialog"
      :visible.sync="sortDialogVisible"
      v-if="sortDialogVisible"
      destroy-on-close
    >
      <el-row>
        <el-col :span="24">
          <el-form inline>
            <el-input placeholder="请输入内容" v-model="searchCondition.keyword" clearable style="margin-top: 10px">
              <el-button slot="append" icon="el-icon-search" @click="searchUser"></el-button>
            </el-input>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="tabConfigData.showAllOrganizeName" name="All"
                         v-if="tabConfigData.showAllOrganize"></el-tab-pane>
            <el-tab-pane :label="tabConfigData.showCustomOrganizeName" name="Ann"
                         v-if="tabConfigData.showCustomOrganize"></el-tab-pane>
            <el-tab-pane :label="tabConfigData.showAllWorkGroupName" name="WorkGroup"
                         v-if="tabConfigData.showAllWorkGroup"></el-tab-pane>
            <el-tab-pane :label="tabConfigData.showPersonalWorkGroupName" name="MyWorkGroup"
                         v-if="tabConfigData.showPersonalWorkGroup"></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col style="min-width: 300px;">
          <div class="border_node_btn" style="height:480px;margin-top: 10px;">
            <el-tree
              :show-checkbox="isMulSelect"
              node-key="id"
              ref="tree_all"
              key="tree_all"
              class="left_tree"
              :filter-node-method="filterNode"
              highlight-current
              lazy
              :load="loadNode"
              v-if="searchCondition.keyword===''&&activeName==='all'"
              :expand-on-click-node=false
              :default-expanded-keys="leftDefaultExpandKeys"
              @node-expand="leftTreeNodeExpand"
              @node-collapse="leftTreeNodeCollapse"
              @node-click="nodeClick"
              @check-change="leftCheckChange"
              :props="defaultProps">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <i :class="icoClass(data)"></i><span style="margin-left: 5px;">{{ data.name }}</span><span
                v-show="data.type==='org'">({{data.userCount}}人)</span>
              </span>
            </el-tree>
            <el-tree
              :show-checkbox="isMulSelect"
              node-key="id"
              ref="tree_custom"
              key="tree_custom"
              class="left_tree"
              :filter-node-method="filterNode"
              highlight-current
              lazy
              :load="loadNode"
              v-if="searchCondition.keyword===''&&activeName==='custom'"
              :expand-on-click-node=false
              :default-expanded-keys="leftDefaultExpandKeys"
              @node-expand="leftTreeNodeExpand"
              @node-collapse="leftTreeNodeCollapse"
              @node-click="nodeClick"
              @check-change="leftCheckChange"
              :props="defaultProps">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <i :class="icoClass(data)"></i><span style="margin-left: 5px;">{{ data.name }}</span><span
                v-show="data.type==='org'">({{data.userCount}}人)</span>
              </span>
            </el-tree>
            <el-tree
              :show-checkbox="isMulSelect"
              node-key="id"
              ref="tree_workGroup"
              key="tree_workGroup"
              class="left_tree"
              :filter-node-method="filterNode"
              highlight-current
              lazy
              :load="loadNode"
              v-if="searchCondition.keyword===''&&activeName==='workGroup'"
              :expand-on-click-node=false
              :default-expanded-keys="leftDefaultExpandKeys"
              @node-expand="leftTreeNodeExpand"
              @node-collapse="leftTreeNodeCollapse"
              @node-click="nodeClick"
              @check-change="leftCheckChange"
              :props="defaultProps">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <i :class="icoClass(data)"></i><span style="margin-left: 5px;">{{ data.name }}</span><span
                v-show="data.type==='org'">({{data.userCount}}人)</span>
              </span>
            </el-tree>
            <el-tree
              :show-checkbox="isMulSelect"
              node-key="id"
              ref="tree_myWorkGroup"
              key="tree_myWorkGroup"
              class="left_tree"
              :filter-node-method="filterNode"
              highlight-current
              lazy
              :load="loadNode"
              v-if="searchCondition.keyword===''&&activeName==='myWorkGroup'"
              :expand-on-click-node=false
              :default-expanded-keys="leftDefaultExpandKeys"
              @node-expand="leftTreeNodeExpand"
              @node-collapse="leftTreeNodeCollapse"
              @node-click="nodeClick"
              @check-change="leftCheckChange"
              :props="defaultProps">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <i :class="icoClass(data)"></i><span style="margin-left: 5px;">{{ data.name }}</span><span
                v-show="data.type==='org'">({{data.userCount}}人)</span>
              </span>
            </el-tree>
            <el-tree
              :show-checkbox="isMulSelect"
              node-key="id"
              ref="treeLeftSearch"
              v-show="searchCondition.keyword!==''"
              class="left_tree"
              highlight-current
              :data="searchUsers"
              :expand-on-click-node=false
              @node-click="nodeClick"
              @check-change="leftCheckChange"
              :props="defaultProps">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <i :class="icoClass(data)"></i><span style="margin-left: 5px;">{{ data.name }}</span>
              </span>
            </el-tree>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;" class="member_count" v-if="containUser===1">
        <el-col>
          <span style="font-weight: bold;font-size: 16px;">总人数：</span> <span style="font-size: 16px;color: red;">{{totalCount}}人</span>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sortDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUsers"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>

  export default {
    name: "user-select",
    props: {
      value: '',
      width: {
        type: String,
        default: "50%"
      },
      title: {
        type: String,
        default: "选择组织机构和人员"
      },
      btnLabel: {
        type: String,
        default: "选择"
      },
      placeholder: {
        type: String,
        default: "请选择"
      },
      // 业务编码 null 使用页面属性的配置，否则根据key查询相应的配置
      businessKey: {
        type: String,
        default: null
      },
      // 是否包含用户 0 不含，1包含
      containUser: {
        type: Number,
        default: 1
      },
      // 是否多选
      isMulSelect: {
        type: Boolean,
        default: false
      },
      // 是否只读
      readonly: {
        type: Boolean,
        default: false
      },

      // 是否隐藏输入框
      isHiddenInput: {
        type: Boolean,
        default: false
      },
      // 是接收还是发送 COPY抄送，RECEIVE 接收,NORMAL 不处理
      busType: {
        type: String,
        default: 'NORMAL'
      },
      // 选择类型 0-部门/工作组，用户，1-用户，2-部门/工作组
      selectType: {
        type: Number,
        default: 0
      },
      // tab 配置
      tabConfig: {
        type: Object,
        default: null
      },
      // 确认调用接口方法
      funcSubmit: {
        type: Function, default: () => {
          return new Promise((resolve, reject) => {
          })
        }
      },
    },
    data() {
      return {
        isLoad: true,// 是否初始化加载
        userNames: "",
        origId: '',
        userIds: '',
        rightCondition: '',
        rightSpan: 10,
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        defaultRightProps: {
          children: 'list',
          label: 'name'
        },
        activeName: 'All',
        totalCount: 0,
        sortDialogVisible: false,
        searchCondition: {
          keyword: ""
        },
        users: [],
        tabItems:[],
        rightSelectUsers: [],// 右侧选择的用户机构列表
        leftSelectNode: null,// 左侧点击选择对象
        rightSelectNode: null,// 右侧点击选择对象
        tabConfigData: {
          "showAllOrganize": true,
          "showCustomOrganize": false,
          "customOrganizeId": 0,
          "showAllWorkGroup": false,
          "showPersonalWorkGroup": false,
          "showAllOrganizeName": "全部",
          "showCustomOrganizeName": "公文传输",
          "showAllWorkGroupName": "工作组",
          "showPersonalWorkGroupName": "我的工作组",
          tabIndicatorWidth: 28//px
        },
        showTabs: [],  // 展示的tabs
        leftDefaultExpandKeys: [],// 左侧树-默认展开keys集合
        leftCacheDefaultExpandKeys: [],// 左侧树-默认展开keys集合 缓存
        searchUsers: [],// 搜索结果集
        temAllUsers: [],// 懒加载All用户树缓存结构，便于遍历去重
        temCustomUsers: [],// 懒加载Custom用户树缓存结构，便于遍历去重
        temWorkGroupUsers: [],// 懒加载Custom用户树缓存结构，便于遍历去重
        temMyWorkGroupUsers: [],// 懒加载Custom用户树缓存结构，便于遍历去重
      }
    },
    computed: {
      rightStyle() {
        let me = this;
        let span = 10;
        this.$nextTick(function () {
          let dialog = me.$refs.user_select_dialog.$el
          let dialogBody = dialog.getElementsByClassName('el-dialog__body')
          let bodyWidth = dialogBody[0].clientWidth
          let one = (bodyWidth / 24)
          span = (bodyWidth - (one * 10 + 90)) / one
          me.rightSpan = Math.floor(span)
          me.$refs.rightContent.$el.style.width = Math.floor(bodyWidth - (one * 10 + 65) - 20) + "px"
        })
      }
    },
    mounted() {
      this.getTabConfig();
      if (this.value) {
        if (this.value) {
          this.userIds = this.value.userIds
          if (this.isLoad) {
            this.updateDefaultUser()
          }
        }
      }
    },
    watch: {
      rightSelectUsers: {
        handler(val) {
          if (val) {
            this.computeCount(val);
          }
        },
        deep: true
      },
      value: {
        handler(val) {
          if (val) {
            this.userIds = val.userIds
            if (this.isLoad) {
              this.updateDefaultUser()
            }
          }
        },
        deep: true
      }
    },
    methods: {
      /**
       * 描述：懒加载数据
       * 时间： 2021/3/22
       */
      async loadNode(node, resolve) {
        // console.log(this.activeName)
        if (node.level === 0) {
          switch (this.activeName) {
            case "all":
              this.getOrganizeUserRoot("ALL", 0, node.level, resolve)
              break
            case 'custom':
              this.getOrganizeUserRoot("CUSTOM", 0, node.level, resolve);
              break;
            case 'workGroup':
              this.getWorkGroupUser("WORK_GROUP", 0, node.level, resolve)
              break;
            case 'myWorkGroup':
              this.getWorkGroupUser("MY_WORK_GROUP", 0, node.level, resolve)
              break;
          }
        } else if (node.level >= 1) {
          switch (this.activeName) {
            case "all":
              this.getOrganizeUserRoot("ALL", node.data.origId, node.level, resolve)
              break
            case 'custom':
              this.getOrganizeUserRoot("CUSTOM", node.data.origId, node.level, resolve);
              break;
            case 'workGroup':
              this.getWorkGroupUser("WORK_GROUP", node.data.origId, node.level, resolve)
              break;
            case 'myWorkGroup':
              this.getWorkGroupUser("MY_WORK_GROUP", node.data.origId, node.level, resolve)
              break;
          }
        }
      },

      // 获取组织用户-懒加载
      getOrganizeUserRoot(queryType, parentId, level, resolve) {
        let tem = {
          queryType: queryType,
          type: this.containUser,
          parentId: parentId === undefined ? 0 : parentId,
          module: this.businessKey,
          keywords: this.searchCondition.keyword
        }
        this.API.getOrganizeLazy(tem).then(res => {
          if (res.data) {
            // console.log(2)
            res.data.map(item => {
              item.leaf = !item.subChildren
              if (level == 0) {
                this.$set(item, "expanded", true)
              }
            })
          }
          if (resolve === null) {
            if (this.searchCondition.keyword) {
              this.searchUsers = res.data
            }
          } else {
            switch (this.activeName) {
              case "all":
                if (this.temAllUsers && level === 0 && this.temAllUsers.length === 0) {
                  this.temAllUsers = (res.data)
                } else {
                  this.setTemUserChildren(this.temAllUsers, parentId, res.data)
                  // console.log(this.temAllUsers)
                }
                break
              case "custom":
                if (this.temCustomUsers && level === 0 && this.temCustomUsers.length === 0) {
                  this.temCustomUsers = (res.data)
                } else {
                  this.setTemUserChildren(this.temCustomUsers, parentId, res.data)
                }
                break
            }
            resolve(res.data)
          }
          if (level === 0) {
            this.setDefaultExpandLeftTree()
          }
          this.setDefaultCheckedData()
        })
      },

      /**
       * 描述：设置默认展开 第一级
       * 时间： 2021/3/25
       */
      setDefaultExpandLeftTree() {
        switch (this.activeName) {
          case "all":
            // console.log(this.temAllUsers, 888)
            this.setLeftExpandKeys(this.temAllUsers)
            break
          case "custom":
            this.setLeftExpandKeys(this.temCustomUsers)
            break
          case "workGroup":
            this.setLeftExpandKeys(this.temWorkGroupUsers)
            break
          case "myWorkGroup":
            this.setLeftExpandKeys(this.temMyWorkGroupUsers)
            break
        }
        this.leftDefaultExpandKeys = this.leftCacheDefaultExpandKeys
        // console.log(this.leftDefaultExpandKeys, 1)
      },

      setLeftExpandKeys(data) {
        if (data) {
          data.map(item => {
            if (item.expanded) {
              // console.log(item.id, item.expanded)
              if (!this.checkCacheExpandKeyExisted(item.id)) {
                this.leftCacheDefaultExpandKeys.push(item.id)
              }
            } else {
              if (item.children) {
                this.setLeftExpandKeys(item.children)
              }
            }
          })
        }
      },

      /**
       * 描述：检测缓存展开的key是否存在
       * 时间： 2021/3/26
       */
      checkCacheExpandKeyExisted(id) {
        let result = false
        if (this.leftCacheDefaultExpandKeys) {
          for (let item of  this.leftCacheDefaultExpandKeys) {
            if (item === id) {
              result = true
              break
            }
          }
        }
        return result
      },

      // 获取工作组用户-懒加载
      getWorkGroupUser(queryType, parentId, level, resolve) {
        // console.log("resolve",resolve)
        let tem = {
          queryType: queryType,
          type: this.containUser,
          parentId: parentId === undefined ? 0 : parentId,
          module: this.businessKey,
          keywords: this.searchCondition.keyword
        }
        this.API.getWorkGroupLazy(tem).then(res => {
          if (res.data) {
            res.data.map(item => {
              item.leaf = !item.subChildren
              if (level == 0) {
                this.$set(item, "expanded", true)
              }
            })
          }
          if (resolve === null) {
            if (this.searchCondition.keyword) {
              this.searchUsers = res.data
            }
          } else {
            switch (this.activeName) {
              case "workGroup":
                if (this.temWorkGroupUsers && level === 0 && this.temWorkGroupUsers.length === 0) {
                  this.temWorkGroupUsers = (res.data)
                } else {
                  this.setTemUserChildren(this.temWorkGroupUsers, parentId, res.data)
                }
                break
              case "myWorkGroup":
                if (this.temMyWorkGroupUsers && level === 0 && this.temMyWorkGroupUsers.length === 0) {
                  this.temMyWorkGroupUsers = (res.data)
                } else {
                  this.setTemUserChildren(this.temMyWorkGroupUsers, parentId, res.data)
                }
                break
            }
            resolve(res.data)
          }
          if (level === 0) {
            this.setDefaultExpandLeftTree()
          }
          this.setDefaultCheckedData()
        })
      },

      /**
       * 描述：设置用户树子集
       * 时间： 2021/3/24
       */
      setTemUserChildren(data, parentId, subData) {
        if (data) {
          data.map(item => {
            if (item.type === 'org' && item.origId === parentId) {
              // console.log(item.children)
              if (item.children === undefined || item.children === null) {
                this.$set(item, "children", subData)
              }
            } else {
              if (item.children) {
                this.setTemUserChildren(item.children, parentId, subData)
              }
            }
          })
        }
      },

      leftTreeNodeExpand(data, node, self) {
        if (!this.checkCacheExpandKeyExisted(data.id)) {
          this.leftCacheDefaultExpandKeys.push(data.id)
        }
        // console.log(data, node, self)
        this.leftLazy = true
        switch (this.activeName) {
          case "all":
            this.setItemExpandOrCollapse(this.temAllUsers, data, true)
            // console.log(this.temAllUsers, 999)
            break
          case "custom":
            this.setItemExpandOrCollapse(this.temCustomUsers, data, true)
            break
          case "workGroup":
            this.setItemExpandOrCollapse(this.temWorkGroupUsers, data, true)
            break
          case "myWorkGroup":
            this.setItemExpandOrCollapse(this.temMyWorkGroupUsers, data, true)
            break
        }
      },
      /**
       * 描述：设置item的展开，闭合属性
       * 时间： 2021/3/26
       */
      setItemExpandOrCollapse(data, item, expand) {
        if (data) {
          for (let model of data) {
            if (model.id === item.id) {
              if (model.expanded) {
                model.expanded = expand
              } else {
                this.$set(model, "expanded", expand)
              }
            } else {
              if (model.children) {
                this.setItemExpandOrCollapse(model.children, item, expand)
              }
            }
          }
        }
      },
      leftTreeNodeCollapse(data, node, self) {
        // console.log(data, node, self)
        let index = this.leftCacheDefaultExpandKeys.findIndex((item) => {
          return item === data.id
        })
        if (index != -1) {
          this.leftCacheDefaultExpandKeys.splice(index, 1)
        }
        switch (this.activeName) {
          case "all":
            this.setItemExpandOrCollapse(this.temAllUsers, data, false)
            break
          case "custom":
            this.setItemExpandOrCollapse(this.temCustomUsers, data, false)
            break
          case "workGroup":
            this.setItemExpandOrCollapse(this.temWorkGroupUsers, data, false)
            break
          case "myWorkGroup":
            this.setItemExpandOrCollapse(this.temMyWorkGroupUsers, data, false)
            break
        }
      },

      /**
       * 描述：获取缓存的数据选择的数组
       * 时间： 2021/3/25
       */
      setDefaultCheckedData() {
        let tem = []
        switch (this.activeName) {
          case "all":
            tem = this.getCheckedData(this.temAllUsers)
            break
          case "custom":
            tem = this.getCheckedData(this.temCustomUsers)
            break
          case "workGroup":
            tem = this.getCheckedData(this.temWorkGroupUsers)
            break
          case "myWorkGroup":
            tem = this.getCheckedData(this.temMyWorkGroupUsers)
            break
        }
        // console.log("tree_" + this.activeName, tem)
        if (this.$refs["tree_" + this.activeName]) {
          this.$refs["tree_" + this.activeName].setCheckedKeys(tem)
        }
      },

      getCheckedData(data) {
        let tem = data.flatArr()
        let result = []
        if (tem) {
          tem.map(item => {
            if (item.check) {
              result.push(item.id)
            }
          })
        }
        return result;
      },
      /**
       * 描述：获取随机颜色
       * 时间： 2021/2/25
       */
      getColorByRandom() {
        let colorList =
          ["#fe9a38", "#fb543a", "#44b4fa", "#fe9a38", "#fb543a", "#44b4fa", "#CC9898", "#8AC007", "#CCC007", "#FFAD5C"];
        let index = Math.floor(Math.random() * colorList.length);
        return colorList[index];
      },
      /** 移除已选择用户
       * 描述：
       * 时间： 2021/2/25
       */
      handleDeletePerson(val) {
        console.log(val);
        let index = this.showUserIds.findIndex(item => item.id === val.id);
        this.showUserIds.splice(index, 1);
        this.rightSelectUsers = _.cloneDeep(this.showUserIds);
      },
      getHeight() {
        return { height: (window.innerHeight - 100) + "px" };
      },
      // 根据业务编码获取tab的控制属性
      getTabConfig() {
        this.showTabs = [];
        this.tabItems=[]
        if (this.businessKey === null) {
          if (this.tabConfig) {
            Object.assign(this.tabConfigData, this.tabConfig);
          }
        } else {
          this.API.getModuleSettingByKey({ key: this.businessKey }).then(res => {
            Object.assign(this.tabConfigData, res.data);
          });
        }
        if (this.tabConfigData.showAllOrganize) {
          this.showTabs.push("all");
          this.tabItems.push({name:'all',label:this.tabConfigData.showAllOrganizeName})
        }
        if (this.tabConfigData.showCustomOrganize) {
          this.showTabs.push("custom");
          this.tabItems.push({name:'custom',label:this.tabConfigData.showCustomOrganizeName})
        }
        if (this.tabConfigData.showAllWorkGroup) {
          this.showTabs.push("workGroup");
          this.tabItems.push({name:'workGroup',label:this.tabConfigData.showAllWorkGroupName})
        }
        if (this.tabConfigData.showPersonalWorkGroup) {
          this.showTabs.push("myWorkGroup");
          this.tabItems.push({name:'myWorkGroup',label:this.tabConfigData.showPersonalWorkGroupName})
        }
      },
      // 回显
      async updateDefaultUser() {
        this.userNames = this.value.userNames;
        this.rightSelectUsers = [];
        if (this.userIds) {
          let tem = { type: this.containUser, parentId: 0 };
          await this.API.getOrganizeTree(tem).then(async res => {
            let users = this.userIds.split(",");
            if (users && res.data) {
              let otherUsers = [];
              users.map((item, index) => {
                let name = this.getUserNameById(res.data, item);
                if (name === undefined || name === null) {
                  otherUsers.push(item);
                }
              });
              if (otherUsers && otherUsers.length > 0) {
                await this.getNameByGroupData(otherUsers);
              }
            }
          });

        }
      },
      // 树点击事件
      nodeClick(data, node, self) {
        console.log(data, node, self);
        this.leftSelectNode = data;
        this.confirmSelectUser();
      },
      // 确认选择的用户
      confirmSelectUser() {
        if (this.leftSelectNode) {
          if (!this.isMulSelect) {
            this.rightSelectUsers = [];
          }
          if (this.selectType === 1 && this.leftSelectNode.type !== "user") {
            this.warning("只能选择用户");
            return;
          }
          // if (this.selectType === 2 && this.leftSelectNode.type === 'user') {
          //   this.warning("请选择工作组或机构")
          //   return
          // }
          if (!this.isMulSelect && this.rightSelectUsers.length > 0) {
            this.warning("只允许选择一个用户");
            return;
          }
          console.log(this.leftSelectNode);
          let i = this.rightSelectUsers.indexOf(this.leftSelectNode);
          console.log(i);
          if (i !== -1) {
            this.warning(this.leftSelectNode.name + ",已存在相同数据");
          } else {
            if (this.isMulSelect) {
              this.rightSelectUsers.push(this.leftSelectNode);
            } else {
              this.rightSelectUsers = [];
              this.rightSelectUsers.push(this.leftSelectNode);
            }
            this.$refs["tree_"+this.activeName].setCheckedNodes(this.rightSelectUsers);
            this.leftSelectNode = null;
          }
        }
      },
      getNameByGroupData(otherUsers) {
        let temGroup = { type: this.containUser, parentId: 0, isMe: 0 };
        this.API.getWorkGroupTree(temGroup).then(res => {
          if (res.data) {
            otherUsers.map((item, index) => {
              this.getUserNameById(res.data, item);
            });
          }
        });
      },

      getUserNameById(data, item) {
        for (let i = 0; i < data.length; i++) {
          let user = data[i];
          if (user.id === item) {
            this.rightSelectUsers.push(user);
            return user.name;
          } else {
            if (user.children !== undefined) {
              let result = this.getUserNameById(user.children, item);
              if (result) {
                return result;
              }
            } else {
              continue;
            }
          }
        }
      },


      // 计算选择人数
      computeCount(val) {
        let tem = val
        let users = []
        let orgs = []
        // console.log(this.totalCount,'计算',tem)
        if (tem) {
          tem.map((item) => {
            if (item.type !== 'org') {
              users.push(item)
            } else {
              orgs.push(item)
            }
          })
        }
        if (orgs) {
          orgs.map(org => {
            this.totalCount += org.userCount
          })
        }
        this.totalCount += users.length
      },
      // 右侧树点击
      nodeRightClick(data, node, self) {
        this.rightSelectNode = data;
        console.log(data);
      },
      //根据类型获取图标
      icoClass(val) {
        if (val.type === "org") {
          return "fa fa-folder";
        } else {
          return "fa fa-user";
        }
      },
      // 过滤用户
      searchUser() {
        this.loadNode({level: 0}, null)
      },

      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      // tab切换
      handleClick(val) {
        this.activeName = val.name
        this.setDefaultExpandLeftTree()
      },
      /**
       * 描述：设置tab指示器宽度
       * 时间： 2021/2/25
       */
      tabStyle(tabName) {
        let index = this.showTabs.findIndex(item => item == tabName);
        let me = this;
        this.$nextTick(() => {
          let dialog = me.$refs.user_select_dialog.$el;
          let tabsActiveBar = dialog.getElementsByClassName("el-tabs__active-bar");
          let hafTabIndicatorWidth = 14;
          if (this.tabConfigData.tabIndicatorWidth === "auto") {
            tabsActiveBar[0].style.width = window.innerWidth / (this.showTabs.length) + "px";
          } else {
            hafTabIndicatorWidth = this.tabConfigData.tabIndicatorWidth / 2;
          }
          let translateX = window.innerWidth * ((1 + index * 2) / (this.showTabs.length * 2)) - hafTabIndicatorWidth;
          console.log(index, translateX, this.tabConfigData.tabIndicatorWidth, this.tabConfigData.tabIndicatorWidth !== "auto");
          if (this.tabConfigData.tabIndicatorWidth !== "auto") {
            tabsActiveBar[0].style.width = this.tabConfigData.tabIndicatorWidth + "px";
            tabsActiveBar[0].style.transform = "translateX(" + translateX + "px)";
          }
          console.log(tabsActiveBar[0].style.width);
        });
      },
      /**
       * 描述：左侧树缓存数据增加checked 字段，便于切换时回显
       * @param item 操作的item
       * @param check 选中状态true 选中，false取消选中
       * 时间： 2021/3/25
       */
      setLeftTemTreeCheckedUserByActive(item, check) {
        switch (this.activeName) {
          case "all":
            this.setLeftTemTreeCheckedUser(this.temAllUsers, item, check)
            break
          case "custom":
            this.setLeftTemTreeCheckedUser(this.temCustomUsers, item, check)
            break
          case "workGroup":
            this.setLeftTemTreeCheckedUser(this.temWorkGroupUsers, item, check)
            break
          case "myWorkGroup":
            this.setLeftTemTreeCheckedUser(this.temMyWorkGroupUsers, item, check)
            break
        }
      },

      /**
       * 描述：左侧树缓存数据增加checked 字段，便于切换时回显
       * @param data 数据集
       * @param item 操作的item
       * @param check 选中状态true 选中，false取消选中
       * 时间： 2021/3/25
       */
      setLeftTemTreeCheckedUser(data, item, check) {
        if (data) {
          for (let model of  data) {
            if (model.id === item.id) {
              if (model.check) {
                model.check = check
                if (model.type!=='user') {
                  item.children = model.children
                }
              } else {
                this.$set(model, "check", check)
              }
              break
            } else {
              if (model.children) {
                this.setLeftTemTreeCheckedUser(model.children, item, check)
              }
            }
          }
        }
      },

      // 递归遍历右侧选择的用户和部门以及部门下的子集，去除重复数据
      removeExistedDeptAndUser(data, item) {
        let existed = false
        if (data) {
          // map 遍历不能跳出，es6 退出for of 可以退出循环
          for (let rightUser of data) {
            if (rightUser.id === item.id) {
              existed = true
              break
            } else {
              if (rightUser.children) {
                existed = this.removeExistedDeptAndUser(rightUser.children, item)
              }
            }
          }
        }
        return existed
      },
      //左侧多选事件
      leftCheckChange(data, self, node) {
        //如果取消选择，右侧去掉相关数据
        this.setLeftTemTreeCheckedUserByActive(data, self)
        //如果取消选择，右侧去掉相关数据
        if (!self) {
          this.rightSelectUsers.map((rightUser, index) => {
            if (rightUser.id === data.id) {
              this.rightSelectUsers.splice(index, 1)
            }
          })
        }
        let temRightUsers = _.cloneDeep(this.rightSelectUsers)
        // console.log(this.$refs["tree_" + this.activeName].getCheckedNodes(),this.temAllUsers)
        this.$refs["tree_" + this.activeName].getCheckedNodes().map(item => {
          if (temRightUsers) {
            let existed = false
            temRightUsers.map((rightUser) => {
              if (rightUser.id === item.id) {
                existed = true
              }
            })
            if (!existed) {
              if (item.id===data.id) {
                temRightUsers.push(data) // 重新给children 赋值的数据
              }else {
                temRightUsers.push(item)
              }
            }
          }
        })
        let depts = []
        // 右侧数据在去重复一次
        temRightUsers.map(item => {
          if (item.type !== 'user') {
            depts.push(item)
          }
        })
        let result = []
        temRightUsers.map((item, index) => {
          // console.log(index,item.id)
          let existed = false
          if (depts) {
            for (let dept of depts) {
              if (dept.children) {
                existed = this.removeExistedDeptAndUser(dept.children, item)
                if (existed) {
                  return
                }
              }
            }
          }
          if (!existed) {
            result.push(item)
          }
        })
        this.rightSelectUsers = _.cloneDeep(result)
      },
      /**
       * 描述：重置缓存数据
       * 时间： 2021/3/26
       */
      resetCacheData() {
        this.temAllUsers = []
        this.temCustomUsers = []
        this.temWorkGroupUsers = []
        this.temMyWorkGroupUsers = []
      },
      // 展示用户弹出
      showUserDialog() {
        this.sortDialogVisible = true;
        console.log(this.showUserIds)
        if (this.showUserIds) {
          this.setDefaultCheckedData()
        }else {
          this.resetCacheData()
        }
      },

      // 跟新组件值
      async updateUsers() {
        let userIds = ''
        this.userNames = ''
        this.origId = ''
        if (this.rightSelectUsers) {
          this.rightSelectUsers.map((item, index) => {
            if (index === this.rightSelectUsers.length - 1) {
              this.userNames += item.name
              userIds += item.id
              this.origId += item.origId
            } else {
              this.userNames += (item.name + ",")
              userIds += (item.id + ",")
              this.origId += (item.origId + ',')
            }
          })
        }
        console.log(userIds, this.userNames)
        this.isLoad = false
        let obj = {}
        obj.origId = this.origId;
        obj.userNames = this.userNames;
        obj.userIds = userIds
        obj.type = this.type
        this.$emit('input', obj)
        if (this.type) {
          switch (this.type) {
            case 'COPY':
              // 抄送
              break
            case 'RECEIVE':
              // 接收
              break
          }
        }
        this.$emit('getUser', obj)
        this.sortDialogVisible = false
        if (this.funcSubmit) {
          await this.funcSubmit(obj);
        }
      }
    }
  };
</script>

<style lang="less" scoped scoped>
  .flex_box {
    display: table-cell;
    vertical-align: middle;
  }

  .member_count {
    position: fixed;
    bottom: 50px;
    right: 0px;
    background-color: white;
    width: 100%;
    height: 50px;
    text-align: left;
    line-height: 50px;
    z-index: 2;
    padding-left: 10px;
    box-shadow: 0px 1px 17px rgba(174, 185, 190, 0.3);
  }

  .dialog-footer {
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: white;
    width: 100%;
    height: 50px;
    text-align: right;
    line-height: 50px;
    padding-right: 10px;
    z-index: 2;
    box-shadow: 0px 1px 17px rgba(174, 185, 190, 0.3);
  }

  .el-dialog.is-fullscreen {
    overflow-x: hidden;

  }

  .flex_box {
    display: table-cell;
    vertical-align: middle;
  }

  .input-with-select {
    /deep/ .el-input.is-disabled .el-input__inner {
      background-color: white !important;
      color: white !important;
      text-align: right !important;
    }

    /deep/ .el-input__inner {
      text-align: right !important;
      background-color: white;
    }
  }

  .user_tree {
    margin-bottom: 100px;
    background-color: white;

    /deep/ .el-dialog {
      margin: 0px !important;
      width: 100%;
    }

    /deep/ .el-dialog__header {
      display: none;
    }

    /deep/ .el-dialog__body {
      padding: 0px;
    }

    /deep/ .el-tabs__nav-scroll {
      margin-left: 20px;
    }

    /deep/ .el-tabs__header {
      background-color: white;
      margin: 10px 0px 0px;
    }

    /deep/ .left_tree {
      /deep/ .el-tree-node__content {
        height: 50px;
      }

      /deep/ .custom-tree-node {
        line-height: 50px;
        width: 100%;
        border-bottom: 1px solid #E4E4E4;
      }
    }

    /deep/ .el-input-group__append {
      border: none;
      background: white;
    }

    /deep/ .el-input__inner {
      border: none;
    }

    /deep/ .el-input__validateIcon {
      display: none;
    }
  }

</style>
<style lang="scss" scoped scoped>
  @import "../../style/var";

  .add_btn {
    border: none;
    padding: 0px 20px 0px 0px;

    /deep/ .el-icon-circle-plus-outline {
      color: $color-primary;
      font-size: 20px;
    }
  }

  .add_btn_readonly {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }

  .user_tree {
    /deep/ .el-dialog, .el-pager li {
      background-color: $main-bg !important;
    }
  }

</style>
