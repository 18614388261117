var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"mescroll-uni-warp"},[_c('v-uni-scroll-view',{staticClass:"mescroll-uni",class:{'mescroll-uni-fixed':_vm.isFixed},style:({'height':_vm.scrollHeight,'padding-top':_vm.padTop,'padding-bottom':_vm.padBottom,'top':_vm.fixedTop,'bottom':_vm.fixedBottom}),attrs:{"id":_vm.viewId,"scroll-top":_vm.scrollTop,"scroll-with-animation":_vm.scrollAnim,"scroll-y":_vm.scrollable,"enable-back-to-top":true,"throttle":false},on:{"scroll":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.scroll).apply(void 0, arguments)
}}},[_c('v-uni-view',{wxsProps:{"change:prop":"wxsProp"},staticClass:"mescroll-uni-content mescroll-render-touch",attrs:{"change:prop":_vm.wxsBiz.propObserver,"prop":_vm.wxsProp},on:{"touchstart":function($event){
$event = _vm.$handleWxsEvent($event);
(_vm.wxsBiz.touchstartEvent)($event, _vm.$getComponentDescriptor())
},"touchmove":function($event){
$event = _vm.$handleWxsEvent($event);
(_vm.wxsBiz.touchmoveEvent)($event, _vm.$getComponentDescriptor())
},"touchend":function($event){
$event = _vm.$handleWxsEvent($event);
(_vm.wxsBiz.touchendEvent)($event, _vm.$getComponentDescriptor())
},"touchcancel":function($event){
$event = _vm.$handleWxsEvent($event);
(_vm.wxsBiz.touchendEvent)($event, _vm.$getComponentDescriptor())
}}},[(_vm.topbar&&_vm.statusBarHeight)?_c('v-uni-view',{staticClass:"mescroll-topbar",style:({height: _vm.statusBarHeight+'px', background: _vm.topbar})}):_vm._e(),_c('v-uni-view',{wxsProps:{"change:prop":"callProp"},staticClass:"mescroll-wxs-content",style:({'transform': _vm.translateY, 'transition': _vm.transition}),attrs:{"change:prop":_vm.wxsBiz.callObserver,"prop":_vm.callProp}},[(_vm.mescroll.optDown.use)?_c('v-uni-view',{staticClass:"mescroll-downwarp",style:({'background':_vm.mescroll.optDown.bgColor,'color':_vm.mescroll.optDown.textColor})},[_c('v-uni-view',{staticClass:"downwarp-content"},[_c('v-uni-view',{staticClass:"downwarp-progress mescroll-wxs-progress",class:{'mescroll-rotate': _vm.isDownLoading},style:({'border-color':_vm.mescroll.optDown.textColor, 'transform': _vm.downRotate})}),_c('v-uni-view',{staticClass:"downwarp-tip"},[_vm._v(_vm._s(_vm.downText))])],1)],1):_vm._e(),_vm._t("default"),(_vm.isShowEmpty)?_c('mescroll-empty',{attrs:{"option":_vm.mescroll.optUp.empty},on:{"emptyclick":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.emptyClick).apply(void 0, arguments)
}}}):_vm._e(),(_vm.mescroll.optUp.use && !_vm.isDownLoading && _vm.upLoadType!==3)?_c('v-uni-view',{staticClass:"mescroll-upwarp",style:({'background':_vm.mescroll.optUp.bgColor,'color':_vm.mescroll.optUp.textColor})},[_c('v-uni-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.upLoadType===1),expression:"upLoadType===1"}]},[_c('v-uni-view',{staticClass:"upwarp-progress mescroll-rotate",style:({'border-color':_vm.mescroll.optUp.textColor})}),_c('v-uni-view',{staticClass:"upwarp-tip"},[_vm._v(_vm._s(_vm.mescroll.optUp.textLoading))])],1),(_vm.upLoadType===2)?_c('v-uni-view',{staticClass:"upwarp-nodata"},[_vm._v(_vm._s(_vm.mescroll.optUp.textNoMore))]):_vm._e()],1):_vm._e()],2),(_vm.bottombar && _vm.windowBottom>0)?_c('v-uni-view',{staticClass:"mescroll-bottombar",style:({height: _vm.windowBottom+'px'})}):_vm._e(),(_vm.safearea)?_c('v-uni-view',{staticClass:"mescroll-safearea"}):_vm._e()],1)],1),_c('mescroll-top',{attrs:{"option":_vm.mescroll.optUp.toTop},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.toTopClick).apply(void 0, arguments)
}},model:{value:(_vm.isShowToTop),callback:function ($$v) {_vm.isShowToTop=$$v},expression:"isShowToTop"}}),_c('v-uni-view',{wxsProps:{"change:prop":"wxsProp"},attrs:{"change:prop":_vm.renderBiz.propObserver,"prop":_vm.wxsProp}})],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }