var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"parent_rate_img",style:({height:_vm.vh+'px'})},[_c('vue-drag-resize',{attrs:{"w":_vm.vw,"x":_vm.left,"parentLimitation":_vm.parentLimitation,"y":_vm.top,"z":_vm.vZ,"h":_vm.vh,"isDraggable":_vm.isDraggable,"isActive":_vm.isActive},on:{"resizing":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.resize).apply(void 0, arguments)
},"dragging":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.resize).apply(void 0, arguments)
}}},[_c('div',{staticClass:"box",style:({width: + _vm.vw+ 'px',height:+_vm.vh+'px'})},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.srcUrl}})])])],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }