/**
 * 用户接口
 */
import qs from 'qs'

export default class UserApi {
  constructor(vm) {
    this.vm = vm
  }

  // 微信授权后 注册新账号
  register(params = {}) {
    return this.vm.$u.post('/api/user/register', params)
  }

  // 微信授权后 绑定账号
  bind(params = {}) {
    return this.vm.$u.post('/api/user/bind', params)
  }

  // 通过token获取用户信息
  getUserDetail(params = {}) {
    if (params.role) return this.vm.$u.get('/api/user/detail',
      params.userId ? { userId: params.userId } : {},
      { 'role': params.role }
    )
    else return this.vm.$u.get('/api/user/detail')
  }

  // 用户注册-发送验证码
  sendVerificationCode({ mobile }) {
    return this.vm.$u.post(`/api/user/send/code/${mobile}`)
  }

  // 用户绑定已有帐号-发送验证码
  sendLoginVerificationCode({ mobile, idCard }) {
    return this.vm.$u.post(`/api/auth/find/login/${mobile}/${idCard}`, {},
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    )
  }

  // 系统用户登录
  handleServiceSystemLogin(params = {}, { role }) {
    return this.vm.$u.post('/api/auth/login', qs.stringify(params), {
      'role': role,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  // 获取用户信息和残疾证信息
  getDisabledCardInfo() {
    return this.vm.$u.get('/api/user/info')
  }

  // 解除微信绑定
  handleUnbindWx() {
    return this.vm.$u.post('/api/user/unbind')
  }

  // 修改残疾人监护人信息
  updateGuardianInfo(params = {}) {
    params.disabledPersonId = this.vm.$store.getters.disabledPersonInfo.id
    return this.vm.$u.post('/api/disabled/person/guardian', params)
  }
}
