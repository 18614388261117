/**
 * 评价接口
 */
export default class EvaluateApi {
  constructor(vm) {
    this.vm = vm
  }

  // 新增或修改服务评价
  setServiceEvaluation(params = {}) {
    return this.vm.$u.post('/api/service/evaluate', params);
  }

  // 查询我的服务评价列表
  getMineServiceEvaluation(params = {}) {
    return this.vm.$u.get('/api/service/evaluate/self', params);
  }

  // 根据业务主键id和业务type值查询服务评价
  getServiceEvaluationById({ id, type }) {
    return this.vm.$u.get(`/api/service/evaluate/module/${id}/${type}`);
  }
}
