const MERGE_FORM_DATA = "MERGE_FORM_DATA";
const INIT_FORM_DATA = "INIT_FORM_DATA";
const UPDATE_FORM_ITEM_CHILDREN = "UPDATE_FORM_ITEM_CHILDREN";
const DEL_FORM_DATA = "DEL_FORM_DATA";
const state = {
  mergeFormData: {},// 提交的表单对象
  formItemChildren: {} //动态表格的items对象,包含上级表单id
}
const mutations = {
  [MERGE_FORM_DATA](state, payload) {
    state.mergeFormData = Object.assign({}, state.mergeFormData, payload);
  },
  [INIT_FORM_DATA](state, payload) {
    state.mergeFormData = payload
  },
  [DEL_FORM_DATA](state, payload) {
    delete state.mergeFormData[payload]
  },
  [UPDATE_FORM_ITEM_CHILDREN](state, payload) {
    state.formItemChildren = payload
  }
}
const actions = {
  // 合并数据
  mergeFormDataAction({ commit }, param) {
    commit(MERGE_FORM_DATA, param);
  },
  // 删除某个属性
  delFormDataAction({ commit }, param) {
    commit(DEL_FORM_DATA, param);
  },
  initFormDataAction({ commit }, param) {
    commit(INIT_FORM_DATA, param);
  },
  // 更新动态表格的items对象
  updateFormItemChildren({ commit }, param) {
    commit(UPDATE_FORM_ITEM_CHILDREN, param);
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
