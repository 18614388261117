const install = (Vue, vm) => {
	const {domain} = Vue.prototype.$appConf;
	const arr = domain.api.split('/api');
	arr.pop()
	let base = arr.join("/api");  // 正式服务器
	const fileUrl = domain.image1;

	Vue.prototype.$u.http.setConfig({
		baseUrl: base,
		loadingTime: 600,
		originalData: true,
	});

	Vue.prototype.$globalData = {
		action: base + "/api/sys/file/upload", // 图片上传地址 返回路径
		actionDb: base + "/api/sys/file/db/upload", // 文件上传地址 返回id
		verify: base + "/api/auth/code", // 验证码
		// image: fileUrls, //测试服务视频的地址
		image: fileUrl, //正式服务视频的地址
		// 正式服务图片完整路径
		filePath: (path) => {
			return fileUrl + "/" + path;
		},
		// 测试服务图片完整路径
		// 图片完整路径
		// filePath: (path) => {
		// 	return fileUrls + "/" + path;
		// },
	};

	// 请求拦截部分，如配置，每次请求前都会执行，见上方说明
	Vue.prototype.$u.http.interceptor.request = (config) => {
		if (config.header.role) {
			config.header.token =
				(config.header.role === "SERVER" ?
					vm.$store.getters.serverToken :
					vm.$store.getters.adminToken) || "";
			config.header.userRole = config.header.role;
			delete config.header.role;
		} else {
			config.header.token = vm.$store.getters.token || "";
			config.header.userRole = "USER";
		}
		return config;
	};

	// 响应拦截，如配置，每次请求结束都会执行本方法
	Vue.prototype.$u.http.interceptor.response = (response) => {
		if (response && response.statusCode === 200) {
			return response.data;
		} else if (response && response.statusCode === 401) {
			vm.$u.toast("用户信息过期,请重新登录");
			vm.$store.commit("user/LOGOUT");
			return false;
		} else {
			vm.$u.toast(
				response.data ? response.data.message : "网络请求失败,请检测网络情况!"
			);
			return false;
		}
	};
};

export default {
	install,
};