var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[(_vm.resultImg)?_c('rate-img',{ref:"rateImg",staticClass:"cut_sign_area",style:({height: (_vm.height-15)+'px'}),attrs:{"src-url":_vm.resultImg},on:{"getRect":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.getRect).apply(void 0, arguments)
}}}):_c('sz-ensign',{ref:"esign",staticClass:"hand_sign_area",attrs:{"width":_vm.width,"height":_vm.height,"isCrop":_vm.isCrop,"lineWidth":_vm.lineWidth,"lineColor":_vm.lineColor,"bgColor":_vm.bgColor},on:{"update:bgColor":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.bgColor=$event
},"update:bg-color":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.bgColor=$event
}},model:{value:(_vm.curSign),callback:function ($$v) {_vm.curSign=$$v},expression:"curSign"}}),_c('div',{staticClass:"btn_footer"},[_c('el-button',{on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleReset).apply(void 0, arguments)
}}},[_vm._v("清空")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleGenerate).apply(void 0, arguments)
}}},[_vm._v("确定")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}},[_vm._v("取消")])],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }