// 仅OA工作流使用
// const globalConf = require('./config/devConfig');
const globalConf = require('./config/prodConfig');

let appConf = {};
appConf.install = function (Vue) {
  Object.defineProperty(Vue.prototype, "$appConf", {
    get: function get() {
      return globalConf;
    }
  });
};

export default appConf;
