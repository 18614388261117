/**
 * 文章、栏目接口
 */
export default class ArticleApi {
  constructor(vm) {
    this.vm = vm
  }

  // 查询文章栏目列表
  getItemList(params) {
    return this.vm.$u.get('/api/news/item/list', params);
  }

  // 根据栏目id查询文章列表
  getArticleList(params) {
    return this.vm.$u.get('/api/news/article/list', params);
  }

  // 根据id查询文章信息
  getArticleDetails({ id }) {
    return this.vm.$u.get(`/api/news/article/${id}`);
  }
}
