const CACHE_FORM_DATA = "CACHE_FORM_DATA";
const DEL_CACHE_FORM_DATA = "DEL_CACHE_FORM_DATA";
const SUPERVISE_ITEM = "SUPERVISE_ITEM";
const state = {
  formDataMap: [],
  supervisionItem: {}
}
const mutations = {
  [SUPERVISE_ITEM](state, payload) {
    state.supervisionItem = payload;
  },
  [CACHE_FORM_DATA](state, payload) {
    if (state.formDataMap) {
      let hasExisted = false;
      state.formDataMap.map(item => {
        if (item.key === payload.key) {
          hasExisted = true;
          item.obj = payload.obj;
        }
      });
      if (!hasExisted) {
        state.formDataMap.push(payload);
      }
    }
  },
  [DEL_CACHE_FORM_DATA](state, payload) {
    if (state.formDataMap) {
      state.formDataMap.map((item, index) => {
        if (item.key === payload.key) {
          state.formDataMap.splice(index, 1);
        }
      });
    }
  }
}
const actions = {
  updateSupervisionItem({ commit }, param) {
    commit(SUPERVISE_ITEM, param);
  },
  // 更新流程表单数据
  updateFormDataMap({ commit }, param) {
    commit(CACHE_FORM_DATA, param);
  },
  // 删除流程表单数据
  delFormDataMap({ commit }, param) {
    commit(CACHE_FORM_DATA, param);
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
