/**
 * 督办的接口
 */
export default class SuperviseTheTaskApi {
	constructor(vm) {
		this.vm = vm
	}
	// (APP端自己查询)查询所有督办详情列表（分页）
	getDisabledHandleTaskInfoBySelfPageList(params = {}) {
		return this.vm.$u.get("api/disabled/handle/task/info/info/byUserId", params);
	}

	// 根据详情主键ID查询任务详情
	getOneDisabledHandleTaskInfoById({
		id
	}) {
		return this.vm.$u.get(`api/disabled/handle/task/info/info/${id}`);
	}
	// 上传图片的提交
	uploadImgInfoById(params = {}) {
		return this.vm.$u.post('api/disabled/handle/task/info/upload/img', params);
	}
	// 获取SDKAppID
	getTencentSDKAppId() {
		return this.vm.$u.get(`api/disabled/safeguard/money/task/get/sdkAppId`);
	}
	// 获取userSig
	getTencentUserSig(params) {
		return this.vm.$u.get(`api/disabled/safeguard/money/task/get/userSig`, params);
	}

	// 获取个人最新的被审核的上传的视频详情
	appraisalDisabledSafeguardMoneyTask() {
		return this.vm.$u.get(`api/disabled/safeguard/money/task/myself/task/video/info`);
	}

	// 获取视频
	getSysFileById(id) {
		return this.vm.$u.get(`api/sys/file/${id}/info`);
	}
	//新增或修改保障金任务
	addOrUpdateDisabledSafeguardMoneyTask(params = {}) {
		return this.vm.$u.post('api/disabled/safeguard/money/task/update/task/sign', params);
		// ​
	}
}