<template>
  <div class="main">
    <rate-img
      ref="rateImg"
      :src-url="resultImg"
      v-if="resultImg"
      @getRect="getRect"
      class="cut_sign_area"
      :style="{height: (height-15)+'px'}"
    />
    <sz-ensign
      v-else
      ref="esign"
      v-model="curSign"
      :width="width"
      :height="height"
      :isCrop="isCrop"
      :lineWidth="lineWidth"
      :lineColor="lineColor"
      :bgColor.sync="bgColor"
      class="hand_sign_area"
    />

    <div class="btn_footer">
      <el-button @click="handleReset">清空</el-button>
      <el-button @click="handleGenerate" type="primary">确定</el-button>
      <el-button @click="close" type="danger">取消</el-button>
    </div>
  </div>

</template>

<script>
import _ from 'lodash'

export default {
  name: "handSign",
  props: {
    value: {
      type: Object,
      default: () => {
        return null
      }
    },
  },
  data() {
    return {
      lineWidth: 2,
      lineColor: '#000000',
      bgColor: '',
      resultImg: '',
      isCrop: true,
      orientation: 'portrait',
      curSign: {
        width: this.value !== null ? _.clone(this.value.width) : '',
        height: this.value !== null ? _.clone(this.value.height) : "",
        resultImg: this.value !== null ? _.clone(this.value.sign) : ''
      }
    }
  },
  mounted() {
    //判断手机横竖屏状态：
    window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", function() {
      if (window.orientation === 180 || window.orientation === 0) {
        this.orientation = 'portrait'
      }
      if (window.orientation === 90 || window.orientation === -90) {
        this.orientation = 'landscape'
      }
    }, false);
  },
  computed: {
    width() {
      if (this.orientation === 'portrait') {
        return window.screen.height
      } else {
        return window.screen.width
      }
    },
    height() {
      if (this.orientation === 'portrait') {
        return window.screen.width - 120
      } else {
        return window.screen.height - 120
      }
    }
  },
  methods: {
    // 获取缩放数据
    getRect(rect) {
      this.curSign.width = rect.width
      this.curSign.height = rect.height
    },
    handleReset() {
      this.$refs.esign.reset()
    },
    close() {
      this.$emit('close', false)
    },
    goBack() {
      this.$emit("closeHandSign", false)
    },
    // 剪切
    handleCut() {
      this.$refs.esign.generate().then(async res => {
        this.resultImg = res
        this.getBase64WidthAndHeight(false)
      }).catch(err => {
        alert(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    },
    handleGenerate() {
      if (this.resultImg) {
        this.curSign.resultImg = this.resultImg
        this.getBase64WidthAndHeight(true)
      } else {
        this.$refs.esign.generate().then(res => {
          this.resultImg = res
          this.curSign.resultImg = res
          this.getBase64WidthAndHeight(true)
        }).catch(err => {
          alert(err) // 画布没有签字时会执行这里 'Not Signned'
        })
      }
    },
    // 获取base64图片的宽高
    async getBase64WidthAndHeight(close) {
      var img = new Image()
      img.src = this.resultImg
      img.onload = () => {
        if (this.curSign.width === '') {
          this.curSign.width = img.width
        }
        if (this.curSign.height === '') {
          this.curSign.height = img.height
        }
        if (close) {
          this.$emit('getSign', this.curSign)
          this.goBack()
        }
      }
    }
  }
}
</script>

<style scoped scoped>

  /*@media screen and (orientation: portrait) {*/
  /*  .main {*/
  /*    -webkit-transform: rotate(-90deg);*/
  /*    -moz-transform: rotate(-90deg);*/
  /*    -ms-transform: rotate(-90deg);*/
  /*    transform: rotate(-90deg);*/
  /*    width: 100vh;*/
  /*    height: 100vh;*/
  /*    !*去掉overflow 微信显示正常，但是浏览器有问题，竖屏时强制横屏缩小*!*/
  /*    overflow: hidden;*/
  /*  }*/
  /*}*/

  /*@media screen and (orientation: landscape) {*/
  /*  .main {*/
  /*    -webkit-transform: rotate(0);*/
  /*    -moz-transform: rotate(0);*/
  /*    -ms-transform: rotate(0);*/
  /*    transform: rotate(0)*/
  /*  }*/
  /*}*/

  .hand_sign_area, .cut_sign_area {
    background-color: white !important;
  }

  .btn_footer {
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
  }
</style>
<style lang="scss" scoped scoped>
  @import "../../style/var";

  .main {
    background-color: $main-bg;
  }
</style>
