<template>
  <div class="sz-select">
    <el-input v-model="curLabel" :class="[focus?'is-focus':'is-normal']" readonly @focus="focusDate" @blur="blurDate"
              ref="inputSelect"
              :placeholder="placeholder"  >
      <i class="el-icon-arrow-right" slot="append"></i>
    </el-input>
    <sz-dialog :visible="showItem" @cancelClick="showItem=false">
      <div class="content_header">{{header?header:placeholder}}</div>
      <div class="content_content">
        <div v-for="item in curOptions" class="content_mater_item" @click="selectItem(item)">
          <div>
            {{item.label}}
          </div>
        </div>
      </div>
    </sz-dialog>
  </div>

</template>

<script>
  /**
   *  移动端
   * 下拉 选择组件
   * */
  import _ from 'lodash'
  import {deepClone} from "./utils";
  export default {
    name: "sz-select",
    components: {},
    props: {
      // 默认值
      value: {
        type: String|Number,
        default: ''
      },
      staticOption: {
        type:Array,
        default: function() {
          return [];
        }
      },
      props:{
        type:Object,
        default: function () {
          return  {
            label: "label",
            value: "value",
          }
        }
      },
      optionMethod: {
        type:String,
        default:''
      },
      // 获取option方法
      placeholder: {
        type:String,
        default: '请选择'
      },
      header: {
        type:String,
        default: '请选择'
      },
      readonly: false,// 只读属性
      isDic: true,// 是否来源数据字典，配合optionMethod的值
      clearable: true,
      disabled: false,
      filterable: false,
      multiple: false
    },
    data() {
      return {
        focus: false,
        showItem: false,
        curValue: '',
        curLabel:'',
        curOptions:[]
      }
    },
    watch: {
      curValue(val) {
        if (val) {
          this.$emit("input", val)
        }
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.curOptions=[]
        if (this.optionMethod){
          if (this.isDic){
            let tem = this.$util.getDictionary(this.optionMethod)
            if (tem){
              tem.map(item=>{
                this.curOptions.push({label:item.label,value:item.keyword})
              })
            }
          }else {
            this.getOptionData(this.optionMethod)
          }
        }else {
          this.curOptions = this.staticOption
        }
        console.log(this.curOptions)
        if (this.value) {
          this.curValue = _.clone(this.value)
          this.curOptions.map((item=>{
            if (item[this.props.value]===this.value) {
              this.curLabel = item[this.props.label]
            }
          }))
        }
      })
    },
    methods: {
      focusDate(val) {
        if (!this.readonly) {
          this.focus = true
          this.showItem = true
        }
      },
      blurDate() {
        this.focus = false
      },
      // 选择item的方法
      selectItem(item) {
        this.curValue = item.value
        this.curLabel = item.label
        console.log( this.curValue, this.curLabel ,'11111')
        this.$emit('change',item)
        this.showItem = false
      },
      // 根据属性method获取数据
      getOptionData(method){
        this.$u.api.WorkFlowApi.getSelectData({path:method}).then(res=>{
          if (res){
            res.data.map(item=>{
              if (Number.isInteger(this.value)) {
                this.curOptions.push({label:item[this.props.label],value:item[this.props.value]})
              }else {
                this.curOptions.push({label:item[this.props.label],value:item[this.props.value]+""})
              }
            })
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped scoped>
  .sz-select{
    /deep/.el-input__inner{
      background-color: white !important;
    }
  }
  .content_content{
    max-height: 300px;
    overflow: auto;
  }
  .content_header {
    background-color: white;
    line-height: 60px;
    text-align: center;
    border-bottom: 1px solid #F5F6FA;

    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: bold;

    color: #303030;
    opacity: 1;
  }
  .content_mater_item {
    background-color: white;
    line-height: 35px;
    text-align: center;
    border-bottom: 1px solid #F5F6FA;
    font-size: 17px;
    font-family: Source Han Sans CN;
    color: #303030;
    opacity: 1;
    padding: 8px 16px;
  }

</style>
