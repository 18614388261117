const state = {
  applianceDot: 0
}
const mutations = {
  SET_DOT: (state, data) => {
    state.applianceDot = data;
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
