<template>
  <div class="sz-date-picker">
    <el-input v-model="curValue" :style="{textAlign: textAlign?textAlign:'right'}"
              :class="[focus?'is-focus':'is-normal']" readonly @focus="focusDate" @blur="blurDate"
              ref="inputDate"
              :placeholder="placeholder" :validate-event="validateEvent">
      <i class="el-icon-arrow-right" slot="append"></i>
    </el-input>
    <sz-date-time ref="dateTime"
                  :type="type"
                  v-model="curValue"
                  :format="valueFormat"
                  @confirm="select_confirm"></sz-date-time>
  </div>

</template>

<script>
  /**
   *  移动端
   *  时间-日期 选择组件
   * */
  import _ from "lodash";
  import time from '../dateTime/utils/dateTime'

  export default {
    name: "sz-date-picker",
    components: {},
    props: {
      value: {
        type: String,
        default: ""
      },
      valueFormat: {
        type: String,
        default: "yyyy-MM-dd"
      },
      // 文本对齐方式
      textAlign: {
        type: String,
        default: "right"
      },
      validateEvent: {
        type: Boolean,
        default: true
      },
      // 只读属性
      readonly: {
        type: Boolean,
        default: false
      },
      // date,time,datetime
      type: {
        type: String,
        default: "date"
      },
      placeholder: {
        type: String,
        default: "选择日期"
      }
    },
    data() {
      return {
        focus: false,
        curValue: ""
      };
    },
    watch: {
      curValue(val) {
        if (val) {
          this.$emit("input", val);
        }
      },
      value(val) {
        // if (val) {
        //   let str =val.replace(/-/g, "/");
        //   str = str.replace(".0", "");
        //   // console.log(str,'str',this.valueFormat,new Date(str))
        //   // let oldVal = time.format(new Date(str),this.valueFormat)
        //   let oldVal = val
        //   // console.log(str,'str',this.valueFormat,oldVal)
        //   this.curValue = _.clone(oldVal);
        // }else {
        //   this.curValue =""
        // }
      }
    },
    mounted() {
      if (this.value) {
        let str = this.value.replace(/-/g, "/");
        str = str.replace(".0", "");
        console.log(str, 'str', this.valueFormat)
        let oldVal = time.format(new Date(str), this.valueFormat)
        console.log(str, 'str', this.valueFormat, oldVal)
        this.curValue = _.clone(oldVal);
      } else {
        this.curValue = ""
      }
    },
    methods: {
      // 确定
      select_confirm(val) {
        console.log(val)
        this.curValue = val;
        this.$refs.inputDate.blur();
      },
      focusDate(val) {
        if (!this.readonly) {
          this.focus = true;
          this.$refs.dateTime.show();

        }
      },
      blurDate() {
        this.focus = false;
      },



    }
  };
</script>

<style lang="less" scoped scoped>
  .sz-date-picker {
    /deep/ .el-picker-panel {
      display: none !important;
    }

    /deep/ .el-input__inner {
      text-align: right;
      border: none;
      width: 100%;
    }

    /deep/ .el-input {
      width: 100% !important;
    }

    /deep/ .el-input__icon {
      width: auto;
    }

    /deep/ .el-input__suffix-inner {
      /deep/ .el-icon-arrow-right:before {
        content: "\e6e0";
        font-family: element-icons !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        width: auto;
        vertical-align: baseline;
        transition: transform .3s;
        cursor: pointer;
      }
    }

    /deep/ .el-input__suffix {
      right: 10px;
    }

    /deep/ .el-input__prefix {
      display: none;
    }
  }

  .is-focus {
    /deep/ .el-icon-arrow-right {
      transform: rotate(90deg);
    }
  }

  .is-normal {
    /deep/ .el-icon-arrow-right {
      transform: rotate(0deg);
    }
  }

</style>
