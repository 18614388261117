const state = {

	token: uni.getStorageSync("token"),
	serverToken: uni.getStorageSync("serverToken"),
	adminToken: uni.getStorageSync("adminToken"),
	adminUser: uni.getStorageSync("adminUser"),
	user: uni.getStorageSync("user"),
	serverUser: uni.getStorageSync("serverUser"),
	disabledPersonInfo: uni.getStorageSync("disabledPersonInfo"),
	userInfo: uni.getStorageSync("userInfo"),
	avater: uni.getStorageSync("avater"),
	openid: uni.getStorageSync("openid"),
	dict: uni.getStorageSync("dict"),














	unread: 0
}
const mutations = {
	// 用户信息
	SET_SELF: (state, {
		user,
		disabledPerson
	}) => {
		state.user = user;
		if (disabledPerson) state.disabledPersonInfo = disabledPerson;
		state.userInfo = JSON.stringify({
			user
		});

		uni.setStorageSync("user", user);
		if (disabledPerson) uni.setStorageSync("disabledPersonInfo", disabledPerson);
		uni.setStorageSync("userInfo", JSON.stringify({
			user
		}));
		// window.sessionStorage.setItem('userInfo', JSON.stringify({ user }))

	},

	// 服务商用户信息
	SET_SERVER_SELF: (state, {
		user
	}) => {
		state.serverUser = user;

		uni.setStorageSync("serverUser", user);

	},

	// admin用户
	SET_ADMIN_SELF: (state, {
		user
	}) => {
		state.adminUser = user;

		uni.setStorageSync("adminUser", user);

	},

	// 微信用户信息
	SET_WX_USER_INFO: (state, data) => {
		state.avater = data.headimgurl;
		state.openid = data.openid;

		uni.setStorageSync("avater", data.headimgurl);
		uni.setStorageSync("openid", data.openid);

	},

	// token
	SET_TOKEN: (state, value) => {
		state.token = value

		uni.setStorageSync("token", value);
		window.sessionStorage.setItem('token', value)

	},

	// 服务商token
	SET_SERVER_TOKEN: (state, value) => {
		state.serverToken = value

		uni.setStorageSync("serverToken", value);

	},

	// 服务商token
	SET_ADMIN_TOKEN: (state, value) => {
		state.adminToken = value

		uni.setStorageSync("adminToken", value);

	},

	// 数据字典
	SET_DICT: (state, value) => {
		console.log(value, 'value');
		state.dict = value

		uni.setStorageSync("dict", value);

	},

	// 退出登录
	LOGOUT: (state) => {
		state.user = {};
		state.token = null;
		state.disabledPersonInfo = {};
		state.userInfo = {};
		state.avater = null;
		state.openid = null;
		state.dict = [];
		state.unread = 0;

		uni.clearStorageSync()

	},

	// 服务商退出登录
	SERVER_LOGOUT: (state) => {
		state.serverUser = {};
		state.serverToken = null;

		uni.removeStorageSync('serverUser')
		uni.removeStorageSync('serverToken')

	},

	// admin退出登录
	ADMIN_LOGOUT: (state) => {
		state.adminUser = {};
		state.adminToken = null;

		uni.removeStorageSync('adminUser')
		uni.removeStorageSync('adminToken')

	},
	SET_UNREAD: (state, unread) => {
		state.unread = unread;
	}
}
const actions = {}
export default {
	namespaced: true,
	state,
	mutations,
	actions
};