<template>
  <div
    :class="[align === 'center' ? 'reply-input-table' : 'reply-input-flex']"
    v-if="!readonly"
  >
    <template v-for="(item, index) in copyItems">
      <div
        style="line-height: 40px; width: inherit; padding-left: 15px;"
        :style="getItemStyle(item)"
      >
        {{ item.text }}
        <template v-if="signAlign === 'right'">
          <span style="float: right; margin-left: 10px;" v-if="addDate">{{
            item.date
          }}</span
          ><span style="float: right;">{{ item.sign }}</span>
        </template>
        <template v-else>
          <span>{{ item.sign }}</span>
          <span style="margin-left: 10px;" v-if="addDate">{{ item.date }}</span>
        </template>
      </div>
    </template>
    <div style="width: inherit;">
      <el-input
        :placeholder="placeholder"
        v-model="mySignItem.text"
        :type="type"
        :readonly="readonly"
        clearable
        :validate-event="required"
        :class="[mySignItem.align === 'center' ? 'input_center' : 'input_left']"
        :style="getItemStyle(mySignItem)"
      >
        <template slot="prepend">{{ acceptText }}</template>
      </el-input>
      <div style="line-height: 35px; text-align: right; padding-right: 20px;">
        <span>{{ mySignItem.sign }}</span>
        <span v-if="addDate" style="margin-left: 10px;">{{
          mySignItem.date
        }}</span>
      </div>
    </div>

    <div class="common_lang" v-if="!readonly">
      <span style="margin: 3px 0px 3px 10px;">快捷回复：</span>
      <span
        class="common_item"
        v-for="(item, index) in commonWords"
        :key="index"
        @click="itemChange(item)"
        >{{ item.name }}</span
      >
    </div>
  </div>
  <div
    :class="[align === 'center' ? 'label-input-table' : 'label-input-flex']"
    v-else
  >
    <template v-for="(item, index) in defaultValue">
      <div
        style="line-height: 40px; width: inherit; padding-left: 15px;"
        :style="getItemStyle(item)"
      >
        {{ item.text }}
        <template v-if="signAlign === 'right'">
          <span style="float: right; margin-left: 10px;" v-if="addDate">{{
            item.date
          }}</span
          ><span style="float: right;">{{ item.sign }}</span>
        </template>
        <template v-else>
          <span>{{ item.sign }}</span>
          <span style="margin-left: 10px;" v-if="addDate">{{ item.date }}</span>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { deepClone } from "./utils/index";
import { mapGetters } from "vuex";

export default {
  name: "quick-reply",
  props: {
    align: {
      type: String,
      default: "left",
    },
    signAlign: {
      type: String,
      default: "left",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "textarea",
    },
    format: {
      type: String,
      default: "yyyy-MM-dd hh:mm:ss",
    },
    // 默认值
    defaultValue: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 显示签名按钮
    showSignButton: {
      type: Boolean,
      default: true,
    },
    // 显示同意按钮
    showAcceptButton: {
      type: Boolean,
      default: true,
    },
    // 显示不同意按钮
    showRejectButton: {
      type: Boolean,
      default: true,
    },
    // 显示取消按钮
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    // 只读属性
    readonly: {
      type: Boolean,
      default: false,
    },
    // 必填属性
    required: {
      type: Boolean,
      default: false,
    },
    // 是否追加时间
    addDate: {
      type: Boolean,
      default: false,
    },
    // 默认签名
    defaultSign: {
      type: Boolean,
      default: true,
    },
    // 是否需要使用合并数据属性
    isMerge: {
      type: Boolean,
      default: true,
    },
    fieldName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      commonWords: [],
      selectType: "",
      acceptText: "", // 同意，不同意的临时变量
      copyItems: [],
      formInputData: {},
      inputData: "", // 输入框的临时变量
      mySignItem: {
        text: "", //存储文本信息
        sign: "", //存储base64图片信息或者中文签名
        date: "", //签名时间
        type: "text_sign", //photo|hand_sign|template|text_and_sign|sign|text_sign
        userId: "", // "签名的用户ID"
        align: "left",
      },
      currentTokenUser: { user: JSON.parse(this.$store.getters.userInfo) },
    };
  },
  computed: {
    ...mapGetters({
      mergeFormData: "mergeFormData",
    }),
  },
  created() {},
  watch: {
    currentTokenUser: {
      handler(val) {
        if (val) {
          this.mySignItem.sign = val.user.name;
          this.mySignItem.userId = val.user.userId;
        }
      },
      deep: true,
    },
    fieldName: {
      handler(val) {
        if (val) {
          this.initFormData();
        }
      },
      deep: true,
    },
    mySignItem: {
      handler(val) {
        if (val) {
          let tem = [];
          if (this.mySignItem) {
            this.mySignItem.userId = this.currentTokenUser.user.userId;
          }
          if (this.defaultSign) {
            this.mySignItem.sign = this.currentTokenUser.user.name;
          }
          if (this.defaultSign) {
            this.mySignItem.date = new Date().Format(this.format);
          }
          if (this.defaultValue) {
            let hasExistedMy = false;
            this.defaultValue.map((item) => {
              if (item.userId === this.currentTokenUser.user.userId) {
                if (this.selectType !== "cancel") {
                  tem.push(this.mySignItem);
                  hasExistedMy = true;
                }
              } else {
                tem.push(item);
              }
            });
            if (!hasExistedMy) {
              if (this.selectType !== "cancel") {
                tem.push(this.mySignItem);
              }
            }
          } else {
            if (this.selectType !== "cancel") {
              tem.push(this.mySignItem);
            }
          }
          this.$emit("input", tem);
          this.formInputData[this.fieldName] = tem;
          console.log(this.formInputData, this.isMerge);
          if (this.isMerge) {
            this.$store.dispatch("formDesign/mergeFormDataAction", this.formInputData);
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getMyItem();
    this.getCommonLanguageList();
  },
  methods: {
    itemChange(item) {
      this.mySignItem.text = item.name + this.mySignItem.text;
      this.mySignItem.sign = this.currentTokenUser.user.name;
      this.mySignItem.date = new Date().Format(this.format);
    },

    getCommonLanguageList() {
      this.API.getCommonLanguageList().then((res) => {
        this.commonWords = res.data;
      });
    },
    changeType(label) {
      this.selectType = label;
      switch (label) {
        case "sign":
          this.mySignItem.sign = this.currentTokenUser.user.name;
          this.mySignItem.date = new Date().Format(this.format);
          break;
        case "accept":
          this.acceptText = "同意";
          console.log(this.mySignItem.text.startsWith("不同意"));
          if (this.mySignItem.text.startsWith("不同意")) {
            this.mySignItem.text = this.mySignItem.text.substring(1);
          } else if (!this.mySignItem.text.startsWith("同意")) {
            if (this.mySignItem.text) {
              this.mySignItem.text =
                this.acceptText + "," + this.mySignItem.text;
            } else {
              this.mySignItem.text = this.acceptText + this.mySignItem.text;
            }
          }
          this.mySignItem.sign = this.currentTokenUser.user.name;
          this.mySignItem.date = new Date().Format(this.format);
          break;
        case "reject":
          this.acceptText = "不同意";
          if (this.mySignItem.text.startsWith("同意")) {
            this.mySignItem.text = "不" + this.mySignItem.text;
          } else if (!this.mySignItem.text.startsWith("不同意")) {
            if (this.mySignItem.text) {
              this.mySignItem.text =
                this.acceptText + "," + this.mySignItem.text;
            } else {
              this.mySignItem.text = this.acceptText + this.mySignItem.text;
            }
          }
          this.mySignItem.sign = this.currentTokenUser.user.name;
          this.mySignItem.date = new Date().Format(this.format);
          break;
        case "cancel":
          this.mySignItem.text = "";
          this.mySignItem.sign = "";
          this.mySignItem.date = "";
          this.mySignItem.type = "text_sign";
          this.mySignItem.userId = "";
          this.mySignItem.align = "left";
          console.log(this.mySignItem);
          this.acceptText = "";
          break;
      }
    },
    // 获取我的
    getMyItem() {
      if (this.defaultValue) {
        this.defaultValue.map((item) => {
          if (
            this.currentTokenUser &&
            item.userId === this.currentTokenUser.user.userId &&
            this.readonly === false
          ) {
            this.mySignItem = item;
          } else {
            this.copyItems.push(item);
          }
        });
        this.initFormData();
      }
    },
    // 初始化formData
    initFormData() {
      this.$set(this.formInputData, this.fieldName, this.defaultValue);
      if (this.isMerge&&!this.required) {
        this.$store.dispatch("formDesign/mergeFormDataAction", this.formInputData);
      }
    },
    getItemStyle(item) {
      let style = "";
      if (item.width) {
        style +=
          "width:" +
          (item.width.endsWith("px") ? item.width : item.width + "px") +
          ";";
      }
      if (item.color) {
        style += "color:" + item.color + ";";
      }
      if (item.align) {
        style += "text-align:" + item.align + ";";
      }
      return style;
    },
  },
};
</script>

<style lang="less" scoped scoped>
.common_lang {
  text-align: left;
  display: flex;
  width: inherit;
  flex-flow: wrap;
}

.common_item {
  background-color: #e7e7e7;
  margin: 3px 5px;
  padding: 0px 10px;
  border-radius: 10px;
  cursor: pointer;
  color: #606266;
  font-size: 14px;
}

.reply-input-flex {
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
  justify-context: centet;
  align-items: center;
}

.reply-input-table {
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: table;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
  justify-context: centet;
  align-items: center;
}

/deep/ .input_center {
  .el-input__inner {
    text-align: center !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/deep/ .input_left {
  .el-input__inner {
    text-align: left !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
<style lang="scss" scoped scoped>
  @import "../../style/var";

.common_item:hover {
  background-color: $color-primary;
  color: white;
}
</style>
