
import Vue from 'vue'

global['________'] = true;
delete global['________'];
global.__uniConfig = {"easycom":{"^u-(.*)":"uview-ui/components/u-$1/u-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"},"globalStyle":{"navigationStyle":"custom","navigationBarTextStyle":"black","navigationBarTitleText":"残联","navigationBarBackgroundColor":"#ffffff","backgroundColor":"#ffffff","scrollIndicator":"none"},"tabBar":{"color":"#a2a2a2","backGroundColor":"#fffff","selectedColor":"#2EAE01","borderStyle":"white","list":[{"pagePath":"pages/tabbar/index/index","text":"首页","iconPath":"static/tabbars/bottom_icon-1@2x.png","selectedIconPath":"static/tabbars/bottom_icon@2x.png","redDot":false,"badge":""},{"pagePath":"pages/tabbar/work/index","text":"办事大厅","iconPath":"static/tabbars/bottom_icon_2-1@2x.png","selectedIconPath":"static/tabbars/bottom_icon_2@2x.png","redDot":false,"badge":""},{"pagePath":"pages/tabbar/user/index","text":"我的","iconPath":"static/tabbars/bottom_icon3_1@2x.png","selectedIconPath":"static/tabbars/bottom_icon_3@2x.png","redDot":false,"badge":""}],"backgroundColor":""},"condition":{"current":0,"list":[{"name":"","path":"","query":""}]}};
global.__uniConfig.compilerVersion = '3.1.8';
global.__uniConfig.router = {"mode":"hash","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = "XVXBZ-NDMC4-JOGUS-XGIEE-QVHDZ-AMFV2";
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-tabbar-index-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/tabbar/index/index.vue')), 'pages-tabbar-index-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-tabbar-work-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/tabbar/work/index.vue')), 'pages-tabbar-work-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-tabbar-user-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/tabbar/user/index.vue')), 'pages-tabbar-user-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-wxLogin', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/login/wxLogin.vue')), 'pages-login-wxLogin'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-registerWay', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/login/registerWay.vue')), 'pages-login-registerWay'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-bindAccount', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/login/bindAccount.vue')), 'pages-login-bindAccount'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-register', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/login/register.vue')), 'pages-login-register'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-providerLogin', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/login/providerLogin.vue')), 'pages-login-providerLogin'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-article-articleList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/article/articleList.vue')), 'pages-article-articleList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-article-articleDetails', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/article/articleDetails.vue')), 'pages-article-articleDetails'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-workFlow-apply-workflowApplyMobile', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/workFlow/apply/workflowApplyMobile.vue')), 'pages-workFlow-apply-workflowApplyMobile'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-userNotice', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/userNotice.vue')), 'pages-work-userNotice'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-recovery', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/recovery.vue')), 'pages-work-recovery'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-application-recoveryDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/application/recoveryDetail.vue')), 'pages-work-application-recoveryDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-chooseMechanism', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/chooseMechanism.vue')), 'pages-work-chooseMechanism'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-application-appliancesDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/application/appliancesDetail.vue')), 'pages-work-application-appliancesDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-questionnaire', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/questionnaire.vue')), 'pages-user-questionnaire'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-questionnaireContent', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/questionnaireContent.vue')), 'pages-user-questionnaireContent'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-mineEvaluation', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/mineEvaluation.vue')), 'pages-user-mineEvaluation'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-application-myApplication', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/application/myApplication.vue')), 'pages-work-application-myApplication'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-training', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/training.vue')), 'pages-user-training'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-trainingDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/trainingDetail.vue')), 'pages-user-trainingDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-signIn', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/signIn.vue')), 'pages-user-signIn'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-appliancesApply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/appliancesApply.vue')), 'pages-work-appliancesApply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-application-barrierFreeDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/application/barrierFreeDetail.vue')), 'pages-work-application-barrierFreeDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-applianceActivities', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/applianceActivities.vue')), 'pages-work-applianceActivities'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-helpOther-applianceActivities', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/helpOther/applianceActivities.vue')), 'pages-work-helpOther-applianceActivities'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-helpOther-chooseAppliances', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/helpOther/chooseAppliances.vue')), 'pages-work-helpOther-chooseAppliances'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-helpOther-appliancesApply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/helpOther/appliancesApply.vue')), 'pages-work-helpOther-appliancesApply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-chooseAppliances', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/chooseAppliances.vue')), 'pages-work-chooseAppliances'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-provider', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/provider.vue')), 'pages-work-provider'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-providerAppraisal', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/providerAppraisal.vue')), 'pages-work-providerAppraisal'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-stockingDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/stockingDetail.vue')), 'pages-work-stockingDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-appraisalDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/appraisalDetail.vue')), 'pages-work-appraisalDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-providerInfo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/providerInfo.vue')), 'pages-work-providerInfo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-providerAppliance', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/providerAppliance.vue')), 'pages-work-providerAppliance'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-providerHandle', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/providerHandle.vue')), 'pages-work-providerHandle'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-providerHandlePeople', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/providerHandlePeople.vue')), 'pages-work-providerHandlePeople'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-providerHandlePeopleDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/providerHandlePeopleDetail.vue')), 'pages-work-providerHandlePeopleDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-boundGuardian', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/boundGuardian.vue')), 'pages-user-boundGuardian'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-user', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/user.vue')), 'pages-user-user'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-notification', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/user/notification.vue')), 'pages-user-notification'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-mineSignature', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/mineSignature.vue')), 'pages-work-mineSignature'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-signature', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/signature.vue')), 'pages-work-signature'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-systemLoginWay', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/login/systemLoginWay.vue')), 'pages-login-systemLoginWay'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-adminTodo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/adminTodo.vue')), 'pages-work-adminTodo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-adminUser', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/adminUser.vue')), 'pages-work-adminUser'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-institutional-institutionalView', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/institutional/institutionalView.vue')), 'pages-work-institutional-institutionalView'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-institutional-institutionalDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/institutional/institutionalDetail.vue')), 'pages-work-institutional-institutionalDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-institutional-infoDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/institutional/infoDetail.vue')), 'pages-work-institutional-infoDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-institutional-teacherDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/institutional/teacherDetail.vue')), 'pages-work-institutional-teacherDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-courseSignature-courseList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/courseSignature/courseList.vue')), 'pages-work-courseSignature-courseList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-courseSignature-courseDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/courseSignature/courseDetail.vue')), 'pages-work-courseSignature-courseDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-superviseTheTask-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/superviseTheTask/index.vue')), 'pages-work-superviseTheTask-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-superviseTheTask-components-superviseTheTaskDetails', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/superviseTheTask/components/superviseTheTaskDetails.vue')), 'pages-work-superviseTheTask-components-superviseTheTaskDetails'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-work-residualFundTask-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('D:/workspace/disabled-h5/src/pages/work/residualFundTask/index.vue')), 'pages-work-residualFundTask-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/tabbar/index/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,
isTabBar:true,

          
          tabBarIndex:0
        },__uniConfig.globalStyle,{"navigationBarTitleText":"首页"})
      },
      [
        createElement('pages-tabbar-index-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-tabbar-index-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/tabbar/index/index',
isQuit:true,
isEntry:true,
isTabBar:true,
tabBarIndex:0,
  windowTop:0
}
},
{
path: '/pages/tabbar/work/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:1
        },__uniConfig.globalStyle,{"navigationBarTitleText":"办事大厅"})
      },
      [
        createElement('pages-tabbar-work-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:2,
  name:'pages-tabbar-work-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/tabbar/work/index',
isQuit:true,
isTabBar:true,
tabBarIndex:1,
  windowTop:0
}
},
{
path: '/pages/tabbar/user/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:2
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的"})
      },
      [
        createElement('pages-tabbar-user-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:3,
  name:'pages-tabbar-user-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/tabbar/user/index',
isQuit:true,
isTabBar:true,
tabBarIndex:2,
  windowTop:0
}
},
{
path: '/pages/login/wxLogin',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"微信授权登录"})
      },
      [
        createElement('pages-login-wxLogin', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-wxLogin',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/wxLogin',
  windowTop:0
}
},
{
path: '/pages/login/registerWay',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"注册方式"})
      },
      [
        createElement('pages-login-registerWay', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-registerWay',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/registerWay',
  windowTop:0
}
},
{
path: '/pages/login/bindAccount',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"绑定已有账号"})
      },
      [
        createElement('pages-login-bindAccount', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-bindAccount',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/bindAccount',
  windowTop:0
}
},
{
path: '/pages/login/register',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"注册新账号"})
      },
      [
        createElement('pages-login-register', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-register',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/register',
  windowTop:0
}
},
{
path: '/pages/login/providerLogin',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"服务商登录"})
      },
      [
        createElement('pages-login-providerLogin', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-providerLogin',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/providerLogin',
  windowTop:0
}
},
{
path: '/pages/article/articleList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"文章列表"})
      },
      [
        createElement('pages-article-articleList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-article-articleList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/article/articleList',
  windowTop:0
}
},
{
path: '/pages/article/articleDetails',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"文章详情"})
      },
      [
        createElement('pages-article-articleDetails', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-article-articleDetails',
  isNVue:false,maxWidth:0,
  pagePath:'pages/article/articleDetails',
  windowTop:0
}
},
{
path: '/pages/workFlow/apply/workflowApplyMobile',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"发起流程"})
      },
      [
        createElement('pages-workFlow-apply-workflowApplyMobile', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-workFlow-apply-workflowApplyMobile',
  isNVue:false,maxWidth:0,
  pagePath:'pages/workFlow/apply/workflowApplyMobile',
  windowTop:0
}
},
{
path: '/pages/work/userNotice',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"用户须知"})
      },
      [
        createElement('pages-work-userNotice', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-userNotice',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/userNotice',
  windowTop:0
}
},
{
path: '/pages/work/recovery',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"儿童康复救助申请"})
      },
      [
        createElement('pages-work-recovery', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-recovery',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/recovery',
  windowTop:0
}
},
{
path: '/pages/work/application/recoveryDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"康复救助申请详情"})
      },
      [
        createElement('pages-work-application-recoveryDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-application-recoveryDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/application/recoveryDetail',
  windowTop:0
}
},
{
path: '/pages/work/chooseMechanism',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"选择机构"})
      },
      [
        createElement('pages-work-chooseMechanism', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-chooseMechanism',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/chooseMechanism',
  windowTop:0
}
},
{
path: '/pages/work/application/appliancesDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"辅助器具申请详情"})
      },
      [
        createElement('pages-work-application-appliancesDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-application-appliancesDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/application/appliancesDetail',
  windowTop:0
}
},
{
path: '/pages/user/questionnaire',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"问卷调查"})
      },
      [
        createElement('pages-user-questionnaire', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-questionnaire',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/questionnaire',
  windowTop:0
}
},
{
path: '/pages/user/questionnaireContent',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"问卷内容"})
      },
      [
        createElement('pages-user-questionnaireContent', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-questionnaireContent',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/questionnaireContent',
  windowTop:0
}
},
{
path: '/pages/user/mineEvaluation',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的评价"})
      },
      [
        createElement('pages-user-mineEvaluation', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-mineEvaluation',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/mineEvaluation',
  windowTop:0
}
},
{
path: '/pages/work/application/myApplication',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的申请"})
      },
      [
        createElement('pages-work-application-myApplication', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-application-myApplication',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/application/myApplication',
  windowTop:0
}
},
{
path: '/pages/user/training',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我参与的"})
      },
      [
        createElement('pages-user-training', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-training',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/training',
  windowTop:0
}
},
{
path: '/pages/user/trainingDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"培训详情"})
      },
      [
        createElement('pages-user-trainingDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-trainingDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/trainingDetail',
  windowTop:0
}
},
{
path: '/pages/user/signIn',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"培训签到"})
      },
      [
        createElement('pages-user-signIn', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-signIn',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/signIn',
  windowTop:0
}
},
{
path: '/pages/work/appliancesApply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"器具适配申请"})
      },
      [
        createElement('pages-work-appliancesApply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-appliancesApply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/appliancesApply',
  windowTop:0
}
},
{
path: '/pages/work/application/barrierFreeDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"无障碍改造详情"})
      },
      [
        createElement('pages-work-application-barrierFreeDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-application-barrierFreeDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/application/barrierFreeDetail',
  windowTop:0
}
},
{
path: '/pages/work/applianceActivities',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"器具适配活动列表"})
      },
      [
        createElement('pages-work-applianceActivities', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-applianceActivities',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/applianceActivities',
  windowTop:0
}
},
{
path: '/pages/work/helpOther/applianceActivities',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"器具适配活动列表"})
      },
      [
        createElement('pages-work-helpOther-applianceActivities', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-helpOther-applianceActivities',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/helpOther/applianceActivities',
  windowTop:0
}
},
{
path: '/pages/work/helpOther/chooseAppliances',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"选择器具"})
      },
      [
        createElement('pages-work-helpOther-chooseAppliances', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-helpOther-chooseAppliances',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/helpOther/chooseAppliances',
  windowTop:0
}
},
{
path: '/pages/work/helpOther/appliancesApply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"器具适配申请"})
      },
      [
        createElement('pages-work-helpOther-appliancesApply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-helpOther-appliancesApply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/helpOther/appliancesApply',
  windowTop:0
}
},
{
path: '/pages/work/chooseAppliances',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"选择器具"})
      },
      [
        createElement('pages-work-chooseAppliances', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-chooseAppliances',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/chooseAppliances',
  windowTop:0
}
},
{
path: '/pages/work/provider',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"备货列表"})
      },
      [
        createElement('pages-work-provider', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-provider',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/provider',
  windowTop:0
}
},
{
path: '/pages/work/providerAppraisal',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"鉴定列表"})
      },
      [
        createElement('pages-work-providerAppraisal', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-providerAppraisal',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/providerAppraisal',
  windowTop:0
}
},
{
path: '/pages/work/stockingDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"备货详情"})
      },
      [
        createElement('pages-work-stockingDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-stockingDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/stockingDetail',
  windowTop:0
}
},
{
path: '/pages/work/appraisalDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"鉴定详情"})
      },
      [
        createElement('pages-work-appraisalDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-appraisalDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/appraisalDetail',
  windowTop:0
}
},
{
path: '/pages/work/providerInfo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的"})
      },
      [
        createElement('pages-work-providerInfo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-providerInfo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/providerInfo',
  windowTop:0
}
},
{
path: '/pages/work/providerAppliance',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"器具申请"})
      },
      [
        createElement('pages-work-providerAppliance', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-providerAppliance',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/providerAppliance',
  windowTop:0
}
},
{
path: '/pages/work/providerHandle',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"活动列表"})
      },
      [
        createElement('pages-work-providerHandle', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-providerHandle',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/providerHandle',
  windowTop:0
}
},
{
path: '/pages/work/providerHandlePeople',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"人员列表"})
      },
      [
        createElement('pages-work-providerHandlePeople', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-providerHandlePeople',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/providerHandlePeople',
  windowTop:0
}
},
{
path: '/pages/work/providerHandlePeopleDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"详情"})
      },
      [
        createElement('pages-work-providerHandlePeopleDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-providerHandlePeopleDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/providerHandlePeopleDetail',
  windowTop:0
}
},
{
path: '/pages/user/boundGuardian',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"绑定监护人"})
      },
      [
        createElement('pages-user-boundGuardian', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-boundGuardian',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/boundGuardian',
  windowTop:0
}
},
{
path: '/pages/user/user',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"用户信息"})
      },
      [
        createElement('pages-user-user', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-user',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/user',
  windowTop:0
}
},
{
path: '/pages/user/notification',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的消息"})
      },
      [
        createElement('pages-user-notification', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-notification',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/notification',
  windowTop:0
}
},
{
path: '/pages/work/mineSignature',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的签名"})
      },
      [
        createElement('pages-work-mineSignature', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-mineSignature',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/mineSignature',
  windowTop:0
}
},
{
path: '/pages/work/signature',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"手写签名"})
      },
      [
        createElement('pages-work-signature', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-signature',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/signature',
  windowTop:0
}
},
{
path: '/pages/login/systemLoginWay',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"系统用户登录"})
      },
      [
        createElement('pages-login-systemLoginWay', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-systemLoginWay',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/systemLoginWay',
  windowTop:0
}
},
{
path: '/pages/work/adminTodo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"待办事项"})
      },
      [
        createElement('pages-work-adminTodo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-adminTodo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/adminTodo',
  windowTop:0
}
},
{
path: '/pages/work/adminUser',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"医院信息"})
      },
      [
        createElement('pages-work-adminUser', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-adminUser',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/adminUser',
  windowTop:0
}
},
{
path: '/pages/work/institutional/institutionalView',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"机构查看"})
      },
      [
        createElement('pages-work-institutional-institutionalView', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-institutional-institutionalView',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/institutional/institutionalView',
  windowTop:0
}
},
{
path: '/pages/work/institutional/institutionalDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"机构详情"})
      },
      [
        createElement('pages-work-institutional-institutionalDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-institutional-institutionalDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/institutional/institutionalDetail',
  windowTop:0
}
},
{
path: '/pages/work/institutional/infoDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"文章详情"})
      },
      [
        createElement('pages-work-institutional-infoDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-institutional-infoDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/institutional/infoDetail',
  windowTop:0
}
},
{
path: '/pages/work/institutional/teacherDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"教师详情"})
      },
      [
        createElement('pages-work-institutional-teacherDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-institutional-teacherDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/institutional/teacherDetail',
  windowTop:0
}
},
{
path: '/pages/work/courseSignature/courseList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程签到"})
      },
      [
        createElement('pages-work-courseSignature-courseList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-courseSignature-courseList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/courseSignature/courseList',
  windowTop:0
}
},
{
path: '/pages/work/courseSignature/courseDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程签到"})
      },
      [
        createElement('pages-work-courseSignature-courseDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-courseSignature-courseDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/courseSignature/courseDetail',
  windowTop:0
}
},
{
path: '/pages/work/superviseTheTask/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"督办任务"})
      },
      [
        createElement('pages-work-superviseTheTask-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-superviseTheTask-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/superviseTheTask/index',
  windowTop:0
}
},
{
path: '/pages/work/superviseTheTask/components/superviseTheTaskDetails',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"任务详情"})
      },
      [
        createElement('pages-work-superviseTheTask-components-superviseTheTaskDetails', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-superviseTheTask-components-superviseTheTaskDetails',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/superviseTheTask/components/superviseTheTaskDetails',
  windowTop:0
}
},
{
path: '/pages/work/residualFundTask/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"残保金任务"})
      },
      [
        createElement('pages-work-residualFundTask-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-work-residualFundTask-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/work/residualFundTask/index',
  windowTop:0
}
},
{
path: '/preview-image',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-preview-image', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'preview-image',
  pagePath:'/preview-image'
}
}
    ,
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
