<template>
  <div class="city-rural-select">
    <el-row :gutter="10" :key="selectModel">
      <el-col :span="8" v-for="(item, index) in outSelectConfig" :key="index">
        <div class="select-item">
          <tree-select-item
            :selectConfig="item"
            :selectOrigin="outSelectOrigin[index]"
            :selectLevel="item.selectLevel"
            v-model="selectValue[index]"
            @selectChange="selectChange"
          ></tree-select-item>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import treeSelectItem from "./treeSelectItem.vue";
export default {
  components: { treeSelectItem },

  name: "cityRuralSelect",

  props: {
    // 选框数据源
    selectOrigin: {
      type: [Array],
      default: () => {
        return [];
      }
    },
    // 选框关联标识
    selectSign: {
      type: [String, Number],
      default: ""
    },
    // 选框等级Level
    selectLevel: {
      type: [Number],
      default: 0
    },
    // 选框模式 city | rural
    selectModel: {
      type: [String],
      default: "city"
    },
    // 双向绑定值
    value: {
      type: [String, Array],
      default: ""
    }
  },

  data() {
    return {
      // 输出数据源
      outSelectOrigin: [],
      // 输出组件配置（组件配置）
      outSelectConfig: [],
      // 选框数据
      selectValue: []
    };
  },

  mounted() {
    this.init();
  },
  watch: {
    selectModel: {
      handler() {
        this.init();
      },
      deep: true
    }
  },
  methods: {
    // 初始化
    init() {
      let that = this;
      this.setInitData();
      if (this.selectModel == "city") {
        this.getSelectOrigin({ level: 1, pid: 0 });
      }
      if (this.selectModel == "rural") {
        this.$bus.$on("citySelectDone", function(obj = {}) {
          let { selectSign } = obj;
          if (that.$props.selectSign == selectSign) {
            let { value } = obj;
            that.getSelectOrigin({ level: 4, pid: value });
          }
          // console.log(selectSign, that.selectModel);
        });
      }
    },
    // 选框事件
    selectChange(obj = {}) {
      this.$emit("input", this.selectValue);
      let { selectLevel, value } = obj;
      if (selectLevel == 3) {
        this.$bus.$emit("citySelectDone", {
          value,
          selectSign: this.$props.selectSign
        });
        return;
      }
      if (selectLevel == 6) {
        return;
      }
      this.getSelectOrigin({ level: selectLevel + 1, pid: value });
    },
    // 拿取级联数据
    getSelectOrigin(obj = {}) {
      let { level, pid } = obj;
      this.API.getSysAreaByLevelAndPid({ level, pid }).then(res => {
        let index = level;
        if (this.selectModel == "rural") {
          index = level - 3;
        }
        let { data } = res;
        this.outSelectOrigin[index - 1] = data;
        this.$forceUpdate();
      });
    },
    // 处理外部传入的数据
    setInitData() {
      this.outSelectOrigin = _.cloneDeep(this.$props.selectOrigin);
      if (this.$props.selectModel == "city") {
        this.outSelectConfig = [
          {
            placeholder: "省（自治区、直辖市）",
            type: "empty",
            selectLevel: 1
          },
          {
            placeholder: "市（地）",
            type: "empty",
            selectLevel: 2
          },
          {
            placeholder: "县（市、区）",
            type: "empty",
            selectLevel: 3
          }
        ];
      } else {
        this.outSelectConfig = [
          {
            placeholder: "乡（镇、街道）",
            type: "line",
            selectLevel: 4
          },
          {
            placeholder: "村（社区）",
            type: "line",
            selectLevel: 5
          },
          {
            placeholder: "",
            type: "text",
            selectLevel: 6
          }
        ];
      }
    }
  },
  beforeDestroy() {
    this.$off("citySelectDone");
  }
};
</script>

<style lang="scss" scoped scoped>
.city-rural-select {
  .select-item {
    display: flex;
    align-items: center;
    .dec-text {
      margin-left: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}
</style>
