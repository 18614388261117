/**
 * 技能培训接口
 */
export default class TrainingApi {
  constructor(vm) {
    this.vm = vm
  }

  // 查询我参加过培训签到列表
  getMineTraining(params = {}) {
    return this.vm.$u.get('/api/skill/training/mine', params);
  }

  // 根据id查询技能培训内容
  getTrainingById({ id }) {
    return this.vm.$u.get(`/api/skill/training/${id}`,);
  }

  // 查询培训签到列表
  getTrainingSign(params = {}) {
    params.name = this.vm.$store.getters.user.name
    params.userId = this.vm.$store.getters.user.userId
    return this.vm.$u.get('/api/skill/training/sign', params);
  }

  // 新增或修改培训签到(残疾人签到使用)
  trainingSign(params = {}) {
    return this.vm.$u.post('/api/skill/training/sign', params);
  }
}
