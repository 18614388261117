/**
 * 问卷调查接口
 */
export default class QuestionnaireApi {
  constructor(vm) {
    this.vm = vm
  }

  // 查询已发布和完结的问卷调查列表
  getList(params) {
    return this.vm.$u.get('/api/questionnaire/list', params);
  }

  // 查询我参与的问卷调查列表
  getMineQuestionnaireList(params) {
    return this.vm.$u.get('/api/questionnaire/mine', params);
  }

  // 问卷调查提交
  answerQuestionnaire(params) {
    return this.vm.$u.post('/api/user/questionnaire/answer/record', params);
  }

  // 根据id查询用户问卷答题记录
  getQuestionnaireById({ questionnaireId }) {
    const userId = this.vm.$store.getters.user.userId
    return this.vm.$u.get(`/api/user/questionnaire/answer/record/questionnaire/${questionnaireId}/user/${userId}`)
  }
}
