// 仅OA工作流使用
var globalConf = {};

switch (process.env.NODE_ENV) {
    case 'development':
        {
            console.log('开发环境');
            let ipAdd = 'admin.office-cloud.cn';
            globalConf = {
                domain: {
                    api: 'https://' + ipAdd + '/api',
                    image: 'https://' + ipAdd + '/api',
                    image1: `https://admin.office-cloud.cn:8849`,
                    uploadUrl: 'https://' + ipAdd + '/api/file/upload/name',
                    uploadUrl1: 'https://' + ipAdd + '/api/sys/file/upload/',
                    sysUploadUrl: 'https://' + ipAdd + '/api/sys/file/db/upload',
                    wpsUploadUrl: 'https://' + ipAdd + '/api/wps/upload/',
                    secretFilePreview: 'https://' + ipAdd + '/api/sys/file/',
                    preFile: 'https://123.6.56.160:8012/onlinePreview', //文件预览服务
                    wxAppId: 'wx135a3221df70101f',
                },
                needAuth: true,
            };
        }
        break;
    case 'production':
        {
            console.log('生产环境');
            console.log(location, 'location');
            let ipAdd = 'admin.office-cloud.cn'; //正式
            globalConf = {
                domain: {
                    api: 'https://' + ipAdd + '/api',
                    image: 'https://' + ipAdd + '/api',
                    image1: `https://admin.office-cloud.cn:8849`,
                    uploadUrl: 'https://' + ipAdd + '/api/file/upload/name',
                    uploadUrl1: 'https://' + ipAdd + '/api/sys/file/upload/',
                    sysUploadUrl: 'https://' + ipAdd + '/api/sys/file/db/upload',
                    wpsUploadUrl: 'https://' + ipAdd + '/api/wps/upload/',
                    secretFilePreview: 'https://' + ipAdd + '/api/sys/file/',
                    preFile: 'https://123.6.56.160:8012/onlinePreview', //文件预览服务
                    wxAppId: 'wx135a3221df70101f',
                },
                needAuth: true,
            };
        }
        break;
}
globalConf.appTitle = '洛阳三智信息科技有限公司';
globalConf.loginSuccessRoute = { name: 'admin' };
window.api = {};
window.api.domain = globalConf.domain.api;
window.api.codeUrl = globalConf.domain.codeUrl;
window.api.image = globalConf.domain.image; //图片、附件前缀
window.api.imageDomain = globalConf.domain.image;
window.api.imageDomain1 = globalConf.domain.image1;
window.api.uploadUrl = globalConf.domain.uploadUrl;
window.api.uploadUrl1 = globalConf.domain.uploadUrl1;
window.api.sysUploadUrl = globalConf.domain.sysUploadUrl;
window.api.secretFilePreview = globalConf.domain.secretFilePreview;
window.api.preFile = globalConf.domain.preFile;
window.api.wpsUploadUrl = globalConf.domain.wpsUploadUrl;
window.api.wxAppId = globalConf.domain.wxAppId;

module.exports = globalConf;
