/**
 * 儿童康复救助接口
 */
export default class RecoveryApi {
  constructor(vm) {
    this.vm = vm
  }

  // 申请/驳回再申请/选择康复机构/儿童康复救助
  applicationRecovery(params = {}) {
    return this.vm.$u.post('/api/recovery', params);
  }

  // 查询我发起的儿童康复救助(申请)列表
  getMineApplicationList(params = {}) {
    return this.vm.$u.get('/api/recovery/mine', params);
  }

  // 根据id查询儿童康复救助申请
  getApplicationDetailById({ id }) {
    return this.vm.$u.get(`/api/recovery/${id}`);
  }

  // 获取康复机构 分页
  getRecoveryInstituteList(params = {}) {
    return this.vm.$u.get('/api/recovery/institute', params);
  }

  // 根据id获取康复机构详情
  getRecoveryInstituteById({ id }) {
    return this.vm.$u.get(`/api/recovery/institute/${id}`);
  }

  // 查询康复机构信息列表（分页)
  getRecoveryInstitutePageList(params = {}) {
    return this.vm.$u.get("api/recovery/institute", params);
  }
  // 根据id查询康复机构信息
  getRecoveryInstituteById({id}){
    return this.vm.$u.get(`api/recovery/institute/${id}`);
  }
  // 根据机构id查询机构其下所有教师
  getRecoveryTeacherList({institutionId}){
    return this.vm.$u.get(`/api/recovery/teacher/all/${institutionId}`);
  }
  // 根据id查询教师
  getRecoveryTeacherById({id}){
    return this.vm.$u.get(`/api/recovery/teacher/${id}`);
  }
  // 根据机构id查询其下所有文章栏目
  getAllRecoveryItem({institutionId}){
    return this.vm.$u.get(`/api/recovery/item/list/${institutionId}`);
  }
  // 根据机构id查询其下分页资讯信息 
  getAllRecoveryArticle({institutionId,itemId,startYear,endYear}){
    return this.vm.$u.get(`/api/recovery/article/list/${institutionId}`,{itemId,startYear,endYear});
  }
  // 根据id查询文章信息 
  getRecoveryArticleById({id}){
    return this.vm.$u.get(`api/recovery/article/${id}`);
  }
  // 查询当前用户是否有儿童救助的申请
  haveApplied(){
    return this.vm.$u.get(`api/recovery/receive/has`);
  }
}

