import Vue from "vue";
import uniCrazyRouter from "uni-crazy-router";
import store from "../store";

Vue.use(uniCrazyRouter);

uniCrazyRouter.beforeEach(async(to, from, next) => {
  if (to.url === '/') to.url = 'pages/tabbar/index/index'

  // 路由白名单
  const whiteList = [
    'pages/tabbar/index/index',  // 首页
    'pages/tabbar/user/index',  // 我的
    'pages/tabbar/work/index',  // 办事大厅
    'pages/login/wxLogin',  // 微信授权登录
    'pages/login/registerWay',  // 注册方式
    'pages/login/bindAccount',  // 绑定已有账号
    'pages/login/register',  // 注册新账号
    'pages/login/systemLoginWay',  // 系统用户登录
    'pages/article/articleList',  // 文章列表
    'pages/article/articleDetails',  // 文章详情
    'pages/login/providerLogin',  // 服务商登录
    'pages/work/provider',  // 服务商备货列表
    'pages/work/providerInfo',  // 服务商我的
    'pages/work/stockingDetail',  // 备货详情
    'pages/work/providerAppraisal',  // 鉴定列表
    'pages/work/signature',  // 医院手写签名
    'pages/work/mineSignature',  // 我的手写签名
    'pages/work/adminTodo',  // 医院待办
    'pages/work/adminUser',  // 医院信息
    'pages/work/appraisalDetail',  // 鉴定信息
    'pages/work/providerAppliance',  // 服务商器具申请
    'pages/work/appliancesApply',  // 发起服务商器具申请
    'pages/work/chooseAppliances',  // 服务商选择器具
    'pages/work/providerHandle',  // 服务商我办理的活动
    'pages/work/providerHandlePeople',  // 服务商我办理的人员
    'pages/work/providerHandlePeopleDetail',  // 服务商我办理的人员详情
    'pages/workFlow/apply/workflowApplyMobile',  // 流程
  ]

  if (to.url === 'pages/user/questionnaire' && to.query.type === 'ALL') next()

  if (!whiteList.includes(to.url) && !store.getters.token) {
    uni.showModal({
      content: '您还未登录哦，快去登录吧！',
      confirmText: "立即登录",
      cancelText: '暂不登录',
      confirmColor: '#2EAE01',
      success: function(res) {
        res.confirm && Vue.prototype.$u.route({ url: '/pages/login/wxLogin' })
      }
    })
  } else {
    next()
  }
});

uniCrazyRouter.afterEach((to, from) => {

});

uniCrazyRouter["on" + "Error"]((to, from) => {
  // 逻辑代码
});
