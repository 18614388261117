var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tagType.length === 1 && _vm.tagType[0] === 'text_sign')?_c('div',[(!_vm.readonly)?_c('div',{class:[_vm.align === 'center' ? 'label-input-table' : 'label-input-flex']},[_vm._l((_vm.copyItems),function(item,index){return [_c('div',{staticStyle:{"line-height":"40px","width":"inherit","padding-left":"15px","box-sizing":"border-box","padding-right":"15px"},style:(_vm.getItemStyle(item))},[_vm._v(_vm._s(item.text)),(item.sign && item.type !== 'text_sign')?_c('img',{style:({
            width:
              item.width === '100%' || item.width > _vm.screenWidth
                ? '100%'
                : item.width + 'px',
          }),attrs:{"src":item.sign,"alt":""}}):_vm._e(),(_vm.signAlign === 'right')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e()]:_vm._e(),(_vm.signAlign === 'left')?[(item.type === 'text_sign')?_c('span',[_vm._v(_vm._s(item.sign))]):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2),_c('div',{staticStyle:{"width":"100%","padding-left":"15px","padding-right":"15px"}},[(_vm.signAlign === 'rightBottom')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e()]:_vm._e(),(_vm.signAlign === 'leftBottom')?[(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.sign))]):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px","float":"left"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2),(item.sign && item.userId === _vm.currentTokenUser.user.userId)?_c('div',{staticClass:"sz-sign-editor-btn"},[(item.type === 'text_sign')?_c('span',{staticStyle:{"color":"blue"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.editCurSign(item, index)
}}},[_vm._v("编辑")]):_vm._e(),_c('span',{staticStyle:{"color":"red","margin-left":"10px"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.removeCurSign(index)
}}},[_vm._v("删除")])]):_vm._e()]}),_c('div',{staticStyle:{"width":"inherit"}},[_c('el-input',{class:[
          _vm.mySignItem.align === 'center' ? 'input_center' : 'input_left' ],style:(_vm.getItemStyle(_vm.mySignItem)),attrs:{"placeholder":_vm.placeholder,"type":_vm.type,"readonly":_vm.readonly,"id":("el_input_" + _vm.uuid),"validate-event":_vm.required},model:{value:(_vm.mySignItem.text),callback:function ($$v) {_vm.$set(_vm.mySignItem, "text", $$v)},expression:"mySignItem.text"}},[_c('template',{slot:"prepend"},[_vm._v(_vm._s(_vm.acceptText))])],2),_c('div',{staticStyle:{"line-height":"35px","text-align":"right","padding-right":"20px"}},[_c('span',[_vm._v(_vm._s(_vm.mySignItem.sign))]),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.mySignItem.date))]):_vm._e()])],1),_c('div',{staticStyle:{"text-align":"center","display":"table","width":"inherit"}},[_c('el-radio-group',{on:{"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.changeType).apply(void 0, arguments)
}},model:{value:(_vm.selectType),callback:function ($$v) {_vm.selectType=$$v},expression:"selectType"}},[(_vm.showSignButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"sign"}},[_vm._v("签名")]):_vm._e(),(_vm.showAcceptButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"accept"}},[_vm._v("同意")]):_vm._e(),(_vm.showRejectButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"reject"}},[_vm._v("不同意")]):_vm._e(),(_vm.showCancelButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"cancel"}},[_vm._v("取消")]):_vm._e()],1)],1),(_vm.itemEditable)?_c('div',{staticClass:"sz-sign-new-sign"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"切换编辑/新增","placement":"top"}},[_c('el-button',{attrs:{"icon":"el-icon-refresh"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.newSign('text_sign')
}}})],1)],1):_vm._e()],2):_c('div',{class:[_vm.align === 'center' ? 'label-input-table' : 'label-input-flex']},[_vm._l((_vm.defaultValue),function(item,index){return [_c('div',{staticStyle:{"line-height":"40px","width":"inherit","padding-left":"15px","box-sizing":"border-box","padding-right":"15px"},style:(_vm.getItemStyle(item))},[_vm._v(_vm._s(item.text)),(item.sign && item.type !== 'text_sign')?_c('img',{style:({
            width:
              item.width === '100%' || item.width > _vm.screenWidth
                ? '100%'
                : item.width + 'px',
          }),attrs:{"src":item.sign,"alt":""}}):_vm._e(),(_vm.signAlign === 'right')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e()]:_vm._e(),(_vm.signAlign === 'left')?[(item.type === 'text_sign')?_c('span',[_vm._v(_vm._s(item.sign))]):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2),_c('div',{staticStyle:{"width":"100%","padding-left":"15px","padding-right":"15px"}},[(_vm.signAlign === 'rightBottom')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e()]:_vm._e(),(_vm.signAlign === 'leftBottom')?[(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.sign))]):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px","float":"left"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2)]})],2)]):_c('div',[(!_vm.readonly)?_c('div',{class:[_vm.align === 'center' ? 'label-input-table' : 'label-input-flex']},[_vm._l((_vm.copyItems),function(item,index){return [_c('div',{staticStyle:{"line-height":"40px","width":"inherit","box-sizing":"border-box","padding":"0px 15px 15px 15px"},style:(_vm.getItemStyle(item))},[_vm._v(_vm._s(item.text)),(
            item.sign &&
              item.type !== 'text_sign' &&
              item.userId !== _vm.currentTokenUser.user.userId
          )?_c('img',{style:({
            width:
              item.width === '100%' || item.width > _vm.screenWidth
                ? '100%'
                : item.width + 'px',
          }),attrs:{"src":item.sign,"alt":""}}):_vm._e(),(
            item.sign &&
              item.type !== 'text_sign' &&
              item.userId === _vm.currentTokenUser.user.userId
          )?_c('rate-img',{staticClass:"cut_sign_area",attrs:{"src-url":item.sign,"v-z":0,"is-draggable":false,"v-height":item.height,"v-width":item.width,"is-active":false},on:{"getRect":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.getRect).apply(void 0, arguments)
}}}):_vm._e(),(_vm.signAlign === 'right')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e()]:_vm._e(),(_vm.signAlign === 'left')?[(item.type === 'text_sign')?_c('span',[_vm._v(_vm._s(item.sign))]):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2),_c('div',{staticStyle:{"width":"100%","padding-left":"15px","padding-right":"15px"}},[(_vm.signAlign === 'rightBottom')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e()]:_vm._e(),(_vm.signAlign === 'leftBottom')?[(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.sign))]):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px","float":"left"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2),(item.sign && item.userId === _vm.currentTokenUser.user.userId)?_c('div',{staticClass:"sz-sign-editor-btn"},[_c('span',{staticStyle:{"color":"blue"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.editCurSign(item, index)
}}},[_vm._v("编辑")]),_c('span',{staticStyle:{"color":"red","margin-left":"10px"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.removeCurSign(index)
}}},[_vm._v("删除")])]):_vm._e()]}),(_vm.mySignItem.sign === '' && !_vm.isHaveCurrentUser)?_c('div',{staticClass:"sign-area",attrs:{"id":("sign_area_" + _vm.uuid)},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.showBtnGroup).apply(void 0, arguments)
}}},[_vm._v("点击签名")]):_vm._e(),_c('sz-dialog',{attrs:{"visible":_vm.dragVisible},on:{"cancelClick":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.dragVisible = false
}}},[_c('div',{staticClass:"content_header"},[_vm._v("请选择签名类型")]),_c('div',{staticStyle:{"display":"flex","background-color":"white","flex-flow":"row wrap","align-content":"flex-start"}},[_vm._l((_vm.tagType),function(item){return [(item === 'text_sign')?_c('div',{staticClass:"content_mater_item",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.switchGo(item)
}}},[_c('el-avatar',{staticStyle:{"background-color":"#2eae01"}},[_c('img',{attrs:{"slot":"default","src":require("../../assets/text.png")},slot:"default"})]),_c('div',{staticClass:"sz-sign-text"},[_vm._v("文本签名")])],1):_vm._e(),(item === 'text_sign')?_c('div',{staticClass:"content_mater_item",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.confirmTextSign(item)
}}},[_c('el-avatar',{staticStyle:{"background-color":"#2eae01"}},[_c('img',{attrs:{"slot":"default","src":require("../../assets/sign.png")},slot:"default"})]),_c('div',{staticClass:"sz-sign-text"},[_vm._v("签名")])],1):_vm._e(),(item === 'photo')?_c('div',{staticClass:"content_mater_item",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.goToPhotoSign(item)
}}},[_c('el-avatar',{staticStyle:{"background-color":"#2eae01"}},[_c('img',{attrs:{"slot":"default","src":require("../../assets/photo_sign.png")},slot:"default"})]),_c('div',{staticClass:"sz-sign-text"},[_vm._v("拍照签名")])],1):_vm._e(),(item === 'hand_sign')?_c('div',{staticClass:"content_mater_item",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.goToHandSign(item)
}}},[_c('el-avatar',{staticStyle:{"background-color":"#007aff"}},[_c('img',{attrs:{"slot":"default","src":require("../../assets/hand_sign.png")},slot:"default"})]),_c('div',{staticClass:"sz-sign-text"},[_vm._v("手写签名")])],1):_vm._e(),(item === 'template')?_c('div',{staticClass:"content_mater_item",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.goToTemplateSign(item)
}}},[_c('el-avatar',{staticStyle:{"background-color":"#ff8700"}},[_c('img',{attrs:{"slot":"default","src":require("../../assets/template_sign.png")},slot:"default"})]),_c('div',{staticClass:"sz-sign-text"},[_vm._v("模板签名")])],1):_vm._e(),(item === 'text_and_sign')?_c('div',{staticClass:"content_mater_item",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.goToTextHandSign(item)
}}},[_c('el-avatar',{staticStyle:{"background-color":"#fa4441"}},[_c('img',{attrs:{"slot":"default","src":require("../../assets/text_sign.png")},slot:"default"})]),_c('div',{staticClass:"sz-sign-text"},[_vm._v("文本+签名")])],1):_vm._e()]})],2)]),_c('sz-dialog',{attrs:{"visible":_vm.handSignVisible,"show-cancel":false},on:{"cancelClick":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.handSignVisible = false
}}},[_c('hand-sign',{on:{"closeHandSign":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.handSignVisible = false
},"getSign":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.getHandSign).apply(void 0, arguments)
},"close":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.closeHandSignVisible).apply(void 0, arguments)
}},model:{value:(_vm.mySignItem),callback:function ($$v) {_vm.mySignItem=$$v},expression:"mySignItem"}})],1),_c('sz-dialog',{staticClass:"sz-sign-textAndSign",attrs:{"visible":_vm.textVisible,"show-cancel":false},on:{"cancelClick":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.textVisible = false
}}},[_c('div',{staticClass:"content_header"},[_vm._v("文本签名")]),_c('div',{staticStyle:{"margin":"10px"}},[_c('div',{staticClass:"sz-sign-input"},[_c('div',{staticStyle:{"width":"inherit","margin-top":"5px"}},[_c('el-input',{class:[
                _vm.mySignItem.align === 'center' ? 'input_center' : 'input_left' ],style:(_vm.getItemStyle(_vm.mySignItem)),attrs:{"placeholder":_vm.placeholder,"type":_vm.type,"readonly":_vm.readonly,"id":("el_input_" + _vm.uuid),"validate-event":_vm.required},model:{value:(_vm.mySignItem.text),callback:function ($$v) {_vm.$set(_vm.mySignItem, "text", $$v)},expression:"mySignItem.text"}},[_c('template',{slot:"prepend"},[_vm._v(_vm._s(_vm.acceptText))])],2),_c('div',{staticStyle:{"line-height":"35px","text-align":"right","margin":"0px 15px","color":"#999"}},[(_vm.mySignItem.type === 'text_sign')?_c('span',[_vm._v(_vm._s(_vm.mySignItem.sign))]):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.mySignItem.date))]):_vm._e()])],1),_c('div',{staticStyle:{"text-align":"center","width":"inherit"}},[_c('el-radio-group',{on:{"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.changeType).apply(void 0, arguments)
}},model:{value:(_vm.selectType),callback:function ($$v) {_vm.selectType=$$v},expression:"selectType"}},[(_vm.showSignButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"sign"}},[_vm._v("签名")]):_vm._e(),(_vm.showAcceptButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"accept"}},[_vm._v("同意")]):_vm._e(),(_vm.showRejectButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"reject"}},[_vm._v("不同意")]):_vm._e(),(_vm.showCancelButton && !_vm.readonly)?_c('el-radio',{attrs:{"label":"cancel"}},[_vm._v("取消")]):_vm._e()],1)],1)])]),_c('div',{staticClass:"sz-dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{staticClass:"cancel_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.cancelText).apply(void 0, arguments)
}}},[_vm._v("取消")]),_c('span',{staticClass:"confirm_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.saveText).apply(void 0, arguments)
}}},[_vm._v("确定")])])]),_c('sz-dialog',{staticClass:"sz-sign-textAndSign",attrs:{"visible":_vm.textSignVisible,"show-cancel":false},on:{"cancelClick":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.textSignVisible = false
}}},[_c('div',{staticClass:"content_header"},[_vm._v("文本+手写签名")]),_c('div',{staticClass:"text-sign-area"},[_c('div',[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入文本","rows":4},model:{value:(_vm.mySignItem.text),callback:function ($$v) {_vm.$set(_vm.mySignItem, "text", $$v)},expression:"mySignItem.text"}})],1),_c('div',{staticClass:"sign-name-area",staticStyle:{"position":"relative"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.goToHandSign('text_and_sign')
}}},[(_vm.textSign)?[(_vm.textSign)?_c('img',{style:({
                height: '300rpx',
                width: '100%',
              }),attrs:{"src":_vm.textSign,"alt":""}}):_vm._e(),_c('i',{staticClass:"fa fa-check-circle",staticStyle:{"position":"absolute","color":"red","top":"2px","left":"2px"}})]:_c('span',[_vm._v("点我签名")])],2),_c('div',{staticStyle:{"height":"200px","overflow-y":"scroll","overflow-x":"hidden"}},_vm._l((_vm.electronicList),function(item,index){return _c('div',{key:index,staticClass:"sign-name-area-template",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.selectElectronicSign(item)
}}},[(item.sign)?_c('img',{style:({
                height: item.height + 'px',
                width: item.width + 'px',
              }),attrs:{"src":item.sign,"alt":""}}):_vm._e()])}),0)]),_c('div',{staticClass:"sz-dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{staticClass:"cancel_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.textSignVisible = false;
            _vm.mySignItem.text = '';
}}},[_vm._v("取消")]),_c('span',{staticClass:"confirm_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.saveTextSign).apply(void 0, arguments)
}}},[_vm._v("确定")])])]),_c('sz-dialog',{staticClass:"sz-sign-textAndSign",attrs:{"visible":_vm.templateSignVisible,"show-cancel":false},on:{"cancelClick":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.templateSignVisible = false
}}},[_c('div',{staticClass:"content_header"},[_vm._v("模板签名")]),_c('div',{staticClass:"text-sign-area"},[_c('div',[_c('el-carousel',{attrs:{"arrow":"click","trigger":"click","autoplay":false,"type":"card","height":"200px"},on:{"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.selectTemplate).apply(void 0, arguments)
}}},_vm._l((_vm.templateList),function(item,index){return _c('el-carousel-item',{key:index},[(item.sign)?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.sign,"alt":""}}):_vm._e()])}),1)],1)]),_c('div',{staticClass:"sz-dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{staticClass:"cancel_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.templateSignVisible = false
}}},[_vm._v("取消")]),_c('span',{staticClass:"confirm_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.saveSignTemplate).apply(void 0, arguments)
}}},[_vm._v("确定")])])]),_c('sz-dialog',{staticClass:"sz-sign-textAndSign",attrs:{"visible":_vm.cutImgVisible,"show-cancel":false},on:{"cancelClick":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.cutImgVisible = false
}}},[_c('div',{staticClass:"content_header"},[_vm._v("模板签名")]),_c('cut-img',{attrs:{"img-url":_vm.waitCurImgUrl}}),_c('div',{staticClass:"sz-dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{staticClass:"cancel_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.cutImgVisible = false
}}},[_vm._v("取消")]),_c('span',{staticClass:"confirm_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.saveSignTemplate).apply(void 0, arguments)
}}},[_vm._v("确定")])])],1)],2):_c('div',{class:[_vm.align === 'center' ? 'label-input-table' : 'label-input-flex']},[_vm._l((_vm.defaultValue),function(item,index){return [_c('div',{staticStyle:{"line-height":"40px","width":"inherit","padding-left":"15px","box-sizing":"border-box","padding-right":"15px"},style:(_vm.getItemStyle(item))},[_vm._v(_vm._s(item.text)),(_vm.signAlign === 'right')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e(),(item.sign && item.type !== 'text_sign')?_c('img',{staticStyle:{"float":"right"},style:({
              width:
                item.width === '100%' || item.width > _vm.screenWidth
                  ? '100%'
                  : item.width + 'px',
            }),attrs:{"src":item.sign,"alt":""}}):_vm._e()]:_vm._e(),(_vm.signAlign === 'left')?[(item.type === 'text_sign')?_c('span',[_vm._v(_vm._s(item.sign))]):_vm._e(),(item.sign && item.type !== 'text_sign')?_c('img',{style:({
              width:
                item.width === '100%' || item.width > _vm.screenWidth
                  ? '100%'
                  : item.width + 'px',
            }),attrs:{"src":item.sign,"alt":""}}):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2),_c('div',{staticStyle:{"width":"100%","padding-left":"15px","padding-right":"15px"}},[(_vm.signAlign === 'rightBottom')?[(_vm.addDate)?_c('span',{staticStyle:{"float":"right","margin-left":"10px","line-height":"60px"}},[_vm._v(_vm._s(item.date))]):_vm._e(),(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.sign))]):_vm._e(),(item.sign && item.type !== 'text_sign')?_c('img',{staticStyle:{"float":"right"},style:({
              width:
                item.width === '100%' || item.width > _vm.screenWidth
                  ? '100%'
                  : item.width + 'px',
            }),attrs:{"src":item.sign,"alt":""}}):_vm._e()]:_vm._e(),(_vm.signAlign === 'leftBottom')?[(item.type === 'text_sign')?_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.sign))]):_vm._e(),(item.sign && item.type !== 'text_sign')?_c('img',{staticStyle:{"float":"left"},style:({
              width:
                item.width === '100%' || item.width > _vm.screenWidth
                  ? '100%'
                  : item.width + 'px',
            }),attrs:{"src":item.sign,"alt":""}}):_vm._e(),(_vm.addDate)?_c('span',{staticStyle:{"margin-left":"10px","float":"left","line-height":"60px"}},[_vm._v(_vm._s(item.date))]):_vm._e()]:_vm._e()],2)]})],2)])}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }