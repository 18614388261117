/**
 * 工作流接口
 */
export default class WorkFlowApi {
  constructor(vm) {
    this.vm = vm
  }

  // 查询我发起的工作流实例列表（分页）
  getMineApplicationList(params = {}) {
    return this.vm.$u.get('/api/workflow/task/instance/launch', params);
  }

  // 查询工作流任务列表（分页）
  getApplicationList(params = {}) {
    return this.vm.$u.get('/api/workflow/task', params, { role: 'ADMIN' });
  }

  // 查询上传组件配置
  getModuleSettingByKey(params = {}) {
    return this.vm.$u.get(`/api/module/setting/${params.key}/info`);
  }

  // 新增或修改签名
  addOrUpdateSignature(params = {}) {
    params.userId = this.vm.$store.getters.adminUser.userId
    return this.vm.$u.post('/api/workflow/sign/template', params, { role: 'ADMIN' });
  }

  // 查询我的签名模板信息列表
  getMineSignatureList(params = {}) {
    return this.vm.$u.get('/api/workflow/sign/template/list', params, { role: 'ADMIN' });
  }

  // 根据id删除签名模板信息
  deleteSignatureById({ id }) {
    return this.vm.$u.post(`/api/workflow/sign/template/${id}`, {}, { role: 'ADMIN' });
  }
  // 查询下拉框数据
  getSelectData({path}){
    return this.vm.$u.get(path);
  }
}
