// 课程签到
export default class CourseApi {
    constructor(vm) {
      this.vm = vm
    }

    // 查询签到的课程列表（分页）
    getNeedSignRecoveryCoursesPageList(params = {}) {
        return this.vm.$u.get("api/recovery/course/sign/list", params);
      }
    //   课程签到  courseId signInfo
    signRecoveryCourse({courseId,signInfo}) {
        return this.vm.$u.post(`/api/recovery/sign/record/sign/${courseId}`,signInfo);
    }
    // 根据id查询教师f
    getRecoveryTeacherById({id}){
        return this.vm.$u.get(`/api/recovery/teacher/${id}`);
    }
    // 据课程id查询康复课程确认详情
    getRecoveryCourseByApplyUserIdAndId({courseId}){
        return this.vm.$u.get(`/api/recovery/sign/record/${courseId}`);
    }
}