var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showSupervise)?_c('el-dialog',{staticClass:"child_matter_dialog",attrs:{"title":"提交反馈","append-to-body":true,"width":"100%","visible":_vm.showSupervise,"close-on-click-modal":false,"close-on-press-escape":false,"destroy-on-close":true},on:{"update:visible":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.showSupervise=$event
}}},[_c('div',{staticClass:"content"},[_vm._t("default"),_vm._t("footer",[(_vm.showCancel)?_c('div',{staticClass:"cancel_btn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.cancelClick).apply(void 0, arguments)
}}},[_vm._v("取消")]):_vm._e()])],2)]):_vm._e()}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }