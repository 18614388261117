<template>
  <div
    :class="[align === 'center' ? 'label-input-table' : 'label-input-flex']"
    style="padding-left: 20px;padding-right: 20px;"
  >
    <template v-for="(item, index) in items">
      <span
        v-if="item.type === 'label'"
        style="line-height: 40px;"
        :style="getItemStyle(item)"
        >{{ item.text }}</span
      >
      <el-input
        :placeholder="item.placeholder"
        v-if="item.type === 'input'"
        v-model="formInputData[item.__vModel__]"
        :readonly="item.readonly"
        :validate-event="item.required"
        :disabled="item.disabled"
        :class="[item.align === 'center' ? 'input_center' : 'input_left']"
        :style="getItemStyle(item)"
      >
      </el-input>
      <el-date-picker
        :placeholder="item.placeholder"
        v-if="item.type === 'date' || item.type === 'datetime'"
        :type="item.type"
        :value-format="item.format"
        :format="item.format"
        :readonly="item.readonly"
        :disabled="item.disabled"
        :validate-event="item.required"
        v-model="formInputData[item.__vModel__]"
        :class="[item.align === 'center' ? 'input_center' : 'input_left']"
        :style="getItemStyle(item)"
      >
      </el-date-picker>
    </template>
  </div>
</template>

<script>
import { deepClone } from "./utils/index";
import render from "./render/render.js";
import { mapGetters } from "vuex";
export default {
  name: "label-input",
  props: {
    align: {
      type: String,
      default: "center"
    },
    items: {
      type: Array,
      default: []
    },
    // 不允许使用formInputData
    formModel: {
      type: String,
      default: "formModel1"
    }
  },
  data() {
    return {
      copyItems: deepClone(this.items),
      [this.formModel]: {},
      formInputData: {}
    };
  },
  computed: {
    ...mapGetters({
      mergeFormData: "mergeFormData"
    })
  },
  created() {
    this.initFormData();
  },
  watch: {
    formInputData: {
      handler(val) {
        if (val) {
          this[this.formModel] = this.formInputData;
          console.log(this.formModel, this[this.formModel]);
          this.$emit("getLabelInput", this.formInputData);
          this.$store.dispatch("formDesign/mergeFormDataAction", this.formInputData);
        }
      },
      deep: true
    }
  },
  methods: {
    // 初始化formData
    initFormData() {
      if (this.copyItems) {
        this.copyItems.forEach(cur => {
          if (cur.type === "input") {
            const vModel = cur.__vModel__;
            if (vModel) {
              this.$set(
                this.formInputData,
                vModel,
                cur.defaultValue === undefined ? "" : cur.defaultValue
              );
            }
          } else if (cur.type === "date" || cur.type === "datetime") {
            const vModel = cur.__vModel__;
            if (vModel) {
              this.$set(
                this.formInputData,
                vModel,
                cur.defaultValue === undefined ? "" : cur.defaultValue
              );
            }
          }
        });
      }
    },
    getItemStyle(item) {
      let style = "";
      if (item.width) {
        style +=
          "width:" +
          (item.width.endsWith("px") ? item.width : item.width + "px") +
          ";";
      }
      if (item.color) {
        style += "color:" + item.color + ";";
      }
      if (item.align) {
        style += "text-align:" + item.align + ";";
      }
      return style;
    }
  }
};
</script>

<style lang="less" scoped scoped>
.label-input-flex {
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
  justify-context: centet;
  align-items: center;
}
.label-input-table {
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: table;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
  justify-context: centet;
  align-items: center;
}
/deep/ .input_center {
  .el-input__inner {
    text-align: center !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}
/deep/ .input_left {
  .el-input__inner {
    text-align: left !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
