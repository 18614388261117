import Parser from "./form-designer/parser/Parser";
import labelInput from "./form-designer/label-input";
import tagInput from "./form-designer/tag-input";
import szUpload from "./form-designer/sz-upload";
import szDatePicker from "./form-designer/sz-date-picker";
import quickReply from "./form-designer/quick-reply";
import szSelect from "./form-designer/sz-select";
import szMobilSelect from "./form-designer/sz-mobile-select";
import szDateTime from "./dateTime/dDateTime";
import szUserSelect from "./form-designer/sz-user-select";
import cityRuralSelect from "./form-designer/city-rural-select";
import treeSelectItem from "./form-designer/treeSelectItem.vue";
import szDialog from "./form-designer/sz-dialog";
import rateImg from "./form-designer/rateImg"
import handSign from "./form-designer/handSign"
import szEnsign from "./form-designer/sz-ensign"

const plugin = {
  install: function(Vue) {
    Vue.component("city-rural-select", cityRuralSelect);
    Vue.component("tree-select-item", treeSelectItem);
    Vue.component("parser", Parser);
    Vue.component("label-input", labelInput);
    Vue.component("tag-input", tagInput);
    Vue.component("sz-tag-input", tagInput);
    Vue.component("sz-upload", szUpload);
    Vue.component("sz-quick-reply", quickReply);
    Vue.component("sz-user-select", szUserSelect);
    Vue.component("sz-select", szSelect);
    Vue.component("sz-mobile-select", szMobilSelect);
    Vue.component("sz-date-picker", szDatePicker);
    Vue.component("sz-date-time", szDateTime);
    Vue.component("sz-dialog", szDialog);
    Vue.component("rate-img", rateImg);
    Vue.component("hand-sign", handSign);
    Vue.component("sz-ensign", szEnsign);
  },
};

export default plugin;
