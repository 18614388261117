<template>
  <el-dialog
    title="提交反馈"
    append-to-body
    width="100%"
    class="child_matter_dialog"
    :visible.sync="showSupervise"
    v-if="showSupervise"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    destroy-on-close
  >
    <div class="content">
      <slot></slot>
      <slot name="footer">
        <div class="cancel_btn" @click="cancelClick" v-if="showCancel">
          取消
        </div>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "sz-dialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    showCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showSupervise: false
    }
  },
  watch: {
    visible(val) {
      this.showSupervise = val
    }
  },
  methods: {
    cancelClick() {
      this.showSupervise = false
      this.$emit('cancelClick', false)
    }
  }
}
</script>

<style lang="scss" scoped scoped>
  .child_matter_dialog {
    margin: 0 20px;

    ::v-deep .el-dialog__header {
      display: none;
    }

    ::v-deep .el-dialog__body {
      padding: 0;
    }

    .content {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

      .cancel_btn {
        background-color: white;
        line-height: 60px;
        text-align: center;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        border-top: 1px solid #F5F6FA;
        color: #FA4441;
        opacity: 1;
      }

      .content_header {
        background-color: white;
        line-height: 60px;
        text-align: center;
        border-bottom: 1px solid #cecece;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #303030;
        opacity: 1;
      }
    }
  }
</style>
