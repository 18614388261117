<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
import { getUserInfo } from "@/common/user.js";
export default {
  onLaunch(option) {
    getUserInfo();
    if (option.scene) {
      switch (option.scene) {
        //扫描小程序码
        case 1047:
          console.log("扫描小程序码");
          break;
        //长按图片识别小程序码
        case 1048:
          console.log("长按图片识别小程序码");
          break;
        //手机相册选取小程序码
        case 1049:
          console.log("手机相册选取小程序码");
          break;
        //直接进入小程序
        case 1001:
          console.log("直接进入小程序");
          break;
      }
    }
    if (this.$store.getters.user.userId) {
      this.$u.api.MessageApi.getUnreadMessagesNumber().then(() => {
        this.$util.setBadgeNumber();
      });
    }
  },
  onShow: function () {
    console.log("App Show");
  },
  onHide: function () {
    console.log("App Hide");
  },
};
</script>
<style lang="less">
@import "style/flex-layout.less";
@import "style/common.css";
</style>

<style lang="scss">
@import "uview-ui/index.scss";

page {
  background: #f6f6f7;
}

//解决苹果 u-search 兼容问题
input[type="search"] {
  -webkit-appearance: none !important;
}
[type="search"]::-webkit-search-decoration {
  display: none !important;
}
input::-webkit-search-cancel-button {
  display: none !important;
}
</style>