// 仅OA工作流使用
import Vue from 'vue';
import {
  Pagination,
  Dialog,
  Avatar,
  Drawer,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup, //!
  Table,
  DatePicker,//!
  TimePicker,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane, //!
  Tree,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Badge,
  Card,
  Rate,
  Carousel,
  CarouselItem,
  Cascader,
  ColorPicker,
  Container,
  Header,
  Aside,
  Main,
  Timeline,
  TimelineItem, //!
  Divider,
  Image,
  Loading,
  MessageBox,
  Message,
  Notification
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Avatar);
Vue.use(Drawer);
// Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
// Vue.use(TableColumn);
Vue.use(DatePicker);
// Vue.use(TimeSelect);
Vue.use(TimePicker);
// Vue.use(Popover);
Vue.use(Tooltip);
// Vue.use(Breadcrumb);
// Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
// Vue.use(Tag);
Vue.use(Tree);
// Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
// Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
// Vue.use(Steps);
// Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
// Vue.use(Collapse);
// Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
// Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
// Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
// Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
// Vue.use(Calendar);
// Vue.use(Backtop);
// Vue.use(PageHeader);
// Vue.use(CascaderPanel);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
// 判断是否空对象
Vue.prototype.isEmptyObj = function(obj) {
  if (Object.getOwnPropertySymbols(obj).length) {
    return false;
  }
  if (Object.getOwnPropertyNames(obj).length) {
    return false;
  }
  return true;
};

// 判断是否数组对象
Vue.prototype.isArray = function(obj) {
  return obj instanceof Array;
};

// 判断是否Date对象
Vue.prototype.isDate = function(obj) {
  return obj instanceof Date;
};

// 判断变量是否定义
Vue.prototype.isSet = function(obj) {
  return typeof obj !== "undefined";
};

// 提示信息
Vue.prototype.error = function(message) {
  this.$mResToast.error(message);
};

// 提示信息
Vue.prototype.warning = function(message) {
  this.$mResToast.warning(message);
};
// 右上角提示信息
Vue.prototype.warningNotify = function(title, message) {
  Notification({
    message: message,
    title: title,
    type: "warning",
    duration: 3000
  });
};

// 提示信息
Vue.prototype.success = function(message) {
  this.$mResToast.success(message);  // 成功提示
};
// 提示信息
Vue.prototype.info = function(message) {
  this.$mResToast.info(message);
};




//  获取下载文件流的文件名称
Vue.prototype.getFileName = function(disposition) {
  var fileName = "";
  if (disposition) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }
  return decodeURIComponent(fileName);
};



// 打开新页面
Vue.prototype.openPage = function(url, key) {
  let strWindowFeatures =
    "width=" +
    (window.screen.availWidth - 10) +
    ",height=" +
    (window.screen.availHeight - 30) +
    ",menubar=yes,location=no,resizable=yes,scrollbars=true,status=true";
  var winObj = window.open(url, "B_page", strWindowFeatures);
  window.localStorage.setItem(key, "");
  var loop = setInterval(function() {
    if (winObj.closed) {
      clearInterval(loop);
      window.localStorage.setItem(key, "refresh");
    }
  }, 1);
};

// 打开新Tab页面
Vue.prototype.openTabPage = function(url) {
  window.open(url, "_blank");
};

// 图片全屏展示
Vue.prototype.showImg = function(url) {
};
// 设备类型
Vue.prototype.deviceTypeF = function(url) {
  var u = navigator.userAgent;
  if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //安卓手机
    return "Android";

  } else if (u.indexOf("iPhone") > -1) {
    //苹果手机
    return "IOS";
  } else if (u.indexOf("Windows Phone") > -1) {
    //winphone手机
    return "WinPhone";
  } else if (/ipad/i.test(u.toLowerCase())) {
    return true;
  }
};




// 获取传递给查询接口的字段
Vue.prototype.setSearchFormApiData = function(
  searchFormData,
  tablePageData
) {
  const data = this.filterSearchFormData(searchFormData);
  data.pageNum = tablePageData.pageNum;
  data.pageSize = tablePageData.pageSize;
  return data;
};

// 过滤对接接口的参数，不传空值
Vue.prototype.filterSearchFormData = function(searchFormData) {
  const data = {};
  Object.keys(searchFormData).forEach(key => {
    if (
      searchFormData[key] !== "" &&
      JSON.stringify(searchFormData[key]) !== "[]"
    ) {
      data[key] = searchFormData[key];
    }
  });
  return data;
};

// 获取传递给信息编辑接口的字段，过滤掉没有变化的字段 默认带上idKey字段 没有变化则返回 false
Vue.prototype.getUpdateFormData = function(formData, idKey) {
  if (!this._formData) return formData;
  if (this._formData === JSON.stringify(formData)) {
    this.warning("未修改任何内容，不需要提交");
    return false;
  }
  idKey = idKey || "id";
  if (!formData[idKey]) {
    this.warning("无效的id-key");
    return false;
  }
  const data = {};
  const oldData = JSON.parse(this._formData);
  Object.keys(formData).forEach(key => {
    if (
      typeof formData[key] === "undefined" ||
      JSON.stringify(formData[key]) !== JSON.stringify(oldData[key])
    ) {
      data[key] = formData[key];
    }
  });
  data[idKey] = formData[idKey];
  return data;
};

// 检查api返回状态，异常则报错
Vue.prototype.checkApiSuccess = function(res) {
  if (res.errorCode === "0") {
    return true;
  } else {
    this.error(res.errorMsg);
  }
  return false;
};

// 表单组件字段重置方法
Vue.prototype.resetForm = function(ref) {
  this.$refs[ref].$emit("resetForm");
};

// 播放提示音
Vue.prototype.playVideo = function(url) {
  var music = new Audio();
  music.src = audio; //这里替换成一个有效的音频文件地址以方便测试
  music.loop = false;
  music.play();
};

// 表单组件刷新表单方法 重置表单默认值
Vue.prototype.refreshForm = function(ref) {
  this.$refs[ref].$emit("refreshForm");
};
// 图片地址
Vue.prototype.fullImagePath = function(uri) {
  if (uri) {
    if (
      uri.indexOf("http") === 0 ||
      uri.indexOf("../") === 0 ||
      uri.indexOf("data:") === 0
    ) {
      return uri;
    } else {
      return window.api.imageDomain + uri;
    }
  } else {
    return "";
  }
};
// 表单组件字段校验方法
Vue.prototype.validateForm = function(ref, func) {
  return this.$refs[ref].$refs["form"].validate(func).catch(err => {
    return err;
  });
};
// 日期比较大小 a》b 返回true, 反false
Vue.prototype.compareDate = function(dateTime1, dateTime2) {
  var formatDate1 = new Date(dateTime1);
  var formatDate2 = new Date(dateTime2);
  if (formatDate1 > formatDate2) {
    return true;
  } else {
    return false;
  }
}
;
// 表单验证
Vue.prototype.validatorForm = function(data, rules) {
  let validator = new schema(rules);
  let formValid = true;
  validator.validate(data, (errors, fields) => {
    if (errors) {
      if (errors) {
        this.warningNotify("提示", errors[0].message);
      }
      formValid = false;
      return;
    } else {
      formValid = true;
    }
  });
  return formValid;
};

// 设置表单数据方法
Vue.prototype.setFormData = function(data, ref) {
  this.formData = JSON.parse(JSON.stringify(data));
  this._formData = JSON.stringify(data);

  if (ref) {
    setTimeout(() => {
      this.refreshForm(ref);
    }, 0);
  }
};

// 给表单提交使用的操作确认方法
Vue.prototype.confirmForm = function(param) {
  if (typeof param === "string") {
    param = { msg: param };
  }
  let { title, subTitle, msg, confirmText, cancelText, type } = param;

  title = title || "提示";
  subTitle = subTitle || "提示";
  msg = msg || "确认提交吗？";
  confirmText = confirmText || "确定";
  cancelText = cancelText || "取消";
  type = type || "warning";
  return szMessageBox({
    title: title,
    type: type,
    msg: msg,
    btn: {
      ok: confirmText,
      no: cancelText
    }
  }).then()
    .catch(err => {
      return err;
    });
};


// Toast
Vue.prototype.toast = function(param) {
  if (typeof param === "string") {
    param = { context: param };
  }
  let { icon, mask, context, duration, type } = param;

  icon = icon || "iconinfo";
  mask = mask || false;
  context = context || "操作成功";
  duration = duration || 3000;
  type = type || "warning";
  return szToastBox({
    type: type,
    context: context,
    icon: icon,
    mask: mask,
    duration: duration
  }).then()
    .catch(err => {
      return err;
    });
};

// 根据值集CODE获取值集选项列表
Vue.prototype.getDictionary = function(code) {
  if (!code) return;
  let DictionaryData = window.sessionStorage.getItem("allDic");
  let arr = JSON.parse(DictionaryData);
  if(arr){
    let obj = arr.find(v => v.keyword === code);
    if (obj) {
      return obj.children;
    }
  }
  return [];
};


/**
 * 描述：生成GUID
 * 时间： 2020/12/11
 */
Vue.prototype.GUID = function() {
  let tem = this.S4();
  console.log(tem);
  return (this.S4() + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4());
};


Vue.prototype.S4 = function() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};
// 根据值集pCode获取子集值集选项列表
Vue.prototype.getChildDictionary = function(code, childCode) {
  console.log(code, childCode);
  if (!childCode) return;
  if (!code) return;
  let DictionaryData = window.sessionStorage.getItem("allDic");
  let arr = JSON.parse(DictionaryData);
  let obj = arr.find(v => v.keyword === code);
  let tem = [];
  if (obj) {
    obj.children.map(item => {
      if (childCode) {
        if (item.keyword === childCode) {
          tem = item.children;
        }
      }
    });
  }
  return tem;
};
// // 根据值集pCode获取子集值集选项列表
// Vue.prototype.getChildDictionary = function (code, child) {
//   let result=[]
//   if (child && code) {
//     for (let i = 0; i < child.length; i++) {
//       let item = child[i]
//       console.log(item.value,item.children,99999999999)
//       if (item) {
//         if (code === item.value) {
//           console.log(777777777777,item.children)
//           result =item.children
//         } else {
//           if (item.children&&item.length>0) {
//             console.log(item.children,5555)
//             result =this.getChildDictionary(code, item.children)
//           }
//         }
//       }
//     }
//   }
//   return result
// }
// 根据值集值Value转换成名称显示
Vue.prototype.showDictionary = function(code, value) {
  let options = [];

  if (typeof code === "object") {
    code.forEach(c => {
      options = options.concat(this.getDictionary(c));
    });
  } else {
    options = this.getDictionary(code);
  }
  if (options && options.length) {
    let opt = options.find(v => v.code === value);
    if (opt) {
      return opt.name;
    }
    return "";
  }
  return "";
};

