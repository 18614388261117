<template>
  <el-upload
    :action="actionUrl"
    :accept="accept"
    :name="name"
    :disabled="disabled"
    :multiple="multiple"
    :auto-upload="autoUpload"
    :list-type="listType"
    :limit="limit"
    v-if="!readonly"
    :on-success="handleChange"
    :headers="headers"
    class="sz-common-upload"
    :on-preview="filePreview"
    :on-remove="removeFile"
    :style="{ height: getUploadHeight }"
    :file-list="fileList"
    :class="{ 'limit-fill': limit == fileList.length }"
  >
    <div class="sz-common-upload-header" slot="default" :style="{ top: getBtnTop }">
      <el-button
        v-if="!readonly"
        size="small"
        type="primary"
        class="sz-common-upload-btn"
        :icon="defaultConfig.icon"
      >上传</el-button>
    </div>
    <div class="el-upload-list__item is-success" slot="file" slot-scope="file">
      <template v-if="listType === 'picture-card'">
        <el-image style="width: 100%;height:100%;" :preview-src-list="[file.file.url]" :src="file.file.url"></el-image>
        <i class="el-icon-delete" style="position:absolute;top:0;right:0;color:red;font-size:36rpx;" @click="handleRemove(file.file)"></i>
      </template>
      <template v-else>
        <el-row>
          <el-col :span="16">
            <span class="el-upload-list__item-name">
              <i :class="getIcoClass(file.file)"></i>
              <span @click="filePreview(file.file)">{{ file.file.name }}</span>
            </span>
          </el-col>
          <el-col :span="5">
            <span class="el-upload-list__item-name">
              <i class="el-icon-delete" @click="handleRemove(file.file)"></i>
              <!--            <span class="download_btn1" @click="download(file.file)">下载</span>-->
            </span>
          </el-col>
        </el-row>
      </template>
    </div>
  </el-upload>
  <div v-else class="sz-common-upload-readonly">
    <ul class="el-upload-list el-upload-list--text" v-if="fileList.length > 0">
      <li
        v-for="item in fileList"
        tabindex="0"
        class="el-upload-list__item is-success"
        :style="{ backgroundColor: itemBg }"
      >
        <template v-if="listType == 'picture-card'">
          <el-image
            style="width: 200rpx; height: 200rpx; margin: 0 auto; display: block"
            :src="imageHead + item.url"
            :preview-src-list="[imageHead + item.url]"
            fit="cover"
          ></el-image>
        </template>
        <template v-else>
          <i :class="getIcoClass(item)" style="font-size: 14px; line-height: 29px"></i>
          <span class="el-upload-list__item-name" @click="filePreview(item)">{{
            item.name
          }}</span>
        </template>

        <!--        <span class="download_btn" @click="download(item)">下载</span>-->
      </li>
    </ul>
    <div v-else style="height: 40px"></div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "sz-upload",
  props: {
    // action: {
    //   type: String,
    //   default:
    //     'http://47.97.101.122:23888/api/sys/file/db/upload?path=workflow"'
    // },
    config: {
      type: Object,
      default: function() {
        return {};
      },
    },
    accept: {
      type: String,
      default: "",
    },
    // 只读属性
    readonly: {
      type: Boolean,
      default: false,
    },
    // 默认值
    value: {
      type: Array | String,
      default: function() {
        return [];
      },
    },
    name: {
      type: String,
      default: "file",
    },
    limit: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * 描述：显示水印
     * 时间： 2020/10/29
     */
    waterMark: {
      type: Boolean,
      default: false,
    },
    /**
     * 描述：水印内容
     * 时间： 2020/10/29
     */
    waterText: {
      type: String,
      default: "",
    },
    /**
     * 描述：是否加标签
     * 时间： 2020/11/7
     */
    isLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * 描述：标签内容
     * 时间： 2020/11/7
     */
    labelText: {
      type: String,
      default: "",
    },
    // 业务编码 null 使用页面属性的配置，否则根据key查询相应的配置
    businessKey: {
      type: String,
      default: "WORKFLOW",
    },
    /**
     * 描述：x坐标
     * 时间： 2020/11/7
     */
    labelX: {
      type: Number | String,
      default: 0,
    },
    /**
     * 描述：y坐标
     * 时间： 2020/11/7
     */
    labelY: {
      type: Number | String,
      default: 0,
    },
    "auto-upload": {
      type: Boolean,
      default: true,
    },
    // 文件列表item背景色
    itemBg: {
      type: String,
      default: "#F5F6FA",
    },
    "list-type": {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      defaultConfig: {
        buttonText: "",
        btnType: "primary",
        icon: "el-icon-plus",
        uploadTip: "只能上传jpg/png文件，且不超过500kb",
      },
      actionUrl: window.api.sysUploadUrl + "?path=workflow",
      headers: {
        token: this.$store.getters.token,
        userRole: "ALL",
      },
      fileList: [],
      temFileList: [],
      imageHead: window.api.imageDomain1 + "/",
    };
  },
  computed: {
    getUploadHeight() {
      return "";
      return this.temFileList.length > 0
        ? 66 * this.temFileList.length + 120 + "px"
        : this.fileList.length > 0
          ? 66 * this.fileList.length + 70 + "px"
          : "120px";
    },
    getBtnTop() {
      return "";
      return this.temFileList.length > 0 ? 66 * this.temFileList.length + "px" : "0px";
    },
  },
  watch: {},
  mounted() {
    Object.assign(this.defaultConfig, this.config);
    if (this.businessKey) {
      this.getSettingByKey();
    }
    if (this.value) {
      this.value.map((item) => {
        this.fileList.push({ name: item.fileName, url: item.id || item.value });
      });
      this.temFileList = this.fileList;
    }
  },
  methods: {
    // // 移除文件
    // handleRemove(file) {
    //   let index = this.fileList.findIndex((item, index, arr) => {
    //     return item.url == file.url;
    //   });
    //   this.fileList.splice(index, 1);
    // },
    getIcoClass(item) {
      if (!item.name) {
        return;
      }
      let type = item.name.split(".")[1];
      switch (type) {
        case "doc":
        case "docx":
          return "fa fa-file-word-o doc_blue";
        case "pdf":
          return "fa fa-file-pdf-o pdf_red";
        case "png":
        case "jpg":
          return "fa fa-file-image-o image_green";
        default:
          return "fa fa-file-text-o image_green";
      }
    },
    removeFile(file, fileList) {
      console.log("aaa");
      this.fileList = fileList;
      this.temFileList = fileList;
    },
    previewFile(item) {
      let url =
        window.api.preFile +
        "?url=" +
        window.api.secretFilePreview +
        item.url +
        "?token=" +
        this.$store.getters.token +
        "&showmenu=false";
      this.$router.push({ name: "preview", query: { url: url } });
    },
    /**
     * 描述：根据业务获取模块的上传类型和提示
     * 时间： 2021/3/22
     */
    getSettingByKey() {
      this.$u.api.WorkFlowApi.getModuleSettingByKey({ key: this.businessKey }).then(
        (res) => {
          this.acceptType = "";
          if (res.fileTypes) {
            this.actionUrl =
              window.api.sysUploadUrl + `?path=workflow&fileTypes=${res.fileTypes}`;
            let arr = res.fileTypes.split(",");
            let nextArr = arr.map((item) => {
              return "." + item;
            });
            this.acceptType = nextArr.join(",");
          }
          if (this.listType == "picture-card") {
            this.actionUrl = window.api.uploadUrl1 + "?path=workflow";
          }
          this.defaultConfig.uploadTip = res.description ? res.description : "";
        }
      );
    },
    // 移除文件
    handleRemove(file) {
      let index = this.fileList.findIndex((item, index, arr) => {
        return item.url == file.url;
      });
      this.fileList.splice(index, 1);
      let tem = [];
      if (this.fileList) {
        this.fileList.map((item) => {
          if (item.response) {
            tem.push(item.response);
          } else if (item.url) {
            tem.push({
              fileName: item.name,
              id: item.url,
            });
          }
        });
      }
      this.temFileList = tem;
      this.$emit("input", tem);
    },
    handleChange(response, file, fileList) {
      if (response.value) {
        const imgUrl = this.imageHead + response.value;
        this.fileList.push({
          name: "文件",
          url: imgUrl,
        });
      } else {
        this.fileList = fileList;
      }
      let tem = [];
      this.uploadList = [];
      if (fileList) {
        fileList.map((item) => {
          if (item.response) {
            tem.push(item.response);
            this.uploadList.push(item);
          } else if (item.url) {
            tem.push({
              fileName: item.name,
              id: item.url,
            });
            this.uploadList.push(item);
          }
        });
      }
      // console.log(response, file, fileList);
      this.$emit("input", tem);
      // const imgUrl = response.value?this.imageHead + response.value:response.id;
      // let tem = [];
      // this.fileList = [];
      // if (fileList) {
      //   fileList.map((item) => {
      //     if (item.response) {
      //       tem.push(item.response);
      //       this.fileList.push({
      //         name: item.response.fileName || '文件',
      //         url: imgUrl,
      //       });
      //     } else if (item.url) {
      //       tem.push({
      //         fileName: item.name,
      //         id: item.url,
      //       });
      //       this.fileList.push({ name: item.name, url: item.url });
      //     }
      //   });
      // }
      // this.temFileList = tem;
      // console.log(response, file, fileList,this.fileList);
      // this.$emit("input", tem);
    },
    // 下载
    download(item) {
      // let fileDownload = require("js-file-download");
      // let taskId = this.$route.query.taskId;
      //
      //   this.API.getLabelTextByTaskId({taskId:taskId,labelText:this.labelText}).then(res=> {
      //     let para = {
      //       id: item.url,
      //       waterMark: this.waterMark,
      //       waterText: this.waterText,
      //       isLabel: this.isLabel,
      //       labelText: res.data,
      //       labelX: this.labelX,
      //       labelY: this.labelY,
      //       taskId: taskId
      //     };
      //     console.log("下载地址：",this.ExportApi.getSpecialFilePath(para),item.name)
      //     // this.openTabPage(this.ExportApi.getSpecialFilePath(para))
      //     if (!this.isDDEnv()) {
      //       Android.downFile(this.ExportApi.getSpecialFilePath(para), item.name);
      //       // this.ExportApi.getSpecialFile(para).then(res => {
      //       //   const disposition = res.headers["content-disposition"];
      //       //   let fileName = this.getFileName(disposition);
      //       //   console.log("下载中", fileName);
      //       //   if (!this.isDDEnv()) {
      //           // Android.downFile(res.request.responseURL, fileName);
      //       //   } else {
      //       //     fileDownload(res.data, fileName);
      //       //   }
      //       // }).finally(() => {
      //
      //       // });
      //     } else {
      //       this.openTabPage(this.ExportApi.getSpecialFilePath(para))
      //     }
      //   })
    },

    // 文件预览 text,pdf,txt,其他格式下载
    filePreview(file) {
      let url = "";
      if (file.url) {
        url =
          window.api.preFile +
          "?url=" +
          window.api.secretFilePreview +
          file.url +
          "?token=" +
          this.$store.getters.token +
          "&showmenu=false";
      } else {
        if (file.response) {
          url =
            window.api.preFile +
            "?url=" +
            window.api.secretFilePreview +
            file.response.id +
            "?token=" +
            this.$store.getters.token +
            "&showmenu=false";
        }
      }
      // this.openPage(url);
      // this.$router.push({
      //   name: "preview",
      //   query: { url: url, showBar: this.$route.query.showBar },
      // });

      window.location.href = url
    },
  },
};
</script>

<style lang="less" scoped scoped>
  .limit-fill {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }

  .sz-common-upload {
    padding: 0 50rpx;
  }

  // .sz-common-upload-btn {
  //   background-color: #f6f6f6;
  //   border: none;
  //   color: #303030;
  //   height: 50px;
  //   width: 50px;
  //   font-size: 22px;
  //   margin-bottom: 16px;
  // }

  // .sz-common-upload-header {
  //   display: flex;
  //   flex-wrap: nowrap;
  //   justify-content: space-between;
  //   position: relative;

  //   label {
  //     font-size: 17px;
  //     font-family: Source Han Sans CN;
  //     font-weight: 400;
  //     color: #303030;
  //     opacity: 1;
  //   }
  // }

  // .download_btn {
  //   color: #0075ff;
  //   padding-left: 20px;
  //   line-height: 29px;
  //   cursor: pointer;
  //   min-width: 30px;
  //   position: absolute;
  //   right: 20px;
  // }

  // .download_btn1 {
  //   color: #0075ff;
  //   padding-left: 15px;
  //   line-height: 29px;
  //   cursor: pointer;
  //   min-width: 30px;
  // }

  // .sz-common-upload {
  //   padding: 0px 16px;

  //   /deep/ .el-upload {
  //     position: relative;
  //   }

  //   /deep/ .el-upload .el-button {
  //     margin-right: 0px;
  //   }

  //   /deep/ .el-upload-list__item {
  //     margin-bottom: 14px !important;
  //     margin-top: 0px;
  //     background-color: #f5f6fa;
  //     border-radius: 10px;
  //     /*display: flex;*/
  //   }

  //   /deep/ .el-upload-list__item-name {
  //     margin-right: 0px;
  //     font-size: 14px;
  //     font-family: Source Han Sans CN;
  //     font-weight: 400;
  //     line-height: 29px;
  //     color: #303030;
  //     opacity: 1;
  //   }

  //   .el-upload-list__item-status-label {
  //   }

  //   /deep/ .el-upload-list {
  //     position: absolute;
  //     top: 55px;
  //     right: 0px;
  //     left: 0px;
  //     margin-left: 16px;
  //     margin-right: 16px;
  //   }

  //   .el-button--text {
  //     color: #007aff;
  //   }
  // }

  // .el-upload__tip {
  //   margin-top: 0px;
  // }

  // .sz-common-upload-readonly {
  //   margin-top: 20px;
  //   padding: 20px;

  //   /deep/ .el-upload-list__item {
  //     margin-bottom: 14px !important;
  //     margin-top: 0px;
  //     background-color: #ffffff;
  //     padding: 10px 14px;
  //     border-radius: 10px;
  //     display: flex;
  //     /*width: calc(~"100% - 50px");*/
  //   }

  //   .el-upload-list__item-name {
  //     margin-right: 40px;
  //     padding: 0px !important;
  //     font-size: 14px;
  //     font-family: Source Han Sans CN;
  //     font-weight: 400;
  //     line-height: 29px;
  //     color: #303030;
  //     opacity: 1;
  //   }
  // }
</style>
