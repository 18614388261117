var render, staticRenderFns, recyclableRender, components
var renderjs
import script from "./Parser.vue?vue&type=script&lang=js&"
export * from "./Parser.vue?vue&type=script&lang=js&"
import style0 from "./Parser.vue?vue&type=style&index=0&id=5810e7fe&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@dcloudio/vue-cli-plugin-uni/packages/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5810e7fe",
  null,
  false,
  components,
  renderjs
)

export default component.exports