<template>
  <div v-if="tagType.length === 1 && tagType[0] === 'text_sign'">
    <div
      :class="[align === 'center' ? 'label-input-table' : 'label-input-flex']"
      v-if="!readonly"
    >
      <template v-for="(item, index) in copyItems">
        <div
          style="
            line-height: 40px;
            width: inherit;
            padding-left: 15px;
            box-sizing: border-box;
            padding-right: 15px;
          "
          :style="getItemStyle(item)"
        >
          {{ item.text
          }}<img
            v-if="item.sign && item.type !== 'text_sign'"
            :src="item.sign"
            alt=""
            :style="{
              width:
                item.width === '100%' || item.width > screenWidth
                  ? '100%'
                  : item.width + 'px',
            }"
          />
          <template v-if="signAlign === 'right'">
            <span style="float: right; margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span
            ><span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
          </template>
          <template v-if="signAlign === 'left'">
            <span v-if="item.type === 'text_sign'">{{ item.sign }}</span>
            <span style="margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span>
          </template>
        </div>
        <div style="width: 100%; padding-left: 15px; padding-right: 15px;">
          <template v-if="signAlign === 'rightBottom'">
            <span style="float: right; margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span
            ><span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
          </template>
          <template v-if="signAlign === 'leftBottom'">
            <span style="float: left;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
            <span style="margin-left: 10px; float: left;" v-if="addDate">{{
              item.date
            }}</span>
          </template>
        </div>
        <div
          class="sz-sign-editor-btn"
          v-if="item.sign && item.userId === currentTokenUser.user.userId"
        >
          <span
            style="color: blue;"
            v-if="item.type === 'text_sign'"
            @click="editCurSign(item, index)"
            >编辑</span
          >
          <span
            style="color: red; margin-left: 10px;"
            @click="removeCurSign(index)"
            >删除</span
          >
        </div>
      </template>
      <div style="width: inherit;">
        <el-input
          :placeholder="placeholder"
          v-model="mySignItem.text"
          :type="type"
          :readonly="readonly"
          :id="`el_input_${uuid}`"
          :validate-event="required"
          :class="[
            mySignItem.align === 'center' ? 'input_center' : 'input_left',
          ]"
          :style="getItemStyle(mySignItem)"
        >
          <template slot="prepend">{{ acceptText }}</template>
        </el-input>
        <div style="line-height: 35px; text-align: right; padding-right: 20px;">
          <span>{{ mySignItem.sign }}</span>
          <span v-if="addDate" style="margin-left: 10px;">{{
            mySignItem.date
          }}</span>
        </div>
      </div>

      <div style="text-align: center; display: table; width: inherit;">
        <el-radio-group v-model="selectType" @change="changeType">
          <el-radio label="sign" v-if="showSignButton && !readonly"
            >签名
          </el-radio>
          <el-radio label="accept" v-if="showAcceptButton && !readonly"
            >同意
          </el-radio>
          <el-radio label="reject" v-if="showRejectButton && !readonly"
            >不同意
          </el-radio>
          <el-radio label="cancel" v-if="showCancelButton && !readonly"
            >取消
          </el-radio>
        </el-radio-group>
      </div>
      <div v-if="itemEditable" class="sz-sign-new-sign">
        <el-tooltip
          class="item"
          effect="dark"
          content="切换编辑/新增"
          placement="top"
        >
          <el-button
            @click="newSign('text_sign')"
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      :class="[align === 'center' ? 'label-input-table' : 'label-input-flex']"
      v-else
    >
      <template v-for="(item, index) in defaultValue">
        <div
          style="
            line-height: 40px;
            width: inherit;
            padding-left: 15px;
            box-sizing: border-box;
            padding-right: 15px;
          "
          :style="getItemStyle(item)"
        >
          {{ item.text
          }}<img
            v-if="item.sign && item.type !== 'text_sign'"
            :src="item.sign"
            alt=""
            :style="{
              width:
                item.width === '100%' || item.width > screenWidth
                  ? '100%'
                  : item.width + 'px',
            }"
          />
          <template v-if="signAlign === 'right'">
            <span style="float: right; margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span
            ><span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
          </template>
          <template v-if="signAlign === 'left'">
            <span v-if="item.type === 'text_sign'">{{ item.sign }}</span>
            <span style="margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span>
          </template>
        </div>
        <div style="width: 100%; padding-left: 15px; padding-right: 15px;">
          <template v-if="signAlign === 'rightBottom'">
            <span style="float: right; margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span
            ><span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
          </template>
          <template v-if="signAlign === 'leftBottom'">
            <span style="float: left;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
            <span style="margin-left: 10px; float: left;" v-if="addDate">{{
              item.date
            }}</span>
          </template>
        </div>
      </template>
    </div>
  </div>
  <div v-else>
    <div
      :class="[align === 'center' ? 'label-input-table' : 'label-input-flex']"
      v-if="!readonly"
    >
      <template v-for="(item, index) in copyItems">
        <div
          style="
            line-height: 40px;
            width: inherit;
            box-sizing: border-box;
            padding: 0px 15px 15px 15px;
          "
          :style="getItemStyle(item)"
        >
          {{ item.text
          }}<img
            v-if="
              item.sign &&
                item.type !== 'text_sign' &&
                item.userId !== currentTokenUser.user.userId
            "
            :src="item.sign"
            alt=""
            :style="{
              width:
                item.width === '100%' || item.width > screenWidth
                  ? '100%'
                  : item.width + 'px',
            }"
          />
          <rate-img
            :src-url="item.sign"
            :v-z="0"
            :is-draggable="false"
            :v-height="item.height"
            :v-width="item.width"
            :is-active="false"
            v-if="
              item.sign &&
                item.type !== 'text_sign' &&
                item.userId === currentTokenUser.user.userId
            "
            @getRect="getRect"
            class="cut_sign_area"
          ></rate-img>
          <template v-if="signAlign === 'right'">
            <span style="float: right; margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span>
            <span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
          </template>
          <template v-if="signAlign === 'left'">
            <span v-if="item.type === 'text_sign'">{{ item.sign }}</span>
            <span style="margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span>
          </template>
        </div>
        <div style="width: 100%; padding-left: 15px; padding-right: 15px;">
          <template v-if="signAlign === 'rightBottom'">
            <span style="float: right; margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span>
            <span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
          </template>
          <template v-if="signAlign === 'leftBottom'">
            <span style="float: left;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
            <span style="margin-left: 10px; float: left;" v-if="addDate">{{
              item.date
            }}</span>
          </template>
        </div>
        <div
          class="sz-sign-editor-btn"
          v-if="item.sign && item.userId === currentTokenUser.user.userId"
        >
          <span style="color: blue;" @click="editCurSign(item, index)"
            >编辑</span
          >
          <span
            style="color: red; margin-left: 10px;"
            @click="removeCurSign(index)"
            >删除</span
          >
        </div>
      </template>
      <div
        class="sign-area"
        v-if="mySignItem.sign === '' && !isHaveCurrentUser"
        @click="showBtnGroup"
        :id="`sign_area_${uuid}`"
      >
        点击签名
      </div>
      <!--签名方式选择框-->
      <sz-dialog :visible="dragVisible" @cancelClick="dragVisible = false">
        <div class="content_header">请选择签名类型</div>
        <div
          style="
            display: flex;
            background-color: white;
            flex-flow: row wrap;
            align-content: flex-start;
          "
        >
          <template v-for="item in tagType">
            <div
              class="content_mater_item"
              v-if="item === 'text_sign'"
              @click="switchGo(item)"
            >
              <el-avatar style="background-color: #2eae01;">
                <img src="../../assets/text.png" slot="default" />
              </el-avatar>
              <div class="sz-sign-text">
                文本签名
              </div>
            </div>
            <div
              class="content_mater_item"
              v-if="item === 'text_sign'"
              @click="confirmTextSign(item)"
            >
              <el-avatar style="background-color: #2eae01;">
                <img src="../../assets/sign.png" slot="default" />
              </el-avatar>
              <div class="sz-sign-text">
                签名
              </div>
            </div>
            <div
              class="content_mater_item"
              v-if="item === 'photo'"
              @click="goToPhotoSign(item)"
            >
              <el-avatar style="background-color: #2eae01;">
                <img src="../../assets/photo_sign.png" slot="default" />
              </el-avatar>
              <div class="sz-sign-text">
                拍照签名
              </div>
            </div>
            <div
              class="content_mater_item"
              v-if="item === 'hand_sign'"
              @click="goToHandSign(item)"
            >
              <el-avatar style="background-color: #007aff;">
                <img src="../../assets/hand_sign.png" slot="default" />
              </el-avatar>
              <div class="sz-sign-text">
                手写签名
              </div>
            </div>
            <div
              class="content_mater_item"
              v-if="item === 'template'"
              @click="goToTemplateSign(item)"
            >
              <el-avatar style="background-color: #ff8700;">
                <img src="../../assets/template_sign.png" slot="default" />
              </el-avatar>
              <div class="sz-sign-text">
                模板签名
              </div>
            </div>
            <div
              class="content_mater_item"
              v-if="item === 'text_and_sign'"
              @click="goToTextHandSign(item)"
            >
              <el-avatar style="background-color: #fa4441;">
                <img src="../../assets/text_sign.png" slot="default" />
              </el-avatar>
              <div class="sz-sign-text">
                文本+签名
              </div>
            </div>
          </template>
        </div>
      </sz-dialog>
      <!--手写框-->
      <sz-dialog
        :visible="handSignVisible"
        @cancelClick="handSignVisible = false"
        :show-cancel="false"
      >
        <hand-sign
          @closeHandSign="handSignVisible = false"
          v-model="mySignItem"
          @getSign="getHandSign"
          @close="closeHandSignVisible"
        ></hand-sign>
      </sz-dialog>
      <!--文本签名 框-->
      <sz-dialog
        :visible="textVisible"
        @cancelClick="textVisible = false"
        class="sz-sign-textAndSign"
        :show-cancel="false"
      >
        <div class="content_header">文本签名</div>
        <div style="margin: 10px;">
          <div class="sz-sign-input">
            <!--        <div class="sz-sign-input-tip">-->
            <!--          {{inputTip}}-->
            <!--        </div>-->
            <div style="width: inherit; margin-top: 5px;">
              <el-input
                :placeholder="placeholder"
                v-model="mySignItem.text"
                :type="type"
                :readonly="readonly"
                :id="`el_input_${uuid}`"
                :validate-event="required"
                :class="[
                  mySignItem.align === 'center' ? 'input_center' : 'input_left',
                ]"
                :style="getItemStyle(mySignItem)"
              >
                <template slot="prepend">{{ acceptText }}</template>
              </el-input>
              <div
                style="
                  line-height: 35px;
                  text-align: right;
                  margin: 0px 15px;
                  color: #999;
                "
              >
                <span v-if="mySignItem.type === 'text_sign'">{{
                  mySignItem.sign
                }}</span>
                <span v-if="addDate" style="margin-left: 10px;">{{
                  mySignItem.date
                }}</span>
              </div>
            </div>

            <div style="text-align: center; width: inherit;">
              <el-radio-group v-model="selectType" @change="changeType">
                <el-radio label="sign" v-if="showSignButton && !readonly"
                  >签名
                </el-radio>
                <el-radio label="accept" v-if="showAcceptButton && !readonly"
                  >同意
                </el-radio>
                <el-radio label="reject" v-if="showRejectButton && !readonly"
                  >不同意
                </el-radio>
                <el-radio label="cancel" v-if="showCancelButton && !readonly"
                  >取消
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div slot="footer" class="sz-dialog-footer">
          <span class="cancel_btn" @click="cancelText">
            取消
          </span>
          <span class="confirm_btn" @click="saveText">
            确定
          </span>
        </div>
      </sz-dialog>
      <!--文本+签名 框-->
      <sz-dialog
        :visible="textSignVisible"
        class="sz-sign-textAndSign"
        @cancelClick="textSignVisible = false"
        :show-cancel="false"
      >
        <div class="content_header">文本+手写签名</div>
        <div class="text-sign-area">
          <div>
            <el-input
              type="textarea"
              placeholder="请输入文本"
              :rows="4"
              v-model="mySignItem.text"
            ></el-input>
          </div>
          <div
            class="sign-name-area"
            style="position: relative;"
            @click="goToHandSign('text_and_sign')"
          >
            <template v-if="textSign">
              <img
                v-if="textSign"
                :src="textSign"
                alt=""
                :style="{
                  height: '300rpx',
                  width: '100%',
                }"
              />
              <i
                class="fa fa-check-circle"
                style="position: absolute; color: red; top: 2px; left: 2px;"
              ></i>
            </template>
            <span v-else>点我签名</span>
          </div>
          <div style="height: 200px; overflow-y: scroll; overflow-x: hidden;">
            <div
              class="sign-name-area-template"
              v-for="(item, index) in electronicList"
              @click="selectElectronicSign(item)"
              :key="index"
            >
              <img
                v-if="item.sign"
                :src="item.sign"
                alt=""
                :style="{
                  height: item.height + 'px',
                  width: item.width + 'px',
                }"
              />
            </div>
          </div>
        </div>
        <div slot="footer" class="sz-dialog-footer">
          <span
            class="cancel_btn"
            @click="
              textSignVisible = false;
              mySignItem.text = '';
            "
          >
            取消
          </span>
          <span class="confirm_btn" @click="saveTextSign">
            确定
          </span>
        </div>
      </sz-dialog>
      <!--模板 框-->
      <sz-dialog
        :visible="templateSignVisible"
        class="sz-sign-textAndSign"
        @cancelClick="templateSignVisible = false"
        :show-cancel="false"
      >
        <div class="content_header">模板签名</div>
        <div class="text-sign-area">
          <div>
            <el-carousel
              arrow="click"
              trigger="click"
              @change="selectTemplate"
              :autoplay="false"
              type="card"
              height="200px"
            >
              <el-carousel-item
                v-for="(item, index) in templateList"
                :key="index"
              >
                <img
                  v-if="item.sign"
                  :src="item.sign"
                  alt=""
                  style="width: 100%;"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div slot="footer" class="sz-dialog-footer">
          <span class="cancel_btn" @click="templateSignVisible = false">
            取消
          </span>
          <span class="confirm_btn" @click="saveSignTemplate">
            确定
          </span>
        </div>
      </sz-dialog>

      <!--剪切框 框-->
      <sz-dialog
        :visible="cutImgVisible"
        class="sz-sign-textAndSign"
        @cancelClick="cutImgVisible = false"
        :show-cancel="false"
      >
        <div class="content_header">模板签名</div>
        <cut-img :img-url="waitCurImgUrl"></cut-img>
        <div slot="footer" class="sz-dialog-footer">
          <span class="cancel_btn" @click="cutImgVisible = false">
            取消
          </span>
          <span class="confirm_btn" @click="saveSignTemplate">
            确定
          </span>
        </div>
      </sz-dialog>
    </div>
    <div
      :class="[align === 'center' ? 'label-input-table' : 'label-input-flex']"
      v-else
    >
      <template v-for="(item, index) in defaultValue">
        <div
          style="
            line-height: 40px;
            width: inherit;
            padding-left: 15px;
            box-sizing: border-box;
            padding-right: 15px;
          "
          :style="getItemStyle(item)"
        >
          {{ item.text }}

          <template v-if="signAlign === 'right'">
            <span style="float: right; margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span
            ><span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
            <img
              v-if="item.sign && item.type !== 'text_sign'"
              :src="item.sign"
              alt=""
              style="float: right;"
              :style="{
                width:
                  item.width === '100%' || item.width > screenWidth
                    ? '100%'
                    : item.width + 'px',
              }"
            />
          </template>
          <template v-if="signAlign === 'left'">
            <span v-if="item.type === 'text_sign'">{{ item.sign }}</span>
            <img
              v-if="item.sign && item.type !== 'text_sign'"
              :src="item.sign"
              alt=""
              :style="{
                width:
                  item.width === '100%' || item.width > screenWidth
                    ? '100%'
                    : item.width + 'px',
              }"
            />
            <span style="margin-left: 10px;" v-if="addDate">{{
              item.date
            }}</span>
          </template>
        </div>
        <div style="width: 100%; padding-left: 15px; padding-right: 15px;">
          <template v-if="signAlign === 'rightBottom'">
            <span
              style="float: right; margin-left: 10px; line-height: 60px;"
              v-if="addDate"
              >{{ item.date }}</span
            ><span style="float: right;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
            <img
              v-if="item.sign && item.type !== 'text_sign'"
              :src="item.sign"
              alt=""
              style="float: right;"
              :style="{
                width:
                  item.width === '100%' || item.width > screenWidth
                    ? '100%'
                    : item.width + 'px',
              }"
            />
          </template>
          <template v-if="signAlign === 'leftBottom'">
            <span style="float: left;" v-if="item.type === 'text_sign'">{{
              item.sign
            }}</span>
            <img
              v-if="item.sign && item.type !== 'text_sign'"
              :src="item.sign"
              alt=""
              style="float: left;"
              :style="{
                width:
                  item.width === '100%' || item.width > screenWidth
                    ? '100%'
                    : item.width + 'px',
              }"
            />
            <span
              style="margin-left: 10px; float: left; line-height: 60px;"
              v-if="addDate"
              >{{ item.date }}</span
            >
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 描述： 移动端-签名组件
 *       目前实现功能：手写签名
 *       待实现：拍照，模板，文本+手写签名
 * 时间： 2020/10/22
 */
import { deepClone } from "./utils/index";
import { mapGetters } from "vuex";
import uuidMixin from "../../mixin/uuid.js";
// import * as dd from "dingtalk-jsapi";
// import $ from "jquery";

export default {
  name: "tag-input",
  mixins: [uuidMixin],
  props: {
    align: {
      type: String,
      default: "left",
    },
    signAlign: {
      type: String,
      default: "left", //left,right ,rightBottom,leftBottom
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "textarea",
    },
    // 签名类型
    tagType: {
      type: Array,
      default: function() {
        return ["text_sign"];
      },
    },
    format: {
      type: String,
      default: "yyyy-MM-dd hh:mm:ss",
    },
    // 默认值
    defaultValue: {
      type: Array | String,
      default: function() {
        return [];
      },
    },
    // 显示签名按钮
    showSignButton: {
      type: Boolean,
      default: true,
    },
    // 显示同意按钮
    showAcceptButton: {
      type: Boolean,
      default: true,
    },
    // 显示不同意按钮
    showRejectButton: {
      type: Boolean,
      default: true,
    },
    // 显示取消按钮
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    // 只读属性
    readonly: {
      type: Boolean,
      default: false,
    },
    // 必填属性
    required: {
      type: Boolean,
      default: false,
    },
    // 是否追加时间
    addDate: {
      type: Boolean,
      default: false,
    },
    // 默认签名
    defaultSign: {
      type: Boolean,
      default: true,
    },
    // 是否需要使用合并数据属性
    isMerge: {
      type: Boolean,
      default: true,
    },
    fieldName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maxWidth: 200, // 最大宽度200
      maxHeight: 60, // 最大高度60
      operateIndex: -1, // 操作索引，编辑，删除时使用
      itemEditable: false, // 签名项是否是编辑
      cutImgVisible: false, // 剪切弹框控制
      waitCurImgUrl: "", // 待剪切的图片地址
      templateList: [],
      electronicList: [], // 电子签名数据
      templateSignVisible: false, // 模板签名选择框控制
      textSignVisible: false, // 文本+签名 弹框控制
      screenWidth: window.innerWidth,
      flag: 0,
      textVisible: false, // 文本签名选择框控制
      handSignVisible: false, //手写签名弹框展示控制
      dragVisible: false, // 签名方式选择弹框控制
      selectType: "",
      acceptText: "", // 同意，不同意的临时变量
      copyItems: [],
      formInputData: {},
      inputData: "", // 输入框的临时变量
      mySignItem: {
        text: "", //存储文本信息
        sign: "", //存储base64图片信息或者中文签名
        date: "", //签名时间
        type: "text_sign", //photo|hand_sign|template|text_and_sign|sign|text_sign
        userId: "", // "签名的用户ID"
        userName: "", // "签名的用户姓名"
        align: "left",
        width: "",
        height: "",
      },
      // currentTokenUser: JSON.parse(window.sessionStorage.getItem("userInfo")),
      currentTokenUser: JSON.parse(this.$store.getters.userInfo),
      curSign: "", // 当前签名内容
      textSign: "", // 文本+签名 的签名内容
      templateIndex: 0, // 模板签名默认选择索引
    };
  },
  computed: {
    ...mapGetters({
      mergeFormData: "mergeFormData",
    }),
    // 签名内是否包含当前用户
    isHaveCurrentUser() {
      if (!this.copyItems.length) {
        return false;
      }
      return this.copyItems.some(item => {
        return item.userId === this.currentTokenUser.user.userId;
      });
    },
  },
  created() {},
  watch: {
    currentTokenUser: {
      handler(val) {
        if (val) {
          this.mySignItem.sign = val.user.name;
          this.mySignItem.userName = val.user.name;
          this.mySignItem.userId = val.user.userId;
        }
      },
      deep: true,
    },
    fieldName: {
      handler(val) {
        if (val) {
          this.initFormData();
        }
      },
      deep: true,
    },
    "mySignItem.text"(val) {
      if (this.mySignItem.type === "text_sign") {
        if (val) {
          this.mySignItem.text = this.mySignItem.text.trim();
          this.mySignItem.sign = this.currentTokenUser.user.name;
          console.log(this.currentTokenUser, 6666);
        } else {
          this.mySignItem.sign = "";
          this.copyItems.map((item, index) => {
            if (item.type == "text_sign" && item.text == "") {
              this.copyItems.splice(index, 1);
            }
          });
          this.setFormData(this.copyItems);
        }
        this.flag = +1;
      }
    },
    mySignItem: {
      handler(val) {
        console.log(val, "sign");
        if (val) {
          let tem = [];
          if (this.mySignItem) {
            this.mySignItem.userId = this.currentTokenUser.user.userId;
          }
          // if (this.defaultSign && this.mySignItem.type === "text_sign") {
          //   this.mySignItem.sign = this.currentTokenUser.user.name;
          // }
          this.mySignItem.date = new Date().Format(this.format);
          this.mySignItem.userName = this.currentTokenUser.user.name;
          if (this.copyItems) {
            this.copyItems.map((item) => {
              tem.push(item);
            });
            if (!this.itemEditable && this.mySignItem.type === "text_sign") {
              if (this.selectType !== "cancel" && this.mySignItem.sign !== "") {
                tem.push(this.mySignItem);
              }
            }
          } else {
            if (this.selectType !== "cancel" && this.mySignItem.sign !== "") {
              tem.push(this.mySignItem);
            }
          }
          this.flag = +1;
          this.setFormData(tem);
        }
      },
      deep: true,
    },
    // 非文本类型，签名变动watch(注：文本类型直接使用上边)
    curSign(val) {
      this.mySignItem.sign = val;
      if (!this.itemEditable) {
        this.mySignItem.date = new Date().Format(this.format);
        this.copyItems.push(this.mySignItem);
      } else {
        this.copyItems.splice(this.operateIndex, this.mySignItem);
      }
      this.flag = +1;
    },
  },
  mounted() {
    this.getMyItem();
    window["setSignFromApp" + this.uuid] = this.setSignFromApp;
  },
  methods: {
    closeHandSignVisible() {
      this.handSignVisible = false;
    },
    // 更新组件值
    setFormData(tem) {
      this.$emit("input", tem);
      this.formInputData[this.fieldName] = tem;
      console.log(this.formInputData, this.isMerge);
      if (this.isMerge) {
        if (!this.required || (this.required && this.mySignItem.sign)) {
          this.$store.dispatch(
            "formDesign/mergeFormDataAction",
            this.formInputData
          );
        }
      }
    },
    /**
     * 描述：从app设置签名
     * 时间： 2020/12/19
     */
    setSignFromApp(sign) {
      const data = JSON.parse(sign);
      switch (data.type) {
        case "HAND_SIGN_RETURN":
        case "CAMERA_SIGN_RETURN":
          if (data.message) {
            if (data.message.code === "FAIL") {
              this.warning(data.message.message);
            } else {
              this.getBase64WidthAndHeight(
                data.message.sign,
                data.message.signType
              );
            }
          }
          break;
      }
      console.log(JSON.stringify(data), "app");
    },
    // 获取base64图片的宽高
    async getBase64WidthAndHeight(sign, type) {
      var img = new Image();
      img.src = sign;
      img.onload = () => {
        this.mySignItem.width = "";
        this.mySignItem.height = "";
        console.log(img.width, img.height);
        let rate = 0;
        if (type === "text_and_sign") {
          rate = img.height >= this.maxHeight ? this.maxHeight : img.height;
          if (this.mySignItem.width === "") {
            let temWidth = (rate / img.height) * img.width;
            this.mySignItem.width =
              img.width > this.maxHeight
                ? temWidth > this.maxWidth
                  ? this.maxWidth
                  : temWidth
                : img.width;
          }
          if (this.mySignItem.height === "") {
            this.mySignItem.height = rate;
          }
          this.textSign = sign;
        } else {
          if (this.mySignItem.width === "") {
            this.mySignItem.width = this.maxWidth;
          }
          if (this.mySignItem.height === "") {
            this.mySignItem.height = (this.maxWidth / img.width) * img.height;
          }
          this.curSign = sign;
          console.log("签名当前");
        }
      };
    },
    /**
     * 描述：模板选择
     * 时间： 2020/11/5
     */
    selectTemplate(index, oldIndex) {
      this.templateIndex = index;
    },
    /**
     * 描述：文本+签名 确认操作
     * 时间： 2020/11/5
     */
    saveTextSign() {
      if (this.textSign === "") {
        this.warning("请点击点我签名，进行签名！");
        return;
      }
      this.curSign = this.textSign;
      this.textSignVisible = false;
    },
    /**
     * 描述：确定选择的模板签名
     * 时间： 2020/11/5
     */
    saveSignTemplate() {
      let tem = this.templateList[this.templateIndex];
      this.itemEditable = false;
      this.curSign = tem.sign;
      this.mySignItem = Object.assign(this.mySignItem, {
        width: tem.width,
        height: tem.height,
      });
      this.templateSignVisible = false;
    },
    // 拖动该更图片大小
    getRect(rect) {
      this.operateIndex = rect.index;
      this.mySignItem.width = rect.width;
      this.mySignItem.height = rect.height;
    },
    /**
     * 描述：钉钉网页手写签名
     * 时间： 2020/12/19
     */
    getHandSign(val) {
      // this.removeMySign()
      this.curSign = "";
      this.flag = +1;
      if (this.mySignItem.type === "text_and_sign") {
        this.textSign = val.resultImg;
      } else {
        this.curSign = val.resultImg;
      }
      this.$set(this.mySignItem, "width", val.width);
      this.$set(this.mySignItem, "height", val.height);
      this.flag = +1;
      console.log(this.mySignItem);
    },
    /**
     * 描述：取消文本签名
     * 时间： 2021/3/22
     */
    cancelText() {
      this.textVisible = false;
      this.resetMySignItem("text_sign");
    },
    /**
     * 描述：确定文本签名
     * 时间： 2021/3/22
     */
    saveText() {
      this.textVisible = false;
      if (!this.itemEditable) {
        this.copyItems.push(this.mySignItem);
      }
      console.log(this.mySignItem);
    },
    /**
     * 描述：弹出文本签名
     * 时间： 2020/10/29
     */
    goToTextSign(item) {
      this.selectType = "";
      this.mySignItem.type = item;
      this.textVisible = true;
      this.dragVisible = false;
    },
    // 手写签名
    goToHandSign(type) {
      this.mySignItem.type = type;
      if (type === "hand_sign") {
        this.mySignItem.text = "";
      }
      this.dragVisible = false;
      this.handSignVisible = true;
      // 钉钉环境
      // if (dd.env.platform !== "notInDingTalk") {
      //   this.handSignVisible = true;
      // } else {
      // Android.showSignActivity(this.mySignItem.sign, this.uuid, type);
      // }
    },
    /**
     * 描述：拍照签名
     * 时间： 2020/11/5
     */
    goToPhotoSign(item) {
      this.mySignItem.type = item;
      // if (dd.env.platform !== "notInDingTalk") {
      //   this.warning("钉钉暂不支持拍照签名");
      // } else {
      this.dragVisible = false;
      // Android.showCameraActivity(this.uuid, item);
      // }
    },

    /**
     * 描述：根据url获取base64
     * 时间： 2020/11/5
     */
    getBase64(imgUrl) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      canvas.width = 100;
      canvas.height = 100;
      var img = new Image();
      img.onload = function() {
        ctx.drawImage(this, 0, 0, 100, 100);
        var base64 = canvas.toDataURL("image/jpg", 1);
        console.log(base64);
      };
      //设置图片跨域访问
      img.crossOrigin = "anonymous";
      //加时间戳解决跨域问题
      img.src = imgUrl + "?timeStamp=" + new Date(); //解决问题的关键点
      console.log(img.src);
      document.body.appendChild(img);
    },
    getBase64Image(img, width, height) {
      var canvas = document.createElement("canvas");
      canvas.width = width ? width : img.width;
      canvas.height = height ? height : img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      var dataURL = canvas.toDataURL();
      return dataURL;
    },
    getCanvasBase64(img) {
      return new Promise((resolve) => {
        var image = new Image();
        image.crossOrigin = "anonymous";
        image.src = img;
        image.onload = () => {
          resolve(this.getBase64Image(image));
        };
      });
    },
    closeDialog(val) {
      this.dragVisible = val;
    },
    // 编辑当前签名
    editCurSign(item, index) {
      this.operateIndex = index;
      this.itemEditable = true;
      this.mySignItem = item;
      if (item && item.type) {
        this.switchGo(item.type);
      }
    },
    /**
     * 描述：模板签名弹出
     * 时间： 2020/11/2
     */
    goToTemplateSign(item) {
      this.mySignItem.type = item;
      this.templateSignVisible = true;
      this.templateIndex = 0;
      this.dragVisible = false;
      this.getMyTemplateSignList();
    },
    /**
     * 描述：获取我的模板签名
     * 时间： 2020/11/5
     */
    getMyTemplateSignList() {
      this.API.getSignTemplateList().then((res) => {
        this.templateList = res.data;
      });
    },
    /**
     * @desc 获取我得电子签名
     * @time 2021/7/16
     */
    getMyElectronicSignList() {
      this.API.getSignTemplateList({ type: "ELECTRONIC_SIGN" }).then((res) => {
        if (res.data) {
          res.data.map((item) => {
            let rate =
              item.height >= this.maxHeight ? this.maxHeight : item.height;
            let temWidth = (rate / item.height) * item.width;
            item.height = rate;
            item.width =
              item.width > this.maxHeight
                ? temWidth > this.maxWidth
                  ? this.maxWidth
                  : temWidth
                : item.width;
          });
        }
        this.electronicList = res.data;
      });
    },

    /**
     * @desc 选择电子签名
     * @time 2021/7/19
     */
    selectElectronicSign(item) {
      this.getBase64WidthAndHeight(item.sign, "text_and_sign");
    },
    /**
     * 描述：弹出文本+签名
     * 时间： 2020/10/29
     */
    goToTextHandSign(item) {
      this.mySignItem.type = item;
      this.textSignVisible = true;
      this.dragVisible = false;
      if (!this.itemEditable) {
        this.textSign = "";
      }
      this.getMyElectronicSignList();
    },
    /**
     * 描述：根据类型跳转
     * 时间： 2020/11/5
     */
    switchGo(type) {
      switch (type) {
        case "text_sign": // 文本签名
          this.goToTextSign(type);
          break;
        case "hand_sign": // 手写
          this.goToHandSign("hand_sign");
          break;
        case "photo": // 拍照
          this.goToPhotoSign();
          break;
        case "template": // 模板
          this.goToTemplateSign(type);
          break;
        case "text_and_sign": // 文本+签名
          this.goToTextHandSign(type);
          break;
      }
    },
    //直接签名
    confirmTextSign(item) {
      this.mySignItem.type = item;
      this.dragVisible = false;
      this.selectType = "sign";
      this.mySignItem.sign = this.currentTokenUser.user.name;
      // console.log(this.itemEditable)
      if (!this.itemEditable) {
        this.mySignItem.date = new Date().Format(this.format);
        this.copyItems.push(this.mySignItem);
        this.itemEditable = true; // 解决watch 里会再加一次的问题
      } else {
        this.copyItems.splice(this.operateIndex, this.mySignItem);
      }
      this.flag = +1;
      // console.log(this.copyItems)
    },
    // 移除当前签名
    removeCurSign(index) {
      console.log(index);
      this.itemEditable = false;
      this.operateIndex = index;
      this.copyItems.splice(index, 1);
      this.defaultValue.splice(index, 1);
      // this.mySignItem.sign = "";
      // this.mySignItem.text = "";
      this.resetMySignItem(this.mySignItem.type);
    },
    /**
     *  显示按钮组 ，如果配置的按钮类型只有1个直接弹出，多个时显示选择框
     *  2020-10-20
     * */
    showBtnGroup() {
      if (this.tagType && this.tagType.length > 1) {
        // 多个，弹出选择类型框
        this.dragVisible = true;
      } else {
        // 只有一个，判断这一个的类型，弹出不同的组件
        if (this.tagType && this.tagType[0]) {
          this.switchGo(this.tagType[0]);
        }
      }
    },
    changeType(label) {
      this.selectType = label;
      switch (label) {
        case "sign":
          this.mySignItem.sign = this.currentTokenUser.user.name;
          this.mySignItem.date = new Date().Format(this.format);
          break;
        case "accept":
          this.acceptText = "同意";
          console.log(this.mySignItem.text.startsWith("不同意"));
          if (this.mySignItem.text.startsWith("不同意")) {
            this.mySignItem.text = this.mySignItem.text.substring(1);
          } else if (!this.mySignItem.text.startsWith("同意")) {
            if (this.mySignItem.text) {
              this.mySignItem.text =
                this.acceptText + "," + this.mySignItem.text;
            } else {
              this.mySignItem.text = this.acceptText + this.mySignItem.text;
            }
          }
          this.mySignItem.sign = this.currentTokenUser.user.name;
          this.mySignItem.date = new Date().Format(this.format);
          break;
        case "reject":
          this.acceptText = "不同意";
          if (this.mySignItem.text.startsWith("同意")) {
            this.mySignItem.text = "不" + this.mySignItem.text;
          } else if (!this.mySignItem.text.startsWith("不同意")) {
            if (this.mySignItem.text) {
              this.mySignItem.text =
                this.acceptText + "," + this.mySignItem.text;
            } else {
              this.mySignItem.text = this.acceptText + this.mySignItem.text;
            }
          }
          this.mySignItem.sign = this.currentTokenUser.user.name;
          this.mySignItem.date = new Date().Format(this.format);
          break;
        case "cancel":
          this.mySignItem.type = "text_sign";
          this.mySignItem.text = "";
          this.mySignItem.sign = "";
          this.mySignItem.date = "";
          this.mySignItem.userId = "";
          this.mySignItem.align = "left";
          console.log(this.mySignItem);
          this.acceptText = "";
          break;
      }
    },
    // 获取我的
    getMyItem() {
      if (this.defaultValue && Array.isArray(this.defaultValue)) {
        this.defaultValue.map((item) => {
          console.log(item, "getMyItem");
          this.copyItems.push(item);
        });
        this.initFormData();
      }
    },
    // 初始化formData
    initFormData() {
      if (Array.isArray(this.defaultValue)) {
        this.$set(this.formInputData, this.fieldName, this.defaultValue);
      } else {
        this.$set(this.formInputData, this.fieldName, []);
      }
      if (this.isMerge) {
        this.$store.dispatch(
          "formDesign/mergeFormDataAction",
          this.formInputData
        );
      }
    },
    /**
     * 描述：修改编辑模式为新增，并重置mySignItem
     * 时间： 2021/3/8
     */
    newSign(type) {
      this.itemEditable = false;
      this.resetMySignItem(type);
    },
    /**
     * 描述：根据类型 重置mySignItem
     * 时间： 2021/3/8
     */
    resetMySignItem(type) {
      this.mySignItem = {
        text: "", //存储文本信息
        sign: "", //存储base64图片信息或者中文签名
        date: "", //签名时间
        width: "", //宽度
        height: "", // 高度
        type: type, //photo|hand_sign|template|text_and_sign|sign|text_sign
        userId: "", // "签名的用户ID"
        userName: "", // "签名的用户姓名"
        align: "left",
      };
      this.delFormData();
    },
    //删除，取消得时候删除当前绑定得属性
    delFormData() {
      this.$store.dispatch("delFormDataAction", this.fieldName);
    },
    getItemStyle(item) {
      let style = "";
      if (item.color) {
        style += "color:" + item.color + ";";
      }
      if (item.align) {
        style += "text-align:" + item.align + ";";
      }
      return style;
    },
  },
};
</script>

<style lang="less" scoped scoped>
.sign-name-area-template {
  width: 100%;
  min-height: 60px;
  height: auto;
  border: 1px dashed #cecece;
  text-align: center;
  color: #cecece;
  margin-top: 10px;
}

.sz-sign-new-sign {
  width: 100%;
  padding: 10px;
  /*border-top: 1px solid;*/
  margin-top: 10px;
}

.sz-dialog-footer {
  line-height: 30px;
  text-align: center;
}

.sz-sign-textAndSign {
  /deep/ .content {
    background-color: white !important;
  }
}

.content_header {
  background-color: white;
  line-height: 60px;
  text-align: center;
  border-bottom: 1px solid #f5f6fa;
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #99b4ca;
  opacity: 1;
}

.text-sign-area {
  padding: 10px;
}

.sign-name-area {
  width: 100%;
  min-height: 60px;
  border: 1px dashed #cecece;
  text-align: center;
  line-height: 60px;
  color: #cecece;
  margin-top: 10px;
  margin-left: auto;
}

.cancel_btn {
  background-color: white;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  border-top: 1px solid #f5f6fa;
  color: #fa4441;
  opacity: 1;
}

.confirm_btn {
  background-color: white;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  border-top: 1px solid #f5f6fa;
  color: #0075ff;
  margin-left: 30px;
  opacity: 1;
}

.el-carousel__item img {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.sz-sign-editor-btn {
  /*display: flex;*/
  /*justify-content: space-between;*/
  box-sizing: border-box;
  text-align: right;
  padding: 0px 15px;
  flex: 1;
  line-height: 40px;
}

.sz-sign-text {
  font-size: 13px;
  font-weight: 400;
}

.btn_cls {
  border-radius: 40px;
  border: none;
  font-size: 20px !important;
  padding: 10px;
  margin-right: 16px;
}

.el-avatar--circle {
  padding: 13px;
  width: 50px;
  height: 50px;
}

.sign-area {
  width: 100%;
  height: 100px;
  border: 1px dashed #cecece;
  text-align: center;
  line-height: 100px;
  color: #cecece;
}

.content_header {
  background-color: white;
  line-height: 60px;
  text-align: center;
  border-bottom: 1px solid #f5f6fa;
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #99b4ca;
  opacity: 1;
}

.content_mater_item {
  background-color: white;
  line-height: 35px;
  text-align: center;
  /*border-bottom: 1px solid #f5f6fa;*/
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #303030;
  opacity: 1;
  margin-top: 5px;
  /*padding: 8px 2px;*/
  flex: 0 0 25%;
}

.label-input-flex {
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
  justify-context: centet;
  align-items: center;
}

.label-input-table {
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: table;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
  justify-context: centet;
  align-items: center;
}

/deep/ .input_center {
  .el-input__inner {
    text-align: center !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/deep/ .input_left {
  .el-input__inner {
    text-align: left !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.sz-sign-textAndSign {
  /deep/ .el-radio {
    margin-right: 10px !important;
  }
}
</style>
