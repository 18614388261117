const install = (Vue, vm) => {
	Vue.prototype.$util = {
		/**
		 * 图片预览方法
		 * @param {String | Array} image 需要预览的图片路径/数组集合
		 * @param {String | Number} current 当前显示图片路径/索引值
		 * @param {String} indicator = [default | number | none] 图片指示器样式
		 */
		previewImage(image, current = 0, indicator = 'default') {
			uni.previewImage({
				indicator,
				current,
				urls: Array.isArray(image) ? image : new Array(1).fill(image)
			})
		},

		// /**
		//  * 获取数据字典
		//  * @param {String | Number} code 字典的key值
		//  * @param {String} type = [default | map] 返回格式
		//  * @returns {[]|*}
		//  */
		// getDictionary(code, type = 'default') {
		//   if (!code) return
		//   let arr = JSON.parse(vm.$store.getters.dict)
		//   let obj = arr.find(v => v.keyword === code)
		//   switch (type) {
		//     case "default":
		//       return obj ? obj.children : []
		//     case "map":
		//       let temp = {}
		//       if (obj) {
		//         for (let i = 0; i < obj.children.length; i++) {
		//           temp[obj.children[i].keyword] = obj.children[i].label
		//         }
		//       }
		//       return temp
		//   }
		// },

		/**
		 * 获取数据字典
		 * @param {String | Number} code 字典的key值
		 * @param {String} type = [default | map] 返回格式
		 * @returns {[]|*}
		 */
		getDictionary(code, type = 'default') {
			console.log(vm.$store.getters,'vm.$store.getters.dict');
			if (!code) return
			let arr = JSON.parse(vm.$store.getters.dict)
			let obj = arr.find(v => v.keyword === code)
			switch (type) {
				case "default":
					return obj ? obj.children : []
				case "map":
					let temp = {}
					if (obj) {
						for (let i = 0; i < obj.children.length; i++) {
							temp[obj.children[i].keyword] = obj.children[i].label
						}
					}
					return temp
			}
		},

		/**
		 * 为tabBar设置红点数量提示
		 * @description 只能在tabBar页面使用
		 * @param {Number} index tabBar的哪一项，从左边算起
		 */
		setBadgeNumber(index = 2) {
			let {
				unread
			} = vm.$store.getters
			if (unread > 0) uni.setTabBarBadge({
				index,
				text: unread.toString()
			})
			else uni.removeTabBarBadge({
				index
			})
		},

		// 判断是否在微信环境下
		isWeixin: () => {
			return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
		},

		serialNumber: (i) => {
			return i < 10 ? '0' + (i) : i
		},

		// 解析微信code
		getUrlCode(name) {
			return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location
				.href) || [, ''])[1].replace(/\+/g, '%20')) || null
		},

		isNumber(val) {
			var regPos = /^\d+(\.\d+)?$/; //非负浮点数
			var regNeg =
				/^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
			return regPos.test(val) || regNeg.test(val)
		},

		// 电话
		makePhoneCall(phoneNumber) {
			uni.makePhoneCall({
				phoneNumber
			});
		},

		// 时间格式化 兼容苹果
		timeFormatIos(time) {
			const isIos = !!navigator.userAgent.match(/\(i[^;]+;(u;)? CPU.+Mac OS X/);

			if (isIos) time = time.replace(/-/g, "/")
			return vm.$u.timeFormat(time)
		}
	}
}
export default {
	install
};