const install = (Vue, vm) => {
  Vue.prototype.$enum = {
    // 问卷调查状态
    questionStatus: {
      'PUBLISH': { title: '调查中', bgColor: '#e5f1ff', color: '#007AFF' },
      'FINISH': { title: '已完结', bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' }
    },

    // 问卷调查类别
    questionTypeMap: {
      'CHECK_BOX': '多选',
      'RADIO': '单选',
      'FILL': '文本'
    },

    // 儿童康复救助申请状态
    recoveryStatus: {
      'CL_APPLY': { title: '残联待审核', bgColor: '#e5f1ff', color: '#007AFF' },
      'CL_REJECT': { title: '残联审核驳回', bgColor: 'rgba(250, 53, 52,0.1)', color: 'rgb(250, 53, 52)' },
      'SELECT_INSTITUTE': { title: '待选择机构', bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      'INSTITUTE_APPLY': { title: '机构待确认', bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      'INSTITUTE_CL_REJECT': { title: '机构审核驳回', bgColor: 'rgba(250, 53, 52,0.1)', color: 'rgb(250, 53, 52)' },
      'FINISH': { title: '审批已完成', bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
      'EVALUATE': { title: '已评价', bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
    },

    // 辅助器具适配活动状态
    activitiesStatus: {
      0: { title: "活动未开始", bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      1: { title: "活动进行中", bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
      2: { title: "机构鉴定中", bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      3: { title: "待上传财政资料", bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      4: { title: "备货中", bgColor: '#e5f1ff', color: '#007AFF' },
      5: { title: "活动完结", bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' }
    },

    // // 辅助器具申请状态
    applianceApplicationStatus: {
      '-2': { title: "鉴定机构驳回", bgColor: 'rgba(250, 53, 52,0.1)', color: 'rgb(250, 53, 52)' },
      '-1': { title: "残联驳回", bgColor: 'rgba(250, 53, 52,0.1)', color: 'rgb(250, 53, 52)' },
      '0': { title: "未申请", bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      '1': { title: "待选择鉴定机构", bgColor: '#e5f1ff', color: '#007AFF' },
      '2': { title: "鉴定机构审核中", bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      '3': { title: "残联审核中", bgColor: '#e5f1ff', color: '#007AFF' },
      '4': { title: "待上传财政资料", bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      '5': { title: "备货中", bgColor: '#e5f1ff', color: '#007AFF' },
      '6': { title: "待评价", bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      '7': { title: "已评价", bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
    },

    // 无障碍改造状态
    barrierFreeStatus: {
      'COLLECT': { title: '汇总中', bgColor: '#e5f1ff', color: '#007AFF' },
      'REMOULD': { title: '改造中', bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      'FINISH': { title: '已完结', bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
      'NOT_REPORT': { title: '未上报', bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      'REPORT': { title: '已上报', bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
      'RETURN': { title: '退回', bgColor: 'rgba(250, 53, 52,0.1)', color: 'rgb(250, 53, 52)' },
      'PASS': { title: '审批通过', bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
      'APPROVE': { title: '审批中', bgColor: '#e5f1ff', color: '#007AFF' },
      'WAIT_CONS': { title: '待施工', bgColor: 'rgba(255, 135, 0, 0.1)', color: '#FF8700' },
      'COMPLETE': { title: '待评价', bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
      'DOWN': { title: '下架', bgColor: 'rgba(250, 53, 52,0.1)', color: 'rgb(250, 53, 52)' },
      'COMMENT_FINISH': { title: '已评价', bgColor: 'rgba(46, 174, 1, 0.1)', color: '#2EAE01' },
      'DESIGN': { title: '设计中', bgColor: '#e5f1ff', color: '#007AFF' },
    },

    // 办证步骤
    CERTIFICATE_STEPS: [
      { name: '填写申请表', id: 'start-d6e10129486a4b48b43f652edc7b19bd' },
      { name: '残联初审', id: 'common-70ab20eaaa5048ddab2eb7c6eb18e9fc' },
      { name: '医院评定', id: 'common-79c954e146604e668e0beea7ad919ca2' },
      { name: '残联审核', id: 'end-80c09af60a9049de8f3d93187c5b9b00' },
    ],

    // 复评
    FUPING_STEPS: [
      { name: '申请复评', id: 'start-4bf23ea1e5944b13b8fea8ab68fa2189' },
      { name: '残联审核', id: 'common-4773fe23a19e4377814a83ce789bb95c' },
      { name: '医院评定', id: 'end-36f497ccd9c443f8bb7c1c34622b5758' },
    ],

    // 变更
    BIANGENG_STEPS: [
      { name: '填写申请表', id: 'start-74039f5a8b564b408cb2e6ebd5f37e1a' },
      { name: '残联初审', id: 'common-683db5b833a64f18b5d40db899d47950' },
      { name: '医院评定', id: 'common-360d0de70f0b4ff7909c8c57a4c92c36' },
      { name: '残联审核', id: 'end-18ac3e54b6884c40b823586f60127774' },
    ],

    // 注销
    LOGOUT_STEPS: [
      { name: '注销申请', id: 'start-85875c30671e42ac82b68b22bc64ca14' },
      { name: '残联审核', id: 'end-5aafe134cb97498d8ff5f112b4f7a148' },
    ],

    // 遗失补办
    REPLACE_STEPS: [
      { name: '填写申请表', id: 'start-0a997df6a1944413871f82699741837e' },
      { name: '残联审核', id: 'end-b2b590ced1a5408caef2e397955d8556' },
    ],

    // 临时救助步骤
    TEMPORARY_STEPS: [
      { name: '发起', id: 'start-01904b35a06a4795bd79b5d7834cc556' },
      { name: '残联审核', id: 'common-602491f2609240fa88e634e8363dfc93' },
      { name: '公示完成', id: 'end-c1972dd7d72b4f79ae97aa0f26a56781' },
    ],

    // 残疾人迁入
    MOVE_IN: [
      { name: '迁入申请', id: 'start-5163d87e6c094f5c8096c803ffbb2c7a' },
      { name: '残联审核', id: 'end-c5e5bfc7f37a420383ddd1f280a30362' },
    ],

    //姓名变更
    NAME_CHANGE: [
      { name: '发起申请', id: 'start-6feb485906f44741a962d0664210ee6e' },
      { name: '残联审核', id: 'end-fd02c7f75fec4959914b2ef67ab1d1da' },
    ]
  }
}
export default {
  install
};
