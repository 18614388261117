import axios from "axios";
import qs from "query-string";

export default {
  /**
   * 查询基本服务列表（分页）
   * @method
   * @name getAssistBasicServicePageList
   */
  getAssistBasicServicePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/assist/basic/service"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改基本服务
   * @method
   * @name addOrUpdateAssistBasicService
   */
  addOrUpdateAssistBasicService: (params = {}) => {

    let domain = window.api.domain
    let path = "/assist/basic/service"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询基本服务
   * @method
   * @name getAssistBasicServiceById
   */
  getAssistBasicServiceById: (params = {}) => {

    let domain = window.api.domain
    let path = "/assist/basic/service/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除基本服务
   * @method
   * @name deleteAssistBasicServiceById
   */
  deleteAssistBasicServiceById: (params = {}) => {

    let domain = window.api.domain
    let path = "/assist/basic/service/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文档审核列表（分页）
   * @method
   * @name getDocumentAuditPageList
   */
  getDocumentAuditPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/audit/document"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    if (params["documentId"] !== undefined) {
      queryParameters["documentId"] = params["documentId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询DocumentAudit
   * @method
   * @name getDocumentAuditById
   */
  getDocumentAuditById: (params = {}) => {

    let domain = window.api.domain
    let path = "/audit/document/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除DocumentAudit
   * @method
   * @name deleteDocumentAuditById
   */
  deleteDocumentAuditById: (params = {}) => {

    let domain = window.api.domain
    let path = "/audit/document/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取APP扫码登录二维码信息
   * @method
   * @name getQrCode
   */
  getQrCode: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/app/code"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 校验APP扫码登录
   * @method
   * @name codeVerify
   */
  codeVerify: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/app/code/verify"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["connectionId"] !== undefined) {
      queryParameters["connectionId"] = params["connectionId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * APP扫码登录判断是否成功
   * @method
   * @name confirmLogin
   */
  confirmLogin: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/app/confirm/login"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["connectionId"] !== undefined) {
      queryParameters["connectionId"] = params["connectionId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取图片验证码
   * @method
   * @name getImgVerifyCode
   */
  getImgVerifyCode: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/code"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/octet-stream'

    if (params["width"] !== undefined) {
      queryParameters["width"] = params["width"]
    }

    if (params["height"] !== undefined) {
      queryParameters["height"] = params["height"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 钉钉应用首页url
   * @method
   * @name dingIndex
   */
  dingIndex: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/ding"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["appKey"] !== undefined) {
      queryParameters["appKey"] = params["appKey"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取钉钉配置
   * @method
   * @name getConfig
   */
  getConfig: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/ding/config"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["appKey"] !== undefined) {
      queryParameters["appKey"] = params["appKey"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 手机钉钉自动登录
   * @method
   * @name dingLogin
   */
  dingLogin: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/ding/login"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["authCode"] !== undefined) {
      queryParameters["authCode"] = params["authCode"]
    }

    if (params["accessToken"] !== undefined) {
      queryParameters["accessToken"] = params["accessToken"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户验证码登录(发送验证码(不含图形验证码),如果返回201,则前端需要调用包含图形验证码的接口)
   * @method
   * @name findLoginCode
   */
  findLoginCode: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/find/login/{mobile}/{idCard}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    path = path.replace("{mobile}", params["mobile"])

    if (params["mobile"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: mobile"))
    }

    path = path.replace("{idCard}", params["idCard"])

    if (params["idCard"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: idCard"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户找回密码(1.发送验证码(含图形验证码))
   * @method
   * @name findPasswordVerify
   */
  findPasswordVerify: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/find/password/verify/{mobile}/{code}/{idCard}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    path = path.replace("{mobile}", params["mobile"])

    if (params["mobile"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: mobile"))
    }

    path = path.replace("{code}", params["code"])

    if (params["code"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: code"))
    }

    path = path.replace("{idCard}", params["idCard"])

    if (params["idCard"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: idCard"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户账号密码登录
   * @method
   * @name login
   */
  login: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/login"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["username"] !== undefined) {
      form["username"] = params["username"]
    }

    if (params["username"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: username"))
    }

    if (params["password"] !== undefined) {
      form["password"] = params["password"]
    }

    if (params["password"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: password"))
    }

    if (params["code"] !== undefined) {
      form["code"] = params["code"]
    }

    if (params["code"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: code"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户账号密码登录(无验证码,为获取token使用)
   * @method
   * @name loginNotCode
   */
  loginNotCode: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/login/not/code"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["username"] !== undefined) {
      form["username"] = params["username"]
    }

    if (params["username"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: username"))
    }

    if (params["password"] !== undefined) {
      form["password"] = params["password"]
    }

    if (params["password"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: password"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户找回密码(3.验证码重置密码)
   * @method
   * @name settingPassword
   */
  settingPassword: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/setting/password/{mobile}/{password}/{idCard}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    path = path.replace("{mobile}", params["mobile"])

    if (params["mobile"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: mobile"))
    }

    path = path.replace("{password}", params["password"])

    if (params["password"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: password"))
    }

    path = path.replace("{idCard}", params["idCard"])

    if (params["idCard"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: idCard"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户找回密码(2.判断验证码正确性,返回true/false)
   * @method
   * @name verifyPassword
   */
  verifyPassword: (params = {}) => {

    let domain = window.api.domain
    let path = "/auth/verify/password/{mobile}/{code}/{idCard}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    path = path.replace("{mobile}", params["mobile"])

    if (params["mobile"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: mobile"))
    }

    path = path.replace("{code}", params["code"])

    if (params["code"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: code"))
    }

    path = path.replace("{idCard}", params["idCard"])

    if (params["idCard"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: idCard"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询辅助适配活动的列表（分页）
   * @method
   * @name getAssistActivityInfoPageList
   */
  getAssistActivityInfoPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增辅助器具适配
   * @method
   * @name addAssistActivityInfo
   */
  addAssistActivityInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据适配活动id查询已经选择过的分页人员信息
   * @method
   * @name getBindDisabledUserInfoById
   */
  getBindDisabledUserInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/bind/userList/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 选择鉴定机构
   * @method
   * @name chooseInstitute
   */
  chooseInstitute: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/choose/institute/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据适配活动id和用户Ids删除适配人员信息
   * @method
   * @name deleteDisabledsByIdAndUserIds
   */
  deleteDisabledsByIdAndUserIds: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/delete/disabled/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除辅助器具的适配
   * @method
   * @name deleteAssistActivityInfoById
   */
  deleteAssistActivityInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/delete/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询辅助器具适配活动的详情
   * @method
   * @name getAssistActivityDetailInfoById
   */
  getAssistActivityDetailInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/detail/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据excel导入保存适配人员信息
   * @method
   * @name saveDisabledsExcel
   */
  saveDisabledsExcel: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/excel/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["fileTypes"] !== undefined) {
      queryParameters["fileTypes"] = params["fileTypes"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据根据年份或活动id导出已适配的人员相关信息(excel)
   * @method
   * @name exportPersonnelExcel
   */
  exportPersonnelExcel: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/export/personnel/excel"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 确认适配活动完结
   * @method
   * @name finishActivity
   */
  finishActivity: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/finish/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取可选择的鉴定机构信息
   * @method
   * @name getInstituteList
   */
  getInstituteList: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/get/instituteList/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 帮他人申请:根据相关内容匹配活动下的残疾人信息
   * @method
   * @name getPersonByParams
   */
  getPersonByParams: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/get/person/by/params"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["activityId"] !== undefined) {
      queryParameters["activityId"] = params["activityId"]
    }

    if (params["disabledPersonName"] !== undefined) {
      queryParameters["disabledPersonName"] = params["disabledPersonName"]
    }

    if (params["disabledCard"] !== undefined) {
      queryParameters["disabledCard"] = params["disabledCard"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 
   * @method
   * @name importExcel
   */
  importExcel: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/import/excel"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["fileTypes"] !== undefined) {
      queryParameters["fileTypes"] = params["fileTypes"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据excel和活动id导入该年已适配器材用户的相关信息
   * @method
   * @name importPersonnelExcel
   */
  importPersonnelExcel: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/import/personnel/excel"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["fileTypes"] !== undefined) {
      queryParameters["fileTypes"] = params["fileTypes"]
    }

    if (params["activityId"] !== undefined) {
      queryParameters["activityId"] = params["activityId"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 发布或下架辅助器具适配
   * @method
   * @name publishAssistActivityInfo
   */
  publishAssistActivityInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/publish/{activityId}/{status}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    path = path.replace("{status}", params["status"])

    if (params["status"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: status"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 上传财政资料时可以选择服务商的信息
   * @method
   * @name serviceInfo
   */
  serviceInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/service/info/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 帮他人申请器具适配的查询页面,只查询发布后且未结束的活动（分页）
   * @method
   * @name showRunningActivity
   */
  showRunningActivity: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/show/running/activity"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 适配活动上传财政资料
   * @method
   * @name submitFile
   */
  submitFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/submit/file/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 适配活动提交财政(同时修改活动和适配清单表)
   * @method
   * @name submitFinance
   */
  submitFinance: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/submit/finance/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据适配活动id查询未适配人员信息
   * @method
   * @name getUnBindDisabledUserInfoById
   */
  getUnBindDisabledUserInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/unbind/userList/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["disabledTypeKey"] !== undefined) {
      queryParameters["disabledTypeKey"] = params["disabledTypeKey"]
    }

    if (params["disabledLevel"] !== undefined) {
      queryParameters["disabledLevel"] = params["disabledLevel"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改辅助器具适配
   * @method
   * @name addOrUpdateAssistActivityInfo
   */
  addOrUpdateAssistActivityInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/update"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据适配活动id保存适配人员信息
   * @method
   * @name saveDisableds
   */
  saveDisableds: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/activity/info/{id}/{applyType}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    path = path.replace("{applyType}", params["applyType"])

    if (params["applyType"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyType"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据适配活动id查询辅助器具申请列表
   * @method
   * @name getApplyUserPageInfoByServiceId
   */
  getApplyUserPageInfoByServiceId: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/apply/userList/service/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["disabledTypeId"] !== undefined) {
      queryParameters["disabledTypeId"] = params["disabledTypeId"]
    }

    if (params["cid"] !== undefined) {
      queryParameters["cid"] = params["cid"]
    }

    if (params["userName"] !== undefined) {
      queryParameters["userName"] = params["userName"]
    }

    if (params["region"] !== undefined) {
      queryParameters["region"] = params["region"]
    }

    if (params["applyType"] !== undefined) {
      queryParameters["applyType"] = params["applyType"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据适配活动id查询辅助器具申请列表
   * @method
   * @name getApplyUserPageInfoById
   */
  getApplyUserPageInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/apply/userList/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["disabledTypeId"] !== undefined) {
      queryParameters["disabledTypeId"] = params["disabledTypeId"]
    }

    if (params["cid"] !== undefined) {
      queryParameters["cid"] = params["cid"]
    }

    if (params["userName"] !== undefined) {
      queryParameters["userName"] = params["userName"]
    }

    if (params["region"] !== undefined) {
      queryParameters["region"] = params["region"]
    }

    if (params["applyType"] !== undefined) {
      queryParameters["applyType"] = params["applyType"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 残联驳回器具适配申请
   * @method
   * @name backApply
   */
  backApply: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/back/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    if (params["reason"] !== undefined) {
      queryParameters["reason"] = params["reason"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 残联取消器具适配申请
   * @method
   * @name cancelApply
   */
  cancelApply: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/cancel/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    if (params["reason"] !== undefined) {
      queryParameters["reason"] = params["reason"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据申请的id查询详情
   * @method
   * @name detailByApplyId
   */
  detailByApplyId: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/detail/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据活动id导出辅助器具申请列表
   * @method
   * @name exportApplyUserInfoById
   */
  exportApplyUserInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/excel/userList/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    if (params["disabledTypeId"] !== undefined) {
      queryParameters["disabledTypeId"] = params["disabledTypeId"]
    }

    if (params["cid"] !== undefined) {
      queryParameters["cid"] = params["cid"]
    }

    if (params["userName"] !== undefined) {
      queryParameters["userName"] = params["userName"]
    }

    if (params["region"] !== undefined) {
      queryParameters["region"] = params["region"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 鉴定机构驳回器具适配申请
   * @method
   * @name instituteBackApply
   */
  instituteBackApply: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/institute/back/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 鉴定机构现场拍照和图文记录
   * @method
   * @name updateInstitutePhotoAndRecord
   */
  updateInstitutePhotoAndRecord: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/institute/photo/record/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改辅助器局适配的服务商信息
   * @method
   * @name updateApplyInstituteById
   */
  updateApplyInstituteById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/institute/{applyId}/{instituteId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 服务商现场拍照和图文记录
   * @method
   * @name updatePhotoAndRecord
   */
  updatePhotoAndRecord: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/photo/record/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据服务商id查询申请列表（分页）
   * @method
   * @name getServicePageList
   */
  getServicePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/serverList"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keyword"] !== undefined) {
      queryParameters["keyword"] = params["keyword"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据申请id删除辅助器具申请记录
   * @method
   * @name deleteAssistEquApplyInfoById
   */
  deleteAssistEquApplyInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/apply/info/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询辅助器具列表（分页）
   * @method
   * @name getAssistEquInfoPageList
   */
  getAssistEquInfoPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["assistEquName"] !== undefined) {
      queryParameters["assistEquName"] = params["assistEquName"]
    }

    if (params["disabledTypeId"] !== undefined) {
      queryParameters["disabledTypeId"] = params["disabledTypeId"]
    }

    if (params["cid"] !== undefined) {
      queryParameters["cid"] = params["cid"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改辅助器具
   * @method
   * @name addOrUpdateAssistEquInfo
   */
  addOrUpdateAssistEquInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询辅助器具的信息
   * @method
   * @name getAssistEquInfoById
   */
  getAssistEquInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/info/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除辅助器具
   * @method
   * @name deleteAssistEquInfoById
   */
  deleteAssistEquInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/assist/equ/info/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'DELETE',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据残疾类别的id绑定器具分类
   * @method
   * @name bindDisabledTypeAssistCategoryById
   */
  bindDisabledTypeAssistCategoryById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/disabled/type/assist/category/bind"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据残疾类别的id查询其绑定的器具分类
   * @method
   * @name getDisabledTypeAssistCategoryByTypeId
   */
  getDisabledTypeAssistCategoryByTypeId: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/disabled/type/assist/category/{disabledTypeId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{disabledTypeId}", params["disabledTypeId"])

    if (params["disabledTypeId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: disabledTypeId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据残疾类别的id查询其下所有的服务商
   * @method
   * @name getServiceDisabledTypeById
   */
  getServiceDisabledTypeById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/service/disabled/type/bind/{disabledTypeId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{disabledTypeId}", params["disabledTypeId"])

    if (params["disabledTypeId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: disabledTypeId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增残疾类别和服务商的关联关系
   * @method
   * @name bindServiceDisabledType
   */
  bindServiceDisabledType: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/service/disabled/type/bind/{disabledTypeId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{disabledTypeId}", params["disabledTypeId"])

    if (params["disabledTypeId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: disabledTypeId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据残疾类别的id查询其下可(未)绑定的所有的服务商
   * @method
   * @name getUnbindServiceDisabledTypeById
   */
  getUnbindServiceDisabledTypeById: (params = {}) => {

    let domain = window.api.domain
    let path = "/bak/service/disabled/type/unbind/{disabledTypeId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{disabledTypeId}", params["disabledTypeId"])

    if (params["disabledTypeId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: disabledTypeId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询分类信息列表（分页）
   * @method
   * @name getCategoryPageList
   */
  getCategoryPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/category"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["parentId"] !== undefined) {
      queryParameters["parentId"] = params["parentId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改分类信息
   * @method
   * @name addOrUpdateCategory
   */
  addOrUpdateCategory: (params = {}) => {

    let domain = window.api.domain
    let path = "/category"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询分类信息列表
   * @method
   * @name getCategoryList
   */
  getCategoryList: (params = {}) => {

    let domain = window.api.domain
    let path = "/category/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["parentId"] !== undefined) {
      queryParameters["parentId"] = params["parentId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询分类信息树状结构
   * @method
   * @name getCategoryTree
   */
  getCategoryTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/category/tree"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["parentId"] !== undefined) {
      queryParameters["parentId"] = params["parentId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询分类信息
   * @method
   * @name getCategoryById
   */
  getCategoryById: (params = {}) => {

    let domain = window.api.domain
    let path = "/category/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除分类信息
   * @method
   * @name deleteCategoryById
   */
  deleteCategoryById: (params = {}) => {

    let domain = window.api.domain
    let path = "/category/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询CommonLanguage列表（分页）
   * @method
   * @name getCommonLanguagePageList
   */
  getCommonLanguagePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/language"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改CommonLanguage
   * @method
   * @name addOrUpdateCommonLanguage
   */
  addOrUpdateCommonLanguage: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/language"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询常用语全部列表
   * @method
   * @name getCommonLanguageList
   */
  getCommonLanguageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/language/all"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询CommonLanguage
   * @method
   * @name getCommonLanguageById
   */
  getCommonLanguageById: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/language/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除CommonLanguage
   * @method
   * @name deleteCommonLanguageById
   */
  deleteCommonLanguageById: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/language/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询常用工具列表（分页）
   * @method
   * @name getCommonToolPageList
   */
  getCommonToolPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/tool"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["position"] !== undefined) {
      queryParameters["position"] = params["position"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改常用工具
   * @method
   * @name addOrUpdateCommonTool
   */
  addOrUpdateCommonTool: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/tool"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询常用工具列表
   * @method
   * @name getCommonToolList
   */
  getCommonToolList: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/tool/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["position"] !== undefined) {
      queryParameters["position"] = params["position"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询常用工具
   * @method
   * @name getCommonToolById
   */
  getCommonToolById: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/tool/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除常用工具
   * @method
   * @name deleteCommonToolById
   */
  deleteCommonToolById: (params = {}) => {

    let domain = window.api.domain
    let path = "/common/tool/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询数据字典列表（分页）
   * @method
   * @name getSystemDictionaryPageList
   */
  getSystemDictionaryPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/dictionary"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keyword"] !== undefined) {
      queryParameters["keyword"] = params["keyword"]
    }

    if (params["parentId"] !== undefined) {
      queryParameters["parentId"] = params["parentId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改数据字典
   * @method
   * @name addOrUpdateSystemDictionary
   */
  addOrUpdateSystemDictionary: (params = {}) => {

    let domain = window.api.domain
    let path = "/dictionary"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据数据字典表生成枚举类
   * @method
   * @name generator
   */
  generator: (params = {}) => {

    let domain = window.api.domain
    let path = "/dictionary/generator"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据父级ID查询全部下级数据字典
   * @method
   * @name dictionaryParentId
   */
  dictionaryParentId: (params = {}) => {

    let domain = window.api.domain
    let path = "/dictionary/parent/{parentId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{parentId}", params["parentId"])

    if (params["parentId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: parentId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 数据字典树形结构
   * @method
   * @name dictionaryTree
   */
  dictionaryTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/dictionary/tree/{parentId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{parentId}", params["parentId"])

    if (params["parentId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: parentId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询数据字典
   * @method
   * @name getSystemDictionaryById
   */
  getSystemDictionaryById: (params = {}) => {

    let domain = window.api.domain
    let path = "/dictionary/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除数据字典
   * @method
   * @name deleteSystemDictionaryById
   */
  deleteSystemDictionaryById: (params = {}) => {

    let domain = window.api.domain
    let path = "/dictionary/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询DingApp列表（分页）
   * @method
   * @name getDingAppPageList
   */
  getDingAppPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/app"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改DingApp
   * @method
   * @name addOrUpdateDingApp
   */
  addOrUpdateDingApp: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/app"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 上传媒体文件到钉钉(供消息提示时的图片展示使用)
   * @method
   * @name mediaUpload
   */
  mediaUpload: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/app/media/upload"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 发送钉钉消息
   * @method
   * @name sendDingMsg
   */
  sendDingMsg: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/app/message"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户扫码登录
   * @method
   * @name qrCodeLogin
   */
  qrCodeLogin: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/app/qrCode/login"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["code"] !== undefined) {
      queryParameters["code"] = params["code"]
    }

    if (params["state"] !== undefined) {
      queryParameters["state"] = params["state"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询DingApp
   * @method
   * @name getDingAppById
   */
  getDingAppById: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/app/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除DingApp
   * @method
   * @name deleteDingAppById
   */
  deleteDingAppById: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/app/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询DingDepartment列表（分页）
   * @method
   * @name getDingDepartmentPageList
   */
  getDingDepartmentPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/department"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改DingDepartment
   * @method
   * @name addOrUpdateDingDepartment
   */
  addOrUpdateDingDepartment: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/department"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询DingDepartment
   * @method
   * @name getDingDepartmentById
   */
  getDingDepartmentById: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/department/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除DingDepartment
   * @method
   * @name deleteDingDepartmentById
   */
  deleteDingDepartmentById: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/department/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询DingSyncLog列表（分页）
   * @method
   * @name getDingSyncLogPageList
   */
  getDingSyncLogPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/sync/log"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改DingSyncLog
   * @method
   * @name addOrUpdateDingSyncLog
   */
  addOrUpdateDingSyncLog: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/sync/log"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询DingSyncLog
   * @method
   * @name getDingSyncLogById
   */
  getDingSyncLogById: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/sync/log/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除DingSyncLog
   * @method
   * @name deleteDingSyncLogById
   */
  deleteDingSyncLogById: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/sync/log/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询DingUser列表（分页）
   * @method
   * @name getDingUserPageList
   */
  getDingUserPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改DingUser
   * @method
   * @name addOrUpdateDingUser
   */
  addOrUpdateDingUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId查询DingUser
   * @method
   * @name getDingUserByUserId
   */
  getDingUserByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/user/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId删除DingUser
   * @method
   * @name deleteDingUserByUserId
   */
  deleteDingUserByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/ding/user/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询督办关系列表（分页）
   * @method
   * @name getDisabledHandleRelationPageList
   */
  getDisabledHandleRelationPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/relation"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["areaName"] !== undefined) {
      queryParameters["areaName"] = params["areaName"]
    }

    if (params["handleName"] !== undefined) {
      queryParameters["handleName"] = params["handleName"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改督办关系
   * @method
   * @name addOrUpdateDisabledHandleRelation
   */
  addOrUpdateDisabledHandleRelation: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/relation"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据区域的id查询督办关系
   * @method
   * @name getDisabledHandleRelationByAreaId
   */
  getDisabledHandleRelationByAreaId: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/relation/area/{areaId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{areaId}", params["areaId"])

    if (params["areaId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: areaId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询所有用户列表（分页）
   * @method
   * @name getDisabledHandleRelationPageList_1
   */
  getDisabledHandleRelationPageList_1: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/relation/user/show"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["mobile"] !== undefined) {
      queryParameters["mobile"] = params["mobile"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    if (params["userRole"] !== undefined) {
      queryParameters["userRole"] = params["userRole"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询督办关系
   * @method
   * @name getDisabledHandleRelationById
   */
  getDisabledHandleRelationById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/relation/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除督办关系
   * @method
   * @name deleteDisabledHandleRelationById
   */
  deleteDisabledHandleRelationById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/relation/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改督办任务
   * @method
   * @name addOrUpdateDisabledHandleTask
   */
  addOrUpdateDisabledHandleTask: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 区级查询督办任务列表（分页）
   * @method
   * @name getDisabledHandleTaskPageList
   */
  getDisabledHandleTaskPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/area/all"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据督办任务ID导出详情
   * @method
   * @name getDisabledHandleTaskByTownPageList_1
   */
  getDisabledHandleTaskByTownPageList_1: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/export/task/info/byId/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 年终汇总督办任务分数数据
   * @method
   * @name getHandleTaskGroupById
   */
  getHandleTaskGroupById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/group/task/info/byId/{yearly}/{cate}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{yearly}", params["yearly"])

    if (params["yearly"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: yearly"))
    }

    path = path.replace("{cate}", params["cate"])

    if (params["cate"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: cate"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * (区级查询)根据任务ID查询督办详情列表（分页）
   * @method
   * @name getDisabledHandleTaskInfoPageList
   */
  getDisabledHandleTaskInfoPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/info/byTaskId"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["taskId"] !== undefined) {
      queryParameters["taskId"] = params["taskId"]
    }

    if (params["taskId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: taskId"))
    }

    if (params["areaName"] !== undefined) {
      queryParameters["areaName"] = params["areaName"]
    }

    if (params["handleName"] !== undefined) {
      queryParameters["handleName"] = params["handleName"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * (APP端自己查询)查询所有督办详情列表（分页）
   * @method
   * @name getDisabledHandleTaskInfoBySelfPageList
   */
  getDisabledHandleTaskInfoBySelfPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/info/byUserId"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["taskInfoState"] !== undefined) {
      queryParameters["taskInfoState"] = params["taskInfoState"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 区级用于督办下级完成任务
   * @method
   * @name handleTaskInfoById
   */
  handleTaskInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/info/handle/{taskInfoId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{taskInfoId}", params["taskInfoId"])

    if (params["taskInfoId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: taskInfoId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * (镇级账号查询)查询某一个督办详情列表（分页）
   * @method
   * @name getDisabledHandleTaskInfoByTownPageList
   */
  getDisabledHandleTaskInfoByTownPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/info/town/byParams"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["taskId"] !== undefined) {
      queryParameters["taskId"] = params["taskId"]
    }

    if (params["handleName"] !== undefined) {
      queryParameters["handleName"] = params["handleName"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据详情主键ID查询任务详情
   * @method
   * @name getOneDisabledHandleTaskInfoById
   */
  getOneDisabledHandleTaskInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/info/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取系统最大分打分规则设置
   * @method
   * @name getHandleMaxScoreInfo
   */
  getHandleMaxScoreInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/maxScore"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取扣分记录详情
   * @method
   * @name getHandleRemoveScoreInfoRecord
   */
  getHandleRemoveScoreInfoRecord: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/removeScore/mark/byInfoId"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["taskInfoId"] !== undefined) {
      queryParameters["taskInfoId"] = params["taskInfoId"]
    }

    if (params["taskInfoId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: taskInfoId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 区级加分
   * @method
   * @name updateInfoAreaAddScoreById
   */
  updateInfoAreaAddScoreById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/update/add/score/area"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["addScore"] !== undefined) {
      queryParameters["addScore"] = params["addScore"]
    }

    if (params["addScore"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: addScore"))
    }

    if (params["addScoreMark"] !== undefined) {
      queryParameters["addScoreMark"] = params["addScoreMark"]
    }

    if (params["addScoreMark"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: addScoreMark"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 区级扣分
   * @method
   * @name updateInfoAreaRemoveScoreById
   */
  updateInfoAreaRemoveScoreById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/update/remove/score/area"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["removeScore"] !== undefined) {
      queryParameters["removeScore"] = params["removeScore"]
    }

    if (params["removeScore"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: removeScore"))
    }

    if (params["removeScoreMark"] !== undefined) {
      queryParameters["removeScoreMark"] = params["removeScoreMark"]
    }

    if (params["removeScoreMark"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: removeScoreMark"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 区级打分
   * @method
   * @name updateInfoAreaScoreById
   */
  updateInfoAreaScoreById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/update/score/area"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["areaScore"] !== undefined) {
      queryParameters["areaScore"] = params["areaScore"]
    }

    if (params["areaScore"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: areaScore"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 镇级打分
   * @method
   * @name updateInfoTownScoreById
   */
  updateInfoTownScoreById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/update/score/town"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["townScore"] !== undefined) {
      queryParameters["townScore"] = params["townScore"]
    }

    if (params["townScore"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: townScore"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 镇级给上传的任务图片打分
   * @method
   * @name updateInfoTownScoreImgUploadById
   */
  updateInfoTownScoreImgUploadById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/update/score/town/imgUpload"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["imgUploadScore"] !== undefined) {
      queryParameters["imgUploadScore"] = params["imgUploadScore"]
    }

    if (params["imgUploadScore"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: imgUploadScore"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 上传图片
   * @method
   * @name uploadImgInfoById
   */
  uploadImgInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/info/upload/img"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 镇级查询督办任务列表（分页）
   * @method
   * @name getDisabledHandleTaskByTownPageList
   */
  getDisabledHandleTaskByTownPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/town/self"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询督办任务
   * @method
   * @name getDisabledHandleTaskById
   */
  getDisabledHandleTaskById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除督办任务
   * @method
   * @name deleteDisabledHandleTaskById
   */
  deleteDisabledHandleTaskById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/handle/task/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人信息列表——管理员用户（分页）
   * @method
   * @name getDisabledPersonPageList
   */
  getDisabledPersonPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["level"] !== undefined) {
      queryParameters["level"] = params["level"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["region"] !== undefined) {
      queryParameters["region"] = params["region"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改残疾人信息
   * @method
   * @name addOrUpdateDisabledPerson
   */
  addOrUpdateDisabledPerson: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人信息列表通过地域——非管理员用户（分页）
   * @method
   * @name getPersonPageListByArea
   */
  getPersonPageListByArea: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/area"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["level"] !== undefined) {
      queryParameters["level"] = params["level"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["region"] !== undefined) {
      queryParameters["region"] = params["region"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 
   * @method
   * @name bindDisadled
   */
  bindDisadled: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/bind/{disabledCard}/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{disabledCard}", params["disabledCard"])

    if (params["disabledCard"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: disabledCard"))
    }

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据当前用户社区/村编码查询残疾人集合
   * @method
   * @name getByCommittee
   */
  getByCommittee: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/by"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据(身份证号/残疾证号)和(姓名)查询是否有该残疾人-(残疾人PC端)
   * @method
   * @name getDisabledPersonByDisabledCard
   */
  getDisabledPersonByDisabledCard: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/card/{card}/{name}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{card}", params["card"])

    if (params["card"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: card"))
    }

    path = path.replace("{name}", params["name"])

    if (params["name"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: name"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询当前组织架构的残疾人数量
   * @method
   * @name getCount
   */
  getCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/count"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 生成残疾人账户信息
   * @method
   * @name createUser
   */
  createUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/create"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人信息列表——管理员用户导出
   * @method
   * @name exportDisabledPersonList
   */
  exportDisabledPersonList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/export"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["level"] !== undefined) {
      queryParameters["level"] = params["level"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["region"] !== undefined) {
      queryParameters["region"] = params["region"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改残疾人监护人信息
   * @method
   * @name updateGuardian
   */
  updateGuardian: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/guardian"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 导入残疾人信息(新)
   * @method
   * @name importDisabledPersonByExcel
   */
  importDisabledPersonByExcel: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/import/excel"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询首页折线图数据
   * @method
   * @name getLineData
   */
  getLineData: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/line"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据地区code查询下级的残疾人数数量
   * @method
   * @name getCountByAreaCode
   */
  getCountByAreaCode: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/ratio/{areaCode}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{areaCode}", params["areaCode"])

    if (params["areaCode"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: areaCode"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据UserId查询残疾人信息
   * @method
   * @name getDisabledPersonByUserId
   */
  getDisabledPersonByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/userId/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人享受的福利待遇列表（分页）
   * @method
   * @name getDisabledPersonWealPageList
   */
  getDisabledPersonWealPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/weal"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改残疾人享受的福利待遇
   * @method
   * @name addOrUpdateDisabledPersonWeal
   */
  addOrUpdateDisabledPersonWeal: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/weal"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询所有残疾人享受的福利待遇列表（分页）
   * @method
   * @name getAllDisabledPersonWealPageList
   */
  getAllDisabledPersonWealPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/weal/all"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["year"] !== undefined) {
      queryParameters["year"] = params["year"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 导出所有残疾人享受的福利待遇列表
   * @method
   * @name exportDisabledPersonWealExcel
   */
  exportDisabledPersonWealExcel: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/weal/export"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["year"] !== undefined) {
      queryParameters["year"] = params["year"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询残疾人享受的福利待遇
   * @method
   * @name getDisabledPersonWealById
   */
  getDisabledPersonWealById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/weal/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除残疾人享受的福利待遇
   * @method
   * @name deleteDisabledPersonWealById
   */
  deleteDisabledPersonWealById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/weal/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询残疾人信息
   * @method
   * @name getDisabledPersonById
   */
  getDisabledPersonById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除残疾人信息
   * @method
   * @name deleteDisabledPersonById
   */
  deleteDisabledPersonById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/person/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询保障金任务列表（分页）
   * @method
   * @name getDisabledSafeguardMoneyTaskPageList
   */
  getDisabledSafeguardMoneyTaskPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改保障金任务
   * @method
   * @name addOrUpdateDisabledSafeguardMoneyTask
   */
  addOrUpdateDisabledSafeguardMoneyTask: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 添加腾讯云用户
   * @method
   * @name addTencentUser
   */
  addTencentUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/add/tencent/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 审核保障金任务
   * @method
   * @name appraisalDisabledSafeguardMoneyTask_1
   */
  appraisalDisabledSafeguardMoneyTask_1: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/appraisal"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    if (params["state"] !== undefined) {
      queryParameters["state"] = params["state"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 腾讯云检验用户是否存在
   * @method
   * @name getCheckUserByIds
   */
  getCheckUserByIds: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/get/checkUser"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 腾讯云获取腾讯云SDKAppID
   * @method
   * @name getTencentSDKAppId
   */
  getTencentSDKAppId: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/get/sdkAppId"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 腾讯云获取某用户的UserSig
   * @method
   * @name getTencentUserSig
   */
  getTencentUserSig: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/get/userSig"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的保障金任务列表（分页）
   * @method
   * @name getDisabledSafeguardMoneyTaskByUserIdPageList
   */
  getDisabledSafeguardMoneyTaskByUserIdPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/myself"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取个人最新的被审核的上传的视频详情
   * @method
   * @name appraisalDisabledSafeguardMoneyTask
   */
  appraisalDisabledSafeguardMoneyTask: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/myself/task/video/info"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 设置已经电话通知
   * @method
   * @name updateMoneyTaskPhoneStateById
   */
  updateMoneyTaskPhoneStateById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/update/phone/state"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["id"] !== undefined) {
      queryParameters["id"] = params["id"]
    }

    if (params["state"] !== undefined) {
      queryParameters["state"] = params["state"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询保障金任务
   * @method
   * @name getDisabledSafeguardMoneyTaskById
   */
  getDisabledSafeguardMoneyTaskById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除保障金任务
   * @method
   * @name deleteDisabledSafeguardMoneyTaskById
   */
  deleteDisabledSafeguardMoneyTaskById: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/task/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取当前模版数据
   * @method
   * @name getHandleTemplateByUd
   */
  getHandleTemplateByUd: (params = {}) => {

    let domain = window.api.domain
    let path = "/disabled/safeguard/money/template/get/info"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询共享文档/资料列表（分页）
   * @method
   * @name getDocumentPageList
   */
  getDocumentPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/document"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["myCreate"] !== undefined) {
      queryParameters["myCreate"] = params["myCreate"]
    }

    if (params["deleteFlag"] !== undefined) {
      queryParameters["deleteFlag"] = params["deleteFlag"]
    }

    if (params["myAccept"] !== undefined) {
      queryParameters["myAccept"] = params["myAccept"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    if (params["readStatus"] !== undefined) {
      queryParameters["readStatus"] = params["readStatus"]
    }

    if (params["year"] !== undefined) {
      queryParameters["year"] = params["year"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改共享文档/资料
   * @method
   * @name addOrUpdateDocument
   */
  addOrUpdateDocument: (params = {}) => {

    let domain = window.api.domain
    let path = "/document"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 重新发起审核/直接发布
   * @method
   * @name againDocument
   */
  againDocument: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/again"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 审核共享文档/资料
   * @method
   * @name auditDocument
   */
  auditDocument: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/audit"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 共享文档数据统计
   * @method
   * @name getDocumentCount
   */
  getDocumentCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/count"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id处理阅读点赞次数
   * @method
   * @name addCount
   */
  addCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/count/{id}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 通过文档来获取文件,id为文件id
   * @method
   * @name getDownloadFile
   */
  getDownloadFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/download/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/octet-stream'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 共享文档个人分类统计
   * @method
   * @name getMyDocumentCategoryCount
   */
  getMyDocumentCategoryCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/mine/category/count"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 共享文档个人数据统计
   * @method
   * @name getDocumentMineCount
   */
  getDocumentMineCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/mine/count"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 共享文档阅读热度排行,(前10条)
   * @method
   * @name getDocumentReadHot
   */
  getDocumentReadHot: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/read/hot"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询DocumentReceiver列表（分页）
   * @method
   * @name getDocumentReceiverPageList
   */
  getDocumentReceiverPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/receiver"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改DocumentReceiver
   * @method
   * @name addOrUpdateDocumentReceiver
   */
  addOrUpdateDocumentReceiver: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/receiver"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询DocumentReceiver
   * @method
   * @name getDocumentReceiverById
   */
  getDocumentReceiverById: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/receiver/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除DocumentReceiver
   * @method
   * @name deleteDocumentReceiverById
   */
  deleteDocumentReceiverById: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/receiver/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询共享文档/资料
   * @method
   * @name getDocumentById
   */
  getDocumentById: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id永久删除共享文档/资料
   * @method
   * @name deleteDocumentById
   */
  deleteDocumentById: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/{id}/delete"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除共享文档/资料
   * @method
   * @name deleteLogicDocumentById
   */
  deleteLogicDocumentById: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/{id}/delete/logic"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id恢复共享文档/资料
   * @method
   * @name recoverDocumentById
   */
  recoverDocumentById: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/{id}/recover"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 提醒接收公文
   * @method
   * @name remindDocumentReceiver
   */
  remindDocumentReceiver: (params = {}) => {

    let domain = window.api.domain
    let path = "/document/{id}/remind"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取工作流表单内文件,返回文件流
   * @method
   * @name getSpecialFile_1
   */
  getSpecialFile_1: (params = {}) => {

    let domain = window.api.domain
    let path = "/download/pdf/special/file/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["waterMark"] !== undefined) {
      queryParameters["waterMark"] = params["waterMark"]
    }

    if (params["waterText"] !== undefined) {
      queryParameters["waterText"] = params["waterText"]
    }

    if (params["isLabel"] !== undefined) {
      queryParameters["isLabel"] = params["isLabel"]
    }

    if (params["labelText"] !== undefined) {
      queryParameters["labelText"] = params["labelText"]
    }

    if (params["labelX"] !== undefined) {
      queryParameters["labelX"] = params["labelX"]
    }

    if (params["labelY"] !== undefined) {
      queryParameters["labelY"] = params["labelY"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询模板导出配置列表（分页）
   * @method
   * @name getExportSettingPageList
   */
  getExportSettingPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/export/setting"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["tableName"] !== undefined) {
      queryParameters["tableName"] = params["tableName"]
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改模板导出配置
   * @method
   * @name addOrUpdateExportSetting
   */
  addOrUpdateExportSetting: (params = {}) => {

    let domain = window.api.domain
    let path = "/export/setting"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询模板导出配置列表（不分页）
   * @method
   * @name getExportSettingList
   */
  getExportSettingList: (params = {}) => {

    let domain = window.api.domain
    let path = "/export/setting/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["tableName"] !== undefined) {
      queryParameters["tableName"] = params["tableName"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询模板导出配置
   * @method
   * @name getExportSettingById
   */
  getExportSettingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/export/setting/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除模板导出配置
   * @method
   * @name deleteExportSettingById
   */
  deleteExportSettingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/export/setting/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人器具适配活动的列表（分页）
   * @method
   * @name getForeAssistActivityInfoPageList
   */
  getForeAssistActivityInfoPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/activity/info/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询服务商残疾人器具适配活动的列表（分页）
   * @method
   * @name getForeAssistActivityInfoPageListByService
   */
  getForeAssistActivityInfoPageListByService: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/activity/info/list/service"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的申请 器具适配列表（分页）
   * @method
   * @name getAssistEquApplyInfoPageList
   */
  getAssistEquApplyInfoPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/equ/apply/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 服务商器具适配申请
   * @method
   * @name applyServerAssistEqu
   */
  applyServerAssistEqu: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/equ/apply/info/apply/server/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 器具适配申请
   * @method
   * @name applyAssistEqu
   */
  applyAssistEqu: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/equ/apply/info/apply/{activityId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{activityId}", params["activityId"])

    if (params["activityId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: activityId"))
    }

    if (params["interFaceCate"] !== undefined) {
      queryParameters["interFaceCate"] = params["interFaceCate"]
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人器具适配记录（分页）
   * @method
   * @name getAssistEquApplyInfoDetailList
   */
  getAssistEquApplyInfoDetailList: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/equ/apply/info/detail"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据申请的id查询辅助器具申请的详情
   * @method
   * @name getAssistEquApplyInfoById
   */
  getAssistEquApplyInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/equ/apply/info/detail/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 首页查询辅助器具申请数量
   * @method
   * @name getAssistDataByHome
   */
  getAssistDataByHome: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/equ/apply/info/home"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据当前活动下的残疾人查询可以选择的的辅助器具或基本服务分类
   * @method
   * @name getAssistEqusByDisabledTypeIdAndCid
   */
  getAssistEqusByDisabledTypeIdAndCid: (params = {}) => {

    let domain = window.api.domain
    let path = "/fore/assist/equ/info/get/disabledType/category/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 残疾人查询我的来信列表（分页）
   * @method
   * @name getLetterPageList
   */
  getLetterPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/letter"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 残疾人新增来信(我要写信)
   * @method
   * @name addLetterBaseInfo
   */
  addLetterBaseInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/letter"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据来信的id查询残疾人来信的详情
   * @method
   * @name getLetterById
   */
  getLetterById: (params = {}) => {

    let domain = window.api.domain
    let path = "/letter/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询短信模板设置列表（分页）
   * @method
   * @name getMessageTemplatePageList
   */
  getMessageTemplatePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/template"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改短信模板设置
   * @method
   * @name addOrUpdateMessageTemplate
   */
  addOrUpdateMessageTemplate: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/template"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询短信模板设置
   * @method
   * @name getMessageTemplateById
   */
  getMessageTemplateById: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/template/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除短信模板设置
   * @method
   * @name deleteMessageTemplateById
   */
  deleteMessageTemplateById: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/template/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询当前用户的未读消息列表（分页）
   * @method
   * @name getMessageUserPageList
   */
  getMessageUserPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询当前用户消息未读总数量(红点数量提示)
   * @method
   * @name getMessageUserCount
   */
  getMessageUserCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/user/count"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 一键已读
   * @method
   * @name updateMessageUserStatus
   */
  updateMessageUserStatus: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/user/read"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户根据消息id查询消息提醒详情
   * @method
   * @name getMessageUserById
   */
  getMessageUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/message/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询模块用户设置列表（分页）
   * @method
   * @name getModuleSettingPageList
   */
  getModuleSettingPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/module/setting"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改模块用户设置
   * @method
   * @name addOrUpdateModuleSetting
   */
  addOrUpdateModuleSetting: (params = {}) => {

    let domain = window.api.domain
    let path = "/module/setting"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询模块用户设置
   * @method
   * @name getModuleSettingById
   */
  getModuleSettingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/module/setting/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除模块用户设置
   * @method
   * @name deleteModuleSettingById
   */
  deleteModuleSettingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/module/setting/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询模块用户设置
   * @method
   * @name getModuleSettingByKey
   */
  getModuleSettingByKey: (params = {}) => {

    let domain = window.api.domain
    let path = "/module/setting/{key}/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{key}", params["key"])

    if (params["key"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: key"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文章信息列表（分页）
   * @method
   * @name getNewsArticlePageList
   */
  getNewsArticlePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["itemId"] !== undefined) {
      queryParameters["itemId"] = params["itemId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改文章信息
   * @method
   * @name addOrUpdateNewsArticle
   */
  addOrUpdateNewsArticle: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 审核文章
   * @method
   * @name auditArticle
   */
  auditArticle: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article/audit"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 复制文章
   * @method
   * @name copyArticle
   */
  copyArticle: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article/copy"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文章信息列表——无鉴权（分页）
   * @method
   * @name getNewsArticlePage
   */
  getNewsArticlePage: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["itemId"] !== undefined) {
      queryParameters["itemId"] = params["itemId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 置顶文章
   * @method
   * @name topId
   */
  topId: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article/top/{id}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询文章信息
   * @method
   * @name getNewsArticleById
   */
  getNewsArticleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除文章信息
   * @method
   * @name deleteNewsArticleById
   */
  deleteNewsArticleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/article/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文章栏目列表（分页）
   * @method
   * @name getNewsItemPageList
   */
  getNewsItemPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改文章栏目
   * @method
   * @name addOrUpdateNewsItem
   */
  addOrUpdateNewsItem: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文章栏目列表——无鉴权（分页）
   * @method
   * @name getNewsItemPage
   */
  getNewsItemPage: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文章栏目列表(不分页)
   * @method
   * @name getArticleList
   */
  getArticleList: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/list/{keywords}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询栏目管理用户列表（分页）
   * @method
   * @name getNewsItemUserPageList
   */
  getNewsItemUserPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改栏目管理用户
   * @method
   * @name addOrUpdateNewsItemUser
   */
  addOrUpdateNewsItemUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询角色栏目关联信息
   * @method
   * @name getRoleList
   */
  getRoleList: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/user/ids/{roleId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["roleId"] !== undefined) {
      queryParameters["roleId"] = params["roleId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 角色栏目授权
   * @method
   * @name saveNewsItemRole
   */
  saveNewsItemRole: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/user/role/save"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询栏目管理用户
   * @method
   * @name getNewsItemUserById
   */
  getNewsItemUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除栏目管理用户
   * @method
   * @name deleteNewsItemUserById
   */
  deleteNewsItemUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询文章栏目
   * @method
   * @name getNewsItemById
   */
  getNewsItemById: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除文章栏目
   * @method
   * @name deleteNewsItemById
   */
  deleteNewsItemById: (params = {}) => {

    let domain = window.api.domain
    let path = "/news/item/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询选项列表（分页）
   * @method
   * @name getOptionPageList
   */
  getOptionPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/option"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改选项
   * @method
   * @name addOrUpdateOption_1
   */
  addOrUpdateOption_1: (params = {}) => {

    let domain = window.api.domain
    let path = "/option"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询选项
   * @method
   * @name getOptionById
   */
  getOptionById: (params = {}) => {

    let domain = window.api.domain
    let path = "/option/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除选项
   * @method
   * @name deleteOptionById
   */
  deleteOptionById: (params = {}) => {

    let domain = window.api.domain
    let path = "/option/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询Organize列表（分页）
   * @method
   * @name getOrganizePageList
   */
  getOrganizePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改Organize
   * @method
   * @name addOrUpdateOrganize
   */
  addOrUpdateOrganize: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除当前部门下的所有信息
   * @method
   * @name deleteOrganizeAllById
   */
  deleteOrganizeAllById: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/all/{id}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 部门列表(懒加载)
   * @method
   * @name getOrganizeLazy
   */
  getOrganizeLazy: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/lazy/{parentId}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{parentId}", params["parentId"])

    if (params["parentId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: parentId"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["queryType"] !== undefined) {
      queryParameters["queryType"] = params["queryType"]
    }

    if (params["flag"] !== undefined) {
      queryParameters["flag"] = params["flag"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId查询OrganizeList
   * @method
   * @name getOrganizeListByUserId
   */
  getOrganizeListByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/mine"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 对当前部门的下级部门进行排序
   * @method
   * @name sortOrg
   */
  sortOrg: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/sort"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 部门列表(树状)
   * @method
   * @name getOrganizeTree
   */
  getOrganizeTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/tree/{parentId}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{parentId}", params["parentId"])

    if (params["parentId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: parentId"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询OrganizeUser列表（分页）
   * @method
   * @name getOrganizeUserPageList
   */
  getOrganizeUserPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改OrganizeUser
   * @method
   * @name addOrUpdateOrganizeUser
   */
  addOrUpdateOrganizeUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户授权部门
   * @method
   * @name grantOrganizeUser
   */
  grantOrganizeUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/user/grant"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 对部门下的用户进行排序
   * @method
   * @name sortOrgUser
   */
  sortOrgUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/user/sort"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询OrganizeUser
   * @method
   * @name getOrganizeUserById
   */
  getOrganizeUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除OrganizeUser
   * @method
   * @name deleteOrganizeUserById
   */
  deleteOrganizeUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询Organize
   * @method
   * @name getOrganizeById
   */
  getOrganizeById: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除Organize(会删除部门用户关系)
   * @method
   * @name deleteOrganizeById
   */
  deleteOrganizeById: (params = {}) => {

    let domain = window.api.domain
    let path = "/organize/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询提示信息配置列表（分页）
   * @method
   * @name getPromptPageList
   */
  getPromptPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/prompt"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改提示信息配置
   * @method
   * @name addOrUpdatePrompt
   */
  addOrUpdatePrompt: (params = {}) => {

    let domain = window.api.domain
    let path = "/prompt"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取全部提示信息
   * @method
   * @name getAllPrompt
   */
  getAllPrompt: (params = {}) => {

    let domain = window.api.domain
    let path = "/prompt/all"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询提示信息配置
   * @method
   * @name getPromptById
   */
  getPromptById: (params = {}) => {

    let domain = window.api.domain
    let path = "/prompt/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除提示信息配置
   * @method
   * @name deletePromptById
   */
  deletePromptById: (params = {}) => {

    let domain = window.api.domain
    let path = "/prompt/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询问题列表（分页）
   * @method
   * @name getQuestionPageList
   */
  getQuestionPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/question"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改问题
   * @method
   * @name addOrUpdateQuestion
   */
  addOrUpdateQuestion: (params = {}) => {

    let domain = window.api.domain
    let path = "/question"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询问题
   * @method
   * @name getQuestionById
   */
  getQuestionById: (params = {}) => {

    let domain = window.api.domain
    let path = "/question/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除问题
   * @method
   * @name deleteQuestionById
   */
  deleteQuestionById: (params = {}) => {

    let domain = window.api.domain
    let path = "/question/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询问卷调查列表（全部-问卷调查分页-后端接口）
   * @method
   * @name getQuestionnairePageList
   */
  getQuestionnairePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改问卷调查
   * @method
   * @name addOrUpdateQuestionnaire
   */
  addOrUpdateQuestionnaire: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 导出当前问卷调查的调查结果(excel)
   * @method
   * @name exportQuestionnaire
   */
  exportQuestionnaire: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire/export"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["questionnaireId"] !== undefined) {
      queryParameters["questionnaireId"] = params["questionnaireId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询已发布和完结的问卷调查列表（全部-问卷调查分页-前端接口）
   * @method
   * @name getPublishQuestionnairePageList
   */
  getPublishQuestionnairePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我参与的问卷调查列表（问卷调查分页-前端接口）
   * @method
   * @name getMinePublishQuestionnairePageList
   */
  getMinePublishQuestionnairePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire/mine"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id修改问卷调查发布状态
   * @method
   * @name updatePublishById
   */
  updatePublishById: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire/publish/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 导出当前问卷调查的调查结果(word)
   * @method
   * @name exportQuestionnaireWord
   */
  exportQuestionnaireWord: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire/word"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["questionnaireId"] !== undefined) {
      queryParameters["questionnaireId"] = params["questionnaireId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询问卷调查
   * @method
   * @name getQuestionnaireById
   */
  getQuestionnaireById: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除问卷调查
   * @method
   * @name deleteQuestionnaireById
   */
  deleteQuestionnaireById: (params = {}) => {

    let domain = window.api.domain
    let path = "/questionnaire/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询儿童康复救助(申请)列表（分页）
   * @method
   * @name getRecoveryPageList
   */
  getRecoveryPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * (申请/驳回再申请/康复机构确认接收)儿童康复救助
   * @method
   * @name addOrUpdateRecovery
   */
  addOrUpdateRecovery: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文章信息列表（分页）
   * @method
   * @name getRecoveryArticlePageList
   */
  getRecoveryArticlePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/article"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改文章信息
   * @method
   * @name addOrUpdateRecoveryArticle
   */
  addOrUpdateRecoveryArticle: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/article"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查询其下分页资讯信息
   * @method
   * @name getAllRecoveryArticle
   */
  getAllRecoveryArticle: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/article/list/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    if (params["itemId"] !== undefined) {
      queryParameters["itemId"] = params["itemId"]
    }

    if (params["startYear"] !== undefined) {
      queryParameters["startYear"] = params["startYear"]
    }

    if (params["endYear"] !== undefined) {
      queryParameters["endYear"] = params["endYear"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询文章信息
   * @method
   * @name getRecoveryArticleById
   */
  getRecoveryArticleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/article/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除文章信息
   * @method
   * @name deleteRecoveryArticleById
   */
  deleteRecoveryArticleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/article/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 康复机构确认接收审核儿童康复救助申请(同意)
   * @method
   * @name instituteAuditRecovery
   */
  instituteAuditRecovery: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/audit/institute/{type}/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 残联审核儿童康复救助申请(同意OR驳回)
   * @method
   * @name auditRecovery
   */
  auditRecovery: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/audit/{type}/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改儿童康复课程
   * @method
   * @name addOrUpdateRecoveryCourse
   */
  addOrUpdateRecoveryCourse: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/course"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询康复课程列表（分页）
   * @method
   * @name getRecoveryCoursePageList
   */
  getRecoveryCoursePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/course/list/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询签到的课程列表（分页）
   * @method
   * @name getNeedSignRecoveryCoursesPageList
   */
  getNeedSignRecoveryCoursesPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/course/sign/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据课程id查询其下所有学员列表（分页）
   * @method
   * @name getAllRecoveryPageListByCourseId
   */
  getAllRecoveryPageListByCourseId: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/course/stu/{courseId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{courseId}", params["courseId"])

    if (params["courseId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: courseId"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询康复课程
   * @method
   * @name getRecoveryCourseById
   */
  getRecoveryCourseById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/course/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除康复课程
   * @method
   * @name deleteRecoveryCourseById
   */
  deleteRecoveryCourseById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/course/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id导出机构管辖的儿童康复救助(申请)列表
   * @method
   * @name exportInstituteRecoveryListByInsId
   */
  exportInstituteRecoveryListByInsId: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/export/ins/{instituteId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    path = path.replace("{instituteId}", params["instituteId"])

    if (params["instituteId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: instituteId"))
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 首页查询儿童康复救助数量
   * @method
   * @name getCountData
   */
  getCountData: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/home"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询当前机构管辖的儿童康复救助(申请)列表（分页）
   * @method
   * @name getInstituteRecoveryPageList
   */
  getInstituteRecoveryPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/ins"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查看机构管辖的儿童康复救助(申请)列表（分页）
   * @method
   * @name getInstituteRecoveryByInsIdPageList
   */
  getInstituteRecoveryByInsIdPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/ins/{instituteId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    path = path.replace("{instituteId}", params["instituteId"])

    if (params["instituteId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: instituteId"))
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询康复机构信息列表（分页-管理）
   * @method
   * @name getRecoveryInstitutePageList
   */
  getRecoveryInstitutePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["recoveryType"] !== undefined) {
      queryParameters["recoveryType"] = params["recoveryType"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查询课时信息
   * @method
   * @name getClassHourInfoInfoByInstitutionId
   */
  getClassHourInfoInfoByInstitutionId: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/course/hour/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    if (params["year"] !== undefined) {
      queryParameters["year"] = params["year"]
    }

    if (params["month"] !== undefined) {
      queryParameters["month"] = params["month"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查询课程教师以及学员信息列表（分页）
   * @method
   * @name getCourseAndStuAndSignInfoPageList
   */
  getCourseAndStuAndSignInfoPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/course/list/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改儿童康复救助机构基础信息
   * @method
   * @name updateRecoveryInstitution
   */
  updateRecoveryInstitution: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/edit"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 机构资金统计-机构列表（分页）
   * @method
   * @name getFundPageList
   */
  getFundPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/fund/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询所有的康复机构康复机构
   * @method
   * @name getRecoveryInstituteList
   */
  getRecoveryInstituteList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/list"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询康复机构-当前康复机构
   * @method
   * @name getMineRecoveryInstitute
   */
  getMineRecoveryInstitute: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/mine"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据学员id查询学员课时信息
   * @method
   * @name getStuHourInfoInfoByApplyId
   */
  getStuHourInfoInfoByApplyId: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/stu/hour/{applyId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{applyId}", params["applyId"])

    if (params["applyId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: applyId"))
    }

    if (params["year"] !== undefined) {
      queryParameters["year"] = params["year"]
    }

    if (params["month"] !== undefined) {
      queryParameters["month"] = params["month"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查询学员信息列表
   * @method
   * @name getStusPageListByInstitutionId
   */
  getStusPageListByInstitutionId: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/stu/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["age"] !== undefined) {
      queryParameters["age"] = params["age"]
    }

    if (params["recoveryTypeName"] !== undefined) {
      queryParameters["recoveryTypeName"] = params["recoveryTypeName"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据用户id查询儿童康复救助机构信息
   * @method
   * @name deleteRecoveryInstitutionById
   */
  deleteRecoveryInstitutionById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/userId"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询康复机构信息
   * @method
   * @name getRecoveryInstituteById
   */
  getRecoveryInstituteById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/institute/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询文章栏目列表（分页）
   * @method
   * @name getRecoveryItemPageList
   */
  getRecoveryItemPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/item"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改文章栏目
   * @method
   * @name addOrUpdateRecoveryItem
   */
  addOrUpdateRecoveryItem: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/item"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查询其下所有文章栏目
   * @method
   * @name getAllRecoveryItem
   */
  getAllRecoveryItem: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/item/list/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除文章栏目
   * @method
   * @name deleteRecoveryItemById
   */
  deleteRecoveryItemById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/item/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询儿童的康复救助列表（分页）
   * @method
   * @name getMineRecoveryPageList
   */
  getMineRecoveryPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/mine"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查询其下的所有学员
   * @method
   * @name getRecoveryReceivers
   */
  getRecoveryReceivers: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/receive/all/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询当前用户是否有儿童救助的申请
   * @method
   * @name haveApplied
   */
  haveApplied: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/receive/has"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据申请id和机构id更新机构下学员的服务状态
   * @method
   * @name updateRecoveryReceiveServeStatus
   */
  updateRecoveryReceiveServeStatus: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/receive/serveStatus/{id}/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    if (params["serverStatus"] !== undefined) {
      queryParameters["serverStatus"] = params["serverStatus"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询儿童康复救助申请列表（分页）
   * @method
   * @name getRecoveryReceivePageList
   */
  getRecoveryReceivePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/receive/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询康复打卡记录列表（分页）
   * @method
   * @name getRecoveryRecordPageList
   */
  getRecoveryRecordPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/record"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["instituteId"] !== undefined) {
      queryParameters["instituteId"] = params["instituteId"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改康复打卡记录
   * @method
   * @name addOrUpdateRecoveryRecord
   */
  addOrUpdateRecoveryRecord: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/record"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 课程签到
   * @method
   * @name signRecoveryCourse
   */
  signRecoveryCourse: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/sign/record/sign/{courseId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{courseId}", params["courseId"])

    if (params["courseId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: courseId"))
    }

    if (params["signInfo"] !== undefined) {
      queryParameters["signInfo"] = params["signInfo"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据课程id查询康复课程确认详情
   * @method
   * @name getRecoveryCourseByApplyUserIdAndId
   */
  getRecoveryCourseByApplyUserIdAndId: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/sign/record/{courseId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{courseId}", params["courseId"])

    if (params["courseId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: courseId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改教师
   * @method
   * @name addOrUpdateRecoveryTeacher
   */
  addOrUpdateRecoveryTeacher: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/teacher"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据机构id查询机构其下所有教师
   * @method
   * @name getRecoveryTeacherList
   */
  getRecoveryTeacherList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/teacher/all/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询教师列表（分页）
   * @method
   * @name getRecoveryTeacherPageList
   */
  getRecoveryTeacherPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/teacher/page/{institutionId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{institutionId}", params["institutionId"])

    if (params["institutionId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: institutionId"))
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询教师
   * @method
   * @name getRecoveryTeacherById
   */
  getRecoveryTeacherById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/teacher/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除教师
   * @method
   * @name deleteRecoveryTeacherById
   */
  deleteRecoveryTeacherById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/teacher/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询儿童康复救助(申请)
   * @method
   * @name getRecoveryById
   */
  getRecoveryById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除儿童康复救助(申请)
   * @method
   * @name deleteRecoveryById
   */
  deleteRecoveryById: (params = {}) => {

    let domain = window.api.domain
    let path = "/recovery/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询无障碍改造主数据列表（分页）
   * @method
   * @name getRemouldPageList
   */
  getRemouldPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 发布无障碍改造任务
   * @method
   * @name addOrUpdateRemould
   */
  addOrUpdateRemould: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 审批通过
   * @method
   * @name approve
   */
  approve: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/approve"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询无障碍改造任务下所有数据
   * @method
   * @name getRemouldDatas
   */
  getRemouldDatas: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/by/{personId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{personId}", params["personId"])

    if (params["personId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: personId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 财政手续确认
   * @method
   * @name updateFinance
   */
  updateFinance: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/finance"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      form["remouldId"] = params["remouldId"]
    }

    if (params["filePath"] !== undefined) {
      form["filePath"] = params["filePath"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 完结任务
   * @method
   * @name finish
   */
  finish: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/finish"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 驳回乡镇申请
   * @method
   * @name reject
   */
  reject: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/reject"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["townId"] !== undefined) {
      queryParameters["townId"] = params["townId"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人无障碍改造记录（分页）
   * @method
   * @name getRemouldList
   */
  getRemouldList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/remould"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["personId"] !== undefined) {
      queryParameters["personId"] = params["personId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询无障碍改造乡镇汇总列表（分页）
   * @method
   * @name getRemouldTownPageList
   */
  getRemouldTownPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改无障碍改造乡镇汇总
   * @method
   * @name addOrUpdateRemouldTown
   */
  addOrUpdateRemouldTown: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询任务下的所有乡镇无障碍改造汇总数据（分页）
   * @method
   * @name getTownPageList
   */
  getTownPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town/by"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 任务下发社区
   * @method
   * @name taskNext
   */
  taskNext: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town/next"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    if (params["townId"] !== undefined) {
      queryParameters["townId"] = params["townId"]
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 驳回社区申请
   * @method
   * @name townReject
   */
  townReject: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town/reject"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["villageId"] !== undefined) {
      queryParameters["villageId"] = params["villageId"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 汇总结果上报
   * @method
   * @name townReport
   */
  townReport: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town/report"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["townId"] !== undefined) {
      queryParameters["townId"] = params["townId"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据任务id查询当前的乡镇
   * @method
   * @name getRemouldTown
   */
  getRemouldTown: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town/town"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询无障碍改造乡镇汇总
   * @method
   * @name getRemouldTownById
   */
  getRemouldTownById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除无障碍改造乡镇汇总
   * @method
   * @name deleteRemouldTownById
   */
  deleteRemouldTownById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/town/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 下架
   * @method
   * @name updateData
   */
  updateData: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/update"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询无障碍改造社区汇总列表（分页）
   * @method
   * @name getRemouldVillagePageList
   */
  getRemouldVillagePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改无障碍改造社区汇总
   * @method
   * @name addOrUpdateRemouldVillage
   */
  addOrUpdateRemouldVillage: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询无障碍改造社区汇总列表（分页）
   * @method
   * @name getRemouldVillageList
   */
  getRemouldVillageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/by"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    if (params["townId"] !== undefined) {
      queryParameters["townId"] = params["townId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询社区改造人员清单列表（分页）
   * @method
   * @name getRemouldVillagePersonPageList
   */
  getRemouldVillagePersonPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改社区改造人员清单
   * @method
   * @name addOrUpdateRemouldVillagePerson
   */
  addOrUpdateRemouldVillagePerson: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据任务ID社区ID查询社区改造人员清单列表（分页）
   * @method
   * @name getVillagePersonPageList
   */
  getVillagePersonPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/by"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    if (params["villageId"] !== undefined) {
      queryParameters["villageId"] = params["villageId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 设计完成
   * @method
   * @name designOver
   */
  designOver: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/designOver"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["content"] !== undefined) {
      form["content"] = params["content"]
    }

    if (params["filePath"] !== undefined) {
      form["filePath"] = params["filePath"]
    }

    if (params["id"] !== undefined) {
      form["id"] = params["id"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询当前用户的无障碍改造记录或根据userId查询（分页）
   * @method
   * @name getDetailList
   */
  getDetailList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/detail"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 验收完成
   * @method
   * @name finishPerson
   */
  finishPerson: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/finish"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["content"] !== undefined) {
      form["content"] = params["content"]
    }

    if (params["filePath"] !== undefined) {
      form["filePath"] = params["filePath"]
    }

    if (params["id"] !== undefined) {
      form["id"] = params["id"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 首页获取无障碍改造人员数据
   * @method
   * @name getRenouldDataByHome
   */
  getRenouldDataByHome: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/home"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 首页获取无障碍改造人员数据
   * @method
   * @name getMapDataList
   */
  getMapDataList: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/map"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询社区改造人员清单
   * @method
   * @name getRemouldVillagePersonById
   */
  getRemouldVillagePersonById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除社区改造人员清单
   * @method
   * @name deleteRemouldVillagePersonById
   */
  deleteRemouldVillagePersonById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/person/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 汇总结果上报
   * @method
   * @name report
   */
  report: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/report"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    if (params["villageId"] !== undefined) {
      queryParameters["villageId"] = params["villageId"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据任务id查询当前的社区
   * @method
   * @name getRemouldVillage
   */
  getRemouldVillage: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/village"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["remouldId"] !== undefined) {
      queryParameters["remouldId"] = params["remouldId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询无障碍改造社区汇总
   * @method
   * @name getRemouldVillageById
   */
  getRemouldVillageById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除无障碍改造社区汇总
   * @method
   * @name deleteRemouldVillageById
   */
  deleteRemouldVillageById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/village/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询无障碍改造主数据
   * @method
   * @name getRemouldById
   */
  getRemouldById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除无障碍改造数据(任务下的数据同步删除)
   * @method
   * @name deleteRemouldById
   */
  deleteRemouldById: (params = {}) => {

    let domain = window.api.domain
    let path = "/remould/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询回复的列表（分页）
   * @method
   * @name getReplyPageList
   */
  getReplyPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/reply"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据来信的id回复来信
   * @method
   * @name addReply
   */
  addReply: (params = {}) => {

    let domain = window.api.domain
    let path = "/reply/{letterId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{letterId}", params["letterId"])

    if (params["letterId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: letterId"))
    }

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询系统资源列表（分页）
   * @method
   * @name getResourcePageList
   */
  getResourcePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改系统资源
   * @method
   * @name addOrUpdateResource
   */
  addOrUpdateResource: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询app资源管理列表（分页）
   * @method
   * @name getResourceAppPageList
   */
  getResourceAppPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["groupId"] !== undefined) {
      queryParameters["groupId"] = params["groupId"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改app资源管理
   * @method
   * @name addOrUpdateResourceApp
   */
  addOrUpdateResourceApp: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询用户应用点击次数列表（分页）
   * @method
   * @name getResourceAppCommonlyPageList
   */
  getResourceAppCommonlyPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/commonly"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改用户应用点击次数
   * @method
   * @name addOrUpdateResourceAppCommonly
   */
  addOrUpdateResourceAppCommonly: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/commonly"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取某个用户常用应用列表
   * @method
   * @name getCommonlyResource
   */
  getCommonlyResource: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/commonly/commonly"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 应用使用增加次数
   * @method
   * @name increaseApplication
   */
  increaseApplication: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/commonly/increase/{appId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{appId}", params["appId"])

    if (params["appId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: appId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询用户应用点击次数
   * @method
   * @name getResourceAppCommonlyById
   */
  getResourceAppCommonlyById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/commonly/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除用户应用点击次数
   * @method
   * @name deleteResourceAppCommonlyById
   */
  deleteResourceAppCommonlyById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/commonly/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询登录用户app资源列表(资源组+资源列表)
   * @method
   * @name getUserAllResource
   */
  getUserAllResource: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/group/mine/{property}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{property}", params["property"])

    if (params["property"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: property"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询用户app资源列表(资源组+资源列表)
   * @method
   * @name getUserAllResourceByUserId
   */
  getUserAllResourceByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/group/{userId}/{property}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{property}", params["property"])

    if (params["property"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: property"))
    }

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据用户id查询app资源ids
   * @method
   * @name getResourceAppIdsByUserId
   */
  getResourceAppIdsByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/ids/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询登录用户app资源列表(只有资源列表)
   * @method
   * @name getMineResourceAppList
   */
  getMineResourceAppList: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/mine/{property}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{property}", params["property"])

    if (params["property"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: property"))
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增用户app资源分配
   * @method
   * @name addResourceAppUser
   */
  addResourceAppUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 资源分配用户
   * @method
   * @name relationResourceAppUser
   */
  relationResourceAppUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/user/relation"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 对用户app资源进行排序(单独处理用户和资源的排序)
   * @method
   * @name sortResourceAppUser
   */
  sortResourceAppUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/user/sort"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据资源id查询已分配/未分配用户列表
   * @method
   * @name getUsersByResourceId
   */
  getUsersByResourceId: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/user/{resourceId}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    path = path.replace("{resourceId}", params["resourceId"])

    if (params["resourceId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: resourceId"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询app资源管理
   * @method
   * @name getResourceAppById
   */
  getResourceAppById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除app资源管理
   * @method
   * @name deleteResourceAppById
   */
  deleteResourceAppById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/app/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 资源列表(树状)
   * @method
   * @name resourceBaseTree
   */
  resourceBaseTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/base/tree/{parentId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{parentId}", params["parentId"])

    if (params["parentId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: parentId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 角色分配资源
   * @method
   * @name distributeRole
   */
  distributeRole: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/distribute/role"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询app资源组列表（分页）
   * @method
   * @name getResourceGroupPageList
   */
  getResourceGroupPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改app资源组
   * @method
   * @name addOrUpdateResourceGroup
   */
  addOrUpdateResourceGroup: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改资源分组关系
   * @method
   * @name addOrUpdateResourceGroupApp
   */
  addOrUpdateResourceGroupApp: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group/app"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据分组id查询资源ids
   * @method
   * @name getResourceAppIdsByGroupId
   */
  getResourceAppIdsByGroupId: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group/app/{groupId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{groupId}", params["groupId"])

    if (params["groupId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: groupId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 添加资源组和关联表
   * @method
   * @name addResourceAndUserResource
   */
  addResourceAndUserResource: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group/relations"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改资源组和关系表
   * @method
   * @name updateResourceAndUserResource
   */
  updateResourceAndUserResource: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group/relationship"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改app资源组排序
   * @method
   * @name softResourceGroup
   */
  softResourceGroup: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group/soft"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["ids"] !== undefined) {
      form["ids"] = params["ids"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询app资源组
   * @method
   * @name getResourceGroupById
   */
  getResourceGroupById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除app资源组(并删除资源组下的资源关系)
   * @method
   * @name deleteResourceGroupById
   */
  deleteResourceGroupById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/group/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询角色资源分配列表（分页）
   * @method
   * @name getResourceRolePageList
   */
  getResourceRolePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/role"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改角色资源分配
   * @method
   * @name addOrUpdateResourceRole
   */
  addOrUpdateResourceRole: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/role"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询角色资源分配
   * @method
   * @name getResourceRoleById
   */
  getResourceRoleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/role/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除角色资源分配
   * @method
   * @name deleteResourceRoleById
   */
  deleteResourceRoleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/role/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 角色权限资源列表(List<Long>)
   * @method
   * @name roleResList
   */
  roleResList: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/roleList/{roleId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{roleId}", params["roleId"])

    if (params["roleId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: roleId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 登录用户权限资源列表(非树状)
   * @method
   * @name userAllResource
   */
  userAllResource: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/user/resource/all/list"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 登录用户权限资源列表(树状)
   * @method
   * @name userResource
   */
  userResource: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/user/resource/list"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询系统资源
   * @method
   * @name getResourceById
   */
  getResourceById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除系统资源
   * @method
   * @name deleteResourceById
   */
  deleteResourceById: (params = {}) => {

    let domain = window.api.domain
    let path = "/resource/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询角色管理列表（分页）
   * @method
   * @name getRolePageList
   */
  getRolePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/role"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改角色管理
   * @method
   * @name addOrUpdateRole
   */
  addOrUpdateRole: (params = {}) => {

    let domain = window.api.domain
    let path = "/role"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询角色用户关联列表（分页）
   * @method
   * @name getRoleUserPageList
   */
  getRoleUserPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改角色用户关联
   * @method
   * @name addOrUpdateRoleUser
   */
  addOrUpdateRoleUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户授权角色
   * @method
   * @name grantRole
   */
  grantRole: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/user/grant/role"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询角色用户关联
   * @method
   * @name getRoleUserById
   */
  getRoleUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除角色用户关联
   * @method
   * @name deleteRoleUserById
   */
  deleteRoleUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据角色id和用户id删除角色用户关联
   * @method
   * @name deleteRoleUserByUserId
   */
  deleteRoleUserByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/user/{roleId}/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{roleId}", params["roleId"])

    if (params["roleId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: roleId"))
    }

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改角色流程设置
   * @method
   * @name addOrUpdateRoleWorkflow
   */
  addOrUpdateRoleWorkflow: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/workflow"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据角色id查询流程的flowIds
   * @method
   * @name getRoleWorkflowByRoleId
   */
  getRoleWorkflowByRoleId: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/workflow/{roleId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{roleId}", params["roleId"])

    if (params["roleId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: roleId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询角色管理
   * @method
   * @name getRoleById
   */
  getRoleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除角色管理
   * @method
   * @name deleteRoleById
   */
  deleteRoleById: (params = {}) => {

    let domain = window.api.domain
    let path = "/role/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询服务评价列表（分页）
   * @method
   * @name getServiceEvaluatePageList
   */
  getServiceEvaluatePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/service/evaluate"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["key"] !== undefined) {
      queryParameters["key"] = params["key"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改服务评价
   * @method
   * @name addOrUpdateServiceEvaluate
   */
  addOrUpdateServiceEvaluate: (params = {}) => {

    let domain = window.api.domain
    let path = "/service/evaluate"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据业务主键id和业务type值查询服务评价
   * @method
   * @name getServiceEvaluateModule
   */
  getServiceEvaluateModule: (params = {}) => {

    let domain = window.api.domain
    let path = "/service/evaluate/module/{id}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的服务评价列表（个人评价——分页）
   * @method
   * @name getSelfServiceEvaluatePageList
   */
  getSelfServiceEvaluatePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/service/evaluate/self"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["content"] !== undefined) {
      queryParameters["content"] = params["content"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询服务评价
   * @method
   * @name getServiceEvaluateById
   */
  getServiceEvaluateById: (params = {}) => {

    let domain = window.api.domain
    let path = "/service/evaluate/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除服务评价
   * @method
   * @name deleteServiceEvaluateById
   */
  deleteServiceEvaluateById: (params = {}) => {

    let domain = window.api.domain
    let path = "/service/evaluate/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询技能培训列表（分页）
   * @method
   * @name getSkillTrainingPageList
   */
  getSkillTrainingPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改技能培训
   * @method
   * @name addOrUpdateSkillTraining
   */
  addOrUpdateSkillTraining: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 首页技能培训数据
   * @method
   * @name getSkillDataByHome
   */
  getSkillDataByHome: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/home"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我参加过培训签到列表（分页,残疾人使用）
   * @method
   * @name getMineSkillTrainingPageList
   */
  getMineSkillTrainingPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/mine"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keyword"] !== undefined) {
      queryParameters["keyword"] = params["keyword"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改现场图文记录
   * @method
   * @name updateSceneImageTextRecord
   */
  updateSceneImageTextRecord: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/scene"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询培训签到列表（分页）
   * @method
   * @name getSkillTrainingSignPageList
   */
  getSkillTrainingSignPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/sign"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    if (params["trainingId"] !== undefined) {
      queryParameters["trainingId"] = params["trainingId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改培训签到(残疾人签到使用)
   * @method
   * @name addOrUpdateSkillTrainingSign
   */
  addOrUpdateSkillTrainingSign: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/sign"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询培训签到
   * @method
   * @name getSkillTrainingSignById
   */
  getSkillTrainingSignById: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/sign/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除培训签到
   * @method
   * @name deleteSkillTrainingSignById
   */
  deleteSkillTrainingSignById: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/sign/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询技能培训
   * @method
   * @name getSkillTrainingById
   */
  getSkillTrainingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除技能培训
   * @method
   * @name deleteSkillTrainingById
   */
  deleteSkillTrainingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/skill/training/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询短信记录列表（分页）
   * @method
   * @name getSmsPageList
   */
  getSmsPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/sms"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["receiver"] !== undefined) {
      queryParameters["receiver"] = params["receiver"]
    }

    if (params["mobile"] !== undefined) {
      queryParameters["mobile"] = params["mobile"]
    }

    if (params["sender"] !== undefined) {
      queryParameters["sender"] = params["sender"]
    }

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    if (params["channel"] !== undefined) {
      queryParameters["channel"] = params["channel"]
    }

    if (params["sendWay"] !== undefined) {
      queryParameters["sendWay"] = params["sendWay"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["result"] !== undefined) {
      queryParameters["result"] = params["result"]
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询短信余额，目前支持HL95和互亿无线
   * @method
   * @name getSmsBalance
   */
  getSmsBalance: (params = {}) => {

    let domain = window.api.domain
    let path = "/sms/balance/info"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我发送的短信记录列表（分页）
   * @method
   * @name getMySmsPageList
   */
  getMySmsPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/sms/mine"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["receiver"] !== undefined) {
      queryParameters["receiver"] = params["receiver"]
    }

    if (params["mobile"] !== undefined) {
      queryParameters["mobile"] = params["mobile"]
    }

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    if (params["channel"] !== undefined) {
      queryParameters["channel"] = params["channel"]
    }

    if (params["sendWay"] !== undefined) {
      queryParameters["sendWay"] = params["sendWay"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["result"] !== undefined) {
      queryParameters["result"] = params["result"]
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改短信设置
   * @method
   * @name addOrUpdateSmsSetting
   */
  addOrUpdateSmsSetting: (params = {}) => {

    let domain = window.api.domain
    let path = "/sms/setting"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询短信设置
   * @method
   * @name getSmsSettingById
   */
  getSmsSettingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/sms/setting/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询短信记录
   * @method
   * @name getSmsById
   */
  getSmsById: (params = {}) => {

    let domain = window.api.domain
    let path = "/sms/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 发送失败的短信重新发送
   * @method
   * @name sendAgain
   */
  sendAgain: (params = {}) => {

    let domain = window.api.domain
    let path = "/sms/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 同步钉钉部门信息
   * @method
   * @name syncDept
   */
  syncDept: (params = {}) => {

    let domain = window.api.domain
    let path = "/sync/dept/{appAlias}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{appAlias}", params["appAlias"])

    if (params["appAlias"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: appAlias"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 同步钉钉用户信息
   * @method
   * @name syncUser
   */
  syncUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/sync/user/{appAlias}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{appAlias}", params["appAlias"])

    if (params["appAlias"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: appAlias"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 同步钉钉部门和用户信息
   * @method
   * @name syncDeptAndUser
   */
  syncDeptAndUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/sync/{appAlias}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{appAlias}", params["appAlias"])

    if (params["appAlias"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: appAlias"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询SysArea列表（分页）
   * @method
   * @name getSysAreaPageList
   */
  getSysAreaPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    if (params["level"] !== undefined) {
      queryParameters["level"] = params["level"]
    }

    if (params["pid"] !== undefined) {
      queryParameters["pid"] = params["pid"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改SysArea
   * @method
   * @name addOrUpdateSysArea
   */
  addOrUpdateSysArea: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据当前用户查询所有下级
   * @method
   * @name getChildList
   */
  getChildList: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area/child"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 导入残疾人
   * @method
   * @name saveDisabledPersonExcel
   */
  saveDisabledPersonExcel: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area/import"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["fileTypes"] !== undefined) {
      queryParameters["fileTypes"] = params["fileTypes"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询SysArea
   * @method
   * @name getSysAreaByLevelAndPid
   */
  getSysAreaByLevelAndPid: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area/level/{level}/pid/{pid}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{level}", params["level"])

    if (params["level"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: level"))
    }

    path = path.replace("{pid}", params["pid"])

    if (params["pid"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: pid"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取伊滨区的tree
   * @method
   * @name getSysAreaTree
   */
  getSysAreaTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area/tree"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询SysArea
   * @method
   * @name getSysAreaById
   */
  getSysAreaById: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除SysArea
   * @method
   * @name deleteSysAreaById
   */
  deleteSysAreaById: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/area/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 缩放，旋转，然后裁剪图片尺寸
   * @method
   * @name updateImageSize
   */
  updateImageSize: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["path"] !== undefined) {
      form["path"] = params["path"]
    }

    if (params["x"] !== undefined) {
      form["x"] = params["x"]
    }

    if (params["y"] !== undefined) {
      form["y"] = params["y"]
    }

    if (params["width"] !== undefined) {
      form["width"] = params["width"]
    }

    if (params["height"] !== undefined) {
      form["height"] = params["height"]
    }

    if (params["rotate"] !== undefined) {
      form["rotate"] = params["rotate"]
    }

    if (params["zoomWidth"] !== undefined) {
      form["zoomWidth"] = params["zoomWidth"]
    }

    if (params["zoomHeight"] !== undefined) {
      form["zoomHeight"] = params["zoomHeight"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存base64图片  适用于小图
   * @method
   * @name uploadImageBae64
   */
  uploadImageBae64: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/base64"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["path"] !== undefined) {
      form["path"] = params["path"]
    }

    if (params["base64"] !== undefined) {
      form["base64"] = params["base64"]
    }

    if (params["header"] !== undefined) {
      form["header"] = params["header"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存base64文件
   * @method
   * @name uploadFileBae64
   */
  uploadFileBae64: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/base64/file"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["path"] !== undefined) {
      form["path"] = params["path"]
    }

    if (params["base64"] !== undefined) {
      form["base64"] = params["base64"]
    }

    if (params["header"] !== undefined) {
      form["header"] = params["header"]
    }

    if (params["fileType"] !== undefined) {
      form["fileType"] = params["fileType"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取文件,返回base64
   * @method
   * @name getBase64File
   */
  getBase64File: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/base64/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 上传文件,返回文件id,不返回文件真实路径
   * @method
   * @name upDbLoadFile
   */
  upDbLoadFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/db/upload"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["path"] !== undefined) {
      queryParameters["path"] = params["path"]
    }

    if (params["fileTypes"] !== undefined) {
      queryParameters["fileTypes"] = params["fileTypes"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取文件,返回文件流
   * @method
   * @name getFileDownload
   */
  getFileDownload: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/download/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 通过文件流上传文件,
   * @method
   * @name upDbLoadFileStream
   */
  upDbLoadFileStream: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/file/stream"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["path"] !== undefined) {
      queryParameters["path"] = params["path"]
    }

    if (params["saveFileName"] !== undefined) {
      queryParameters["saveFileName"] = params["saveFileName"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取下载文件水印设置
   * @method
   * @name getSysFileOption
   */
  getSysFileOption: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/option"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改下载文件水印设置
   * @method
   * @name addOrUpdateOption
   */
  addOrUpdateOption: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/option"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 生成并上传二维码,
   * @method
   * @name qrCode
   */
  qrCode: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/qrCode"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["content"] !== undefined) {
      queryParameters["content"] = params["content"]
    }

    if (params["format"] !== undefined) {
      queryParameters["format"] = params["format"]
    }

    if (params["logUri"] !== undefined) {
      queryParameters["logUri"] = params["logUri"]
    }

    if (params["savePath"] !== undefined) {
      queryParameters["savePath"] = params["savePath"]
    }

    if (params["width"] !== undefined) {
      queryParameters["width"] = params["width"]
    }

    if (params["height"] !== undefined) {
      queryParameters["height"] = params["height"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 断点续传，第1步，检查文件，返回上传状态和最终分片索引
   * @method
   * @name checkSliceFile
   */
  checkSliceFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/slice/check"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["fileSize"] !== undefined) {
      form["fileSize"] = params["fileSize"]
    }

    if (params["sliceSize"] !== undefined) {
      form["sliceSize"] = params["sliceSize"]
    }

    if (params["md5"] !== undefined) {
      form["md5"] = params["md5"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 断点续传，第3步，合并分片
   * @method
   * @name mergeSliceFile
   */
  mergeSliceFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/slice/merge"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["fileId"] !== undefined) {
      form["fileId"] = params["fileId"]
    }

    if (params["fileName"] !== undefined) {
      form["fileName"] = params["fileName"]
    }

    if (params["md5"] !== undefined) {
      form["md5"] = params["md5"]
    }

    if (params["path"] !== undefined) {
      form["path"] = params["path"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 断点续传，第2步，上传分片
   * @method
   * @name uploadSliceFile
   */
  uploadSliceFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/slice/upload"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["md5"] !== undefined) {
      form["md5"] = params["md5"]
    }

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["chunk"] !== undefined) {
      form["chunk"] = params["chunk"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 上传文件并转化为pdf
   * @method
   * @name transformFiles
   */
  transformFiles: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/transform"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["savePath"] !== undefined) {
      queryParameters["savePath"] = params["savePath"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 上传文件
   * @method
   * @name uploadFile
   */
  uploadFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/upload"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["path"] !== undefined) {
      queryParameters["path"] = params["path"]
    }

    if (params["fileTypes"] !== undefined) {
      queryParameters["fileTypes"] = params["fileTypes"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改水印名称
   * @method
   * @name updateFileWatermark
   */
  updateFileWatermark: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/watermark"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["id"] !== undefined) {
      form["id"] = params["id"]
    }

    if (params["watermark"] !== undefined) {
      form["watermark"] = params["watermark"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 下载水印文件
   * @method
   * @name getWatermarkFileDownload
   */
  getWatermarkFileDownload: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/watermark/download/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 分页查询水印记录
   * @method
   * @name getSysFileWatermarkPageList
   */
  getSysFileWatermarkPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/watermark/record"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 上传待添加水印的文件,返回文件id,不返回文件真实路径
   * @method
   * @name upWaterMaterFile
   */
  upWaterMaterFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/watermark/upload"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    if (params["file"] !== undefined) {
      form["file"] = params["file"]
    }

    if (params["path"] !== undefined) {
      queryParameters["path"] = params["path"]
    }

    if (params["fileTypes"] !== undefined) {
      queryParameters["fileTypes"] = params["fileTypes"]
    }

    if (params["watermark"] !== undefined) {
      queryParameters["watermark"] = params["watermark"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取文件,返回文件流
   * @method
   * @name getFile
   */
  getFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询文件信息
   * @method
   * @name getSysFileById
   */
  getSysFileById: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/{id}/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取文件静态访问地址
   * @method
   * @name getFileStaticPath
   */
  getFileStaticPath: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/file/{id}/static/path"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询操作日志列表（分页）
   * @method
   * @name getSystemLogPageList
   */
  getSystemLogPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/log"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改操作日志
   * @method
   * @name addOrUpdateSystemLog
   */
  addOrUpdateSystemLog: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/log"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询操作日志
   * @method
   * @name test
   */
  test: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/log/test/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询操作日志
   * @method
   * @name getSystemLogById
   */
  getSystemLogById: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/log/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除操作日志
   * @method
   * @name deleteSystemLogById
   */
  deleteSystemLogById: (params = {}) => {

    let domain = window.api.domain
    let path = "/sys/log/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询SystemSetting列表（分页）
   * @method
   * @name getSystemSettingPageList
   */
  getSystemSettingPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/system/setting"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改SystemSetting
   * @method
   * @name addOrUpdateSystemSetting
   */
  addOrUpdateSystemSetting: (params = {}) => {

    let domain = window.api.domain
    let path = "/system/setting"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询是否开启es服务
   * @method
   * @name getSystemSettingES
   */
  getSystemSettingES: (params = {}) => {

    let domain = window.api.domain
    let path = "/system/setting/es"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询SystemSetting的静态资源文件dto(无鉴权)
   * @method
   * @name getSystemSettingStatic
   */
  getSystemSettingStatic: (params = {}) => {

    let domain = window.api.domain
    let path = "/system/setting/static"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询SystemSetting
   * @method
   * @name getSystemSettingById
   */
  getSystemSettingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/system/setting/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除SystemSetting
   * @method
   * @name deleteSystemSettingById
   */
  deleteSystemSettingById: (params = {}) => {

    let domain = window.api.domain
    let path = "/system/setting/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人临时救助（先办证）列表（分页）
   * @method
   * @name getTempSuccourPageList
   */
  getTempSuccourPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/temp/succour"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改残疾人临时救助（先办证）
   * @method
   * @name addOrUpdateTempSuccour
   */
  addOrUpdateTempSuccour: (params = {}) => {

    let domain = window.api.domain
    let path = "/temp/succour"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 首页临时救助记录
   * @method
   * @name getDataByMap
   */
  getDataByMap: (params = {}) => {

    let domain = window.api.domain
    let path = "/temp/succour/home"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询公示救助对象数据
   * @method
   * @name getNotice
   */
  getNotice: (params = {}) => {

    let domain = window.api.domain
    let path = "/temp/succour/notice"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询残疾人临时救助记录（分页）
   * @method
   * @name getTempList
   */
  getTempList: (params = {}) => {

    let domain = window.api.domain
    let path = "/temp/succour/temp"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询残疾人临时救助（先办证）
   * @method
   * @name getTempSuccourById
   */
  getTempSuccourById: (params = {}) => {

    let domain = window.api.domain
    let path = "/temp/succour/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除残疾人临时救助（先办证）
   * @method
   * @name deleteTempSuccourById
   */
  deleteTempSuccourById: (params = {}) => {

    let domain = window.api.domain
    let path = "/temp/succour/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取UEditor配置信息
   * @method
   * @name getUEditorConfigInfo
   */
  getUEditorConfigInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/ueditor/config"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["action"] !== undefined) {
      queryParameters["action"] = params["action"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * UEditor上传文件
   * @method
   * @name uploadUEditorFile
   */
  uploadUEditorFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/ueditor/config"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'multipart/form-data'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * UEditor上传文件
   * @method
   * @name uploadUEditorSimple
   */
  uploadUEditorSimple: (params = {}) => {

    let domain = window.api.domain
    let path = "/ueditor/config/simple"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询用户列表（分页）
   * @method
   * @name getUserPageList
   */
  getUserPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改用户
   * @method
   * @name addOrUpdateUser
   */
  addOrUpdateUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 微信登录，绑定账号
   * @method
   * @name bindUser
   */
  bindUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/bind"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户修改自己的主题色
   * @method
   * @name updateThemeColor
   */
  updateThemeColor: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/color"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["color"] !== undefined) {
      queryParameters["color"] = params["color"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取用户通讯录
   * @method
   * @name getUserContacts
   */
  getUserContacts: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/contacts/tree"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["key"] !== undefined) {
      queryParameters["key"] = params["key"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 复制用户信息到另一个部门
   * @method
   * @name copyUser
   */
  copyUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/copy"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取user详细信息
   * @method
   * @name getUserDetail
   */
  getUserDetail: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/detail"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询UserDevice列表（分页）
   * @method
   * @name getUserDevicePageList
   */
  getUserDevicePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改UserDevice
   * @method
   * @name addOrUpdateUserDevice
   */
  addOrUpdateUserDevice: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 设定为主设备
   * @method
   * @name setMainDevice
   */
  setMainDevice: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/setMainDevice"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询用户设备token列表（分页）
   * @method
   * @name getUserDeviceTokenPageList
   */
  getUserDeviceTokenPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/token"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改用户设备token
   * @method
   * @name addOrUpdateUserDeviceToken
   */
  addOrUpdateUserDeviceToken: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/token"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId和deviceId删除用户设备token(华为推送使用)
   * @method
   * @name deleteUserDeviceTokenByUserIdAndDeviceId
   */
  deleteUserDeviceTokenByUserIdAndDeviceId: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/token/hw/{userId}/{deviceId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    path = path.replace("{deviceId}", params["deviceId"])

    if (params["deviceId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: deviceId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 极光推送新增或修改用户设备token
   * @method
   * @name addOrUpdateJPushUserDeviceToken
   */
  addOrUpdateJPushUserDeviceToken: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/token/jPush"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId和registrationId删除用户设备token(极光推送使用)
   * @method
   * @name deleteUserDeviceTokenByUserIdAndRegistrationId
   */
  deleteUserDeviceTokenByUserIdAndRegistrationId: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/token/jPush/{userId}/{registrationId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    path = path.replace("{registrationId}", params["registrationId"])

    if (params["registrationId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: registrationId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询用户设备token
   * @method
   * @name getUserDeviceTokenById
   */
  getUserDeviceTokenById: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/token/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除用户设备token
   * @method
   * @name deleteUserDeviceTokenById
   */
  deleteUserDeviceTokenById: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/token/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询UserDevice
   * @method
   * @name getUserDeviceById
   */
  getUserDeviceById: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除UserDevice
   * @method
   * @name deleteUserDeviceById
   */
  deleteUserDeviceById: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/device/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取user首页信息(首页右上角用,用户工作状态)
   * @method
   * @name getUserHomeDetail
   */
  getUserHomeDetail: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/home/detail"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取用户信息和残疾证件信息
   * @method
   * @name getInfo
   */
  getInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/info"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 同步工号信息
   * @method
   * @name updateJobNumber
   */
  updateJobNumber: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/job/number"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据用户自定义ids查询所有的用户列表
   * @method
   * @name getUserListByIds
   */
  getUserListByIds: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/list/ids"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["ids"] !== undefined) {
      form["ids"] = params["ids"]
    }

    if (params["ids"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: ids"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 修改个人密码
   * @method
   * @name changePassword
   */
  changePassword: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/password"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["password"] !== undefined) {
      form["password"] = params["password"]
    }

    if (params["password"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: password"))
    }

    if (params["newPassword"] !== undefined) {
      form["newPassword"] = params["newPassword"]
    }

    if (params["newPassword"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: newPassword"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询用户问卷关系列表（个人-文件调查首页分页）
   * @method
   * @name getUserQuestionnairePageList
   */
  getUserQuestionnairePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["name"] !== undefined) {
      queryParameters["name"] = params["name"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增用户问卷关系
   * @method
   * @name addOrUpdateUserQuestionnaire
   */
  addOrUpdateUserQuestionnaire: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询用户问卷答题记录列表（分页）
   * @method
   * @name getUserQuestionnaireAnswerRecordPageList
   */
  getUserQuestionnaireAnswerRecordPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire/answer/record"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增用户问卷答题记录
   * @method
   * @name addUserQuestionnaireAnswerRecord
   */
  addUserQuestionnaireAnswerRecord: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire/answer/record"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询用户问卷答题记录
   * @method
   * @name getUserQuestionnaireAnswerRecordByUserAndQuestionnaire
   */
  getUserQuestionnaireAnswerRecordByUserAndQuestionnaire: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire/answer/record/questionnaire/{questionnaireId}/user/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{questionnaireId}", params["questionnaireId"])

    if (params["questionnaireId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: questionnaireId"))
    }

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除用户问卷答题记录
   * @method
   * @name deleteUserQuestionnaireAnswerRecordById
   */
  deleteUserQuestionnaireAnswerRecordById: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire/answer/record/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据问卷id查询答题人列表（分页）
   * @method
   * @name getUserQuestionnairePageListByQuestionnaireId
   */
  getUserQuestionnairePageListByQuestionnaireId: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire/questionnaire"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["questionnaireId"] !== undefined) {
      queryParameters["questionnaireId"] = params["questionnaireId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询用户问卷关系
   * @method
   * @name getUserQuestionnaireById
   */
  getUserQuestionnaireById: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除用户问卷关系
   * @method
   * @name deleteUserQuestionnaireById
   */
  deleteUserQuestionnaireById: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/questionnaire/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 手动更新redis部门用户树结构缓存,和部门表中的用户数量,以及工作组中用户数量
   * @method
   * @name updateRedisTree
   */
  updateRedisTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/redis/tree"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 微信登录，注册新账号
   * @method
   * @name registerUser
   */
  registerUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/register"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取角色用户树状结构
   * @method
   * @name getRoleUserTree
   */
  getRoleUserTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/role/tree/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询角色下的用户列表
   * @method
   * @name getRoleUserList
   */
  getRoleUserList: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/role/{roleId}/{type}/{pageIndex}/{pageSize}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{roleId}", params["roleId"])

    if (params["roleId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: roleId"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    path = path.replace("{pageIndex}", params["pageIndex"])

    if (params["pageIndex"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: pageIndex"))
    }

    path = path.replace("{pageSize}", params["pageSize"])

    if (params["pageSize"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: pageSize"))
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 发送验证码（注册）
   * @method
   * @name sendCode
   */
  sendCode: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/send/code/{mobile}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{mobile}", params["mobile"])

    if (params["mobile"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: mobile"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据用户/部门/工作组自定义ids当做父级ID,查询树形结构
   * @method
   * @name getTreeListByIds
   */
  getTreeListByIds: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/tree/ids"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["ids"] !== undefined) {
      form["ids"] = params["ids"]
    }

    if (params["ids"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: ids"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 微信登录，解除绑定
   * @method
   * @name unbindUser
   */
  unbindUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/unbind"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询当前登录用户是否修改过密码(true-修改过或者系统配置未开启修改,false-代表没有修改过)
   * @method
   * @name getPasswordChangeState
   */
  getPasswordChangeState: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/update/password"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId查询用户
   * @method
   * @name getUserByUserId
   */
  getUserByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/{userId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 重置密码
   * @method
   * @name resetPassword
   */
  resetPassword: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/{userId}/password/reset"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId删除用户
   * @method
   * @name deleteUserByUserId
   */
  deleteUserByUserId: (params = {}) => {

    let domain = window.api.domain
    let path = "/user/{userId}/{orgId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    path = path.replace("{orgId}", params["orgId"])

    if (params["orgId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: orgId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取微信js签名
   * @method
   * @name getSign
   */
  getSign: (params = {}) => {

    let domain = window.api.domain
    let path = "/wechat/sign"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["url"] !== undefined) {
      queryParameters["url"] = params["url"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取微信用户信息
   * @method
   * @name getWeChatUserInfo
   */
  getWeChatUserInfo: (params = {}) => {

    let domain = window.api.domain
    let path = "/wechat/user/info/{code}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{code}", params["code"])

    if (params["code"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: code"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作组列表（分页）
   * @method
   * @name getWorkGroupPageList
   */
  getWorkGroupPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改工作组
   * @method
   * @name addOrUpdateWorkGroup
   */
  addOrUpdateWorkGroup: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 工作组列表(懒加载)
   * @method
   * @name getWorkGroupLazy
   */
  getWorkGroupLazy: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/lazy/{parentId}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{parentId}", params["parentId"])

    if (params["parentId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: parentId"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    if (params["module"] !== undefined) {
      queryParameters["module"] = params["module"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["queryType"] !== undefined) {
      queryParameters["queryType"] = params["queryType"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 工作组列表(树状)
   * @method
   * @name getWorkGroupTree
   */
  getWorkGroupTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/tree/{parentId}/{type}/{isMe}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{parentId}", params["parentId"])

    if (params["parentId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: parentId"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    path = path.replace("{isMe}", params["isMe"])

    if (params["isMe"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: isMe"))
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    if (params["uStatus"] !== undefined) {
      queryParameters["uStatus"] = params["uStatus"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询WorkGroupUser列表（分页）
   * @method
   * @name getWorkGroupUserPageList
   */
  getWorkGroupUserPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改WorkGroupUser
   * @method
   * @name addOrUpdateWorkGroupUser
   */
  addOrUpdateWorkGroupUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/user"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 用户授权工作组
   * @method
   * @name grantWorkGroup
   */
  grantWorkGroup: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/user/grant"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作组下的用户列表
   * @method
   * @name userList
   */
  userList: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/user/userList/{workGroupId}/{type}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{workGroupId}", params["workGroupId"])

    if (params["workGroupId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: workGroupId"))
    }

    path = path.replace("{type}", params["type"])

    if (params["type"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: type"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询WorkGroupUser
   * @method
   * @name getWorkGroupUserById
   */
  getWorkGroupUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除WorkGroupUser
   * @method
   * @name deleteWorkGroupUserById
   */
  deleteWorkGroupUserById: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/user/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据userId和workId删除工作组下的用户
   * @method
   * @name deleteWorkGroupUser
   */
  deleteWorkGroupUser: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/user/{userId}/{workId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{userId}", params["userId"])

    if (params["userId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: userId"))
    }

    path = path.replace("{workId}", params["workId"])

    if (params["workId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: workId"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询工作组
   * @method
   * @name getWorkGroupById
   */
  getWorkGroupById: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除工作组
   * @method
   * @name deleteWorkGroupById
   */
  deleteWorkGroupById: (params = {}) => {

    let domain = window.api.domain
    let path = "/work/group/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流程列表（分页）
   * @method
   * @name getWorkflowPageList
   */
  getWorkflowPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改工作流程
   * @method
   * @name addOrUpdateWorkflow
   */
  addOrUpdateWorkflow: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询按钮信息列表（分页）
   * @method
   * @name getWorkflowButtonPageList
   */
  getWorkflowButtonPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/button"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改按钮信息
   * @method
   * @name addOrUpdateWorkflowButton
   */
  addOrUpdateWorkflowButton: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/button"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询按钮信息列表
   * @method
   * @name getWorkflowButtonList
   */
  getWorkflowButtonList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/button/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询按钮信息
   * @method
   * @name getWorkflowButtonById
   */
  getWorkflowButtonById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/button/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除按钮信息
   * @method
   * @name deleteWorkflowButtonById
   */
  deleteWorkflowButtonById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/button/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流程树状结构（包含全部流程）
   * @method
   * @name getWorkflowCategoryTree
   */
  getWorkflowCategoryTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/category/tree"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["parentId"] !== undefined) {
      queryParameters["parentId"] = params["parentId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询流程实例显示字段设置列表（分页）
   * @method
   * @name getWorkflowFieldPageList
   */
  getWorkflowFieldPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/field"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["tableName"] !== undefined) {
      queryParameters["tableName"] = params["tableName"]
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改流程实例显示字段设置
   * @method
   * @name addOrUpdateWorkflowField
   */
  addOrUpdateWorkflowField: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/field"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 初始化流程实例显示字段设置
   * @method
   * @name initWorkflowField
   */
  initWorkflowField: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/field/init"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询流程实例显示字段设置
   * @method
   * @name getWorkflowFieldById
   */
  getWorkflowFieldById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/field/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除流程实例显示字段设置
   * @method
   * @name deleteWorkflowFieldById
   */
  deleteWorkflowFieldById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/field/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取文件
   * @method
   * @name getSpecialFile
   */
  getSpecialFile: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/file/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["waterMark"] !== undefined) {
      queryParameters["waterMark"] = params["waterMark"]
    }

    if (params["waterText"] !== undefined) {
      queryParameters["waterText"] = params["waterText"]
    }

    if (params["isLabel"] !== undefined) {
      queryParameters["isLabel"] = params["isLabel"]
    }

    if (params["labelText"] !== undefined) {
      queryParameters["labelText"] = params["labelText"]
    }

    if (params["labelX"] !== undefined) {
      queryParameters["labelX"] = params["labelX"]
    }

    if (params["labelY"] !== undefined) {
      queryParameters["labelY"] = params["labelY"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流表单信息列表（分页）
   * @method
   * @name getWorkflowFormPageList
   */
  getWorkflowFormPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存工作流表单信息
   * @method
   * @name saveWorkflowForm
   */
  saveWorkflowForm: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流表单信息列表
   * @method
   * @name getWorkflowFormList
   */
  getWorkflowFormList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存并发布工作流表单信息
   * @method
   * @name saveAndPublishWorkflowForm
   */
  saveAndPublishWorkflowForm: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form/save/and/publish"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存工作流表单模板位置信息
   * @method
   * @name saveWorkflowFormTemplates
   */
  saveWorkflowFormTemplates: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form/templates"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询工作流表单信息
   * @method
   * @name getWorkflowFormById
   */
  getWorkflowFormById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除工作流表单信息
   * @method
   * @name deleteWorkflowFormById
   */
  deleteWorkflowFormById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id发布工作流表单信息
   * @method
   * @name publishWorkflowFormById
   */
  publishWorkflowFormById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/form/{id}/publish"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流实例表信息
   * @method
   * @name getWorkflowInstanceTableDataList
   */
  getWorkflowInstanceTableDataList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/instance/table/data"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 下载工作流实例表信息
   * @method
   * @name downloadWorkflowInstanceTableData
   */
  downloadWorkflowInstanceTableData: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/instance/table/data/download"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询labelText标签内容
   * @method
   * @name getLabelTextByTaskId
   */
  getLabelTextByTaskId: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/label/text/{taskId}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{taskId}", params["taskId"])

    if (params["taskId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: taskId"))
    }

    if (params["labelText"] !== undefined) {
      queryParameters["labelText"] = params["labelText"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流程列表
   * @method
   * @name getWorkflowList
   */
  getWorkflowList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["keywords"] !== undefined) {
      queryParameters["keywords"] = params["keywords"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-加签
   * @method
   * @name addWrite
   */
  addWrite: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/add/write"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["id"] !== undefined) {
      form["id"] = params["id"]
    }

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["addType"] !== undefined) {
      form["addType"] = params["addType"]
    }

    if (params["addType"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: addType"))
    }

    if (params["writeType"] !== undefined) {
      form["writeType"] = params["writeType"]
    }

    if (params["writeType"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: writeType"))
    }

    if (params["writeUser"] !== undefined) {
      form["writeUser"] = params["writeUser"]
    }

    if (params["writeUser"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: writeUser"))
    }

    if (params["note"] !== undefined) {
      form["note"] = params["note"]
    }

    if (params["note"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: note"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 管理员获取工作流表单信息（除了签名内容其他都可更改）
   * @method
   * @name getAdminFormData
   */
  getAdminFormData: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/admin/index"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["flowId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: flowId"))
    }

    if (params["taskId"] !== undefined) {
      queryParameters["taskId"] = params["taskId"]
    }

    if (params["announcementId"] !== undefined) {
      queryParameters["announcementId"] = params["announcementId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-退回
   * @method
   * @name backFlow
   */
  backFlow: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/back"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-执行
   * @method
   * @name execute
   */
  execute: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/execute"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存表单信息
   * @method
   * @name saveFormData
   */
  saveFormData: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/form/data"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取工作流表单信息
   * @method
   * @name getFormData
   */
  getFormData: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/index"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["flowId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: flowId"))
    }

    if (params["taskId"] !== undefined) {
      queryParameters["taskId"] = params["taskId"]
    }

    if (params["announcementId"] !== undefined) {
      queryParameters["announcementId"] = params["announcementId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程批量归档-根据实例ID集合
   * @method
   * @name archiveByInstanceList
   */
  archiveByInstanceList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/instance/archive"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程批量归档-根据发起时间范围
   * @method
   * @name archiveByLaunchTime
   */
  archiveByLaunchTime: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/instance/archive/by/time"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["startTime"] !== undefined) {
      form["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      form["endTime"] = params["endTime"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-批量终止实例
   * @method
   * @name endBatchFlowByInstanceId
   */
  endBatchFlowByInstanceId: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/instance/batch/end"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 我发起的流程批量催办（不传数组或者数组为null全部催办）
   * @method
   * @name remindMyLaunchByInstanceList
   */
  remindMyLaunchByInstanceList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/instance/mine/remind"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程批量催办（不传数组或者数组为null全部催办）
   * @method
   * @name remindByInstanceList
   */
  remindByInstanceList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/instance/remind"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-终止实例
   * @method
   * @name endFlowByInstanceId
   */
  endFlowByInstanceId: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/instance/{id}/end"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程单个催办
   * @method
   * @name remindByInstanceIdAll
   */
  remindByInstanceIdAll: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/instance/{id}/remind"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 获取手机端工作流表单信息
   * @method
   * @name getMobileFormData
   */
  getMobileFormData: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/mobile/index"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["flowId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: flowId"))
    }

    if (params["taskId"] !== undefined) {
      queryParameters["taskId"] = params["taskId"]
    }

    if (params["announcementId"] !== undefined) {
      queryParameters["announcementId"] = params["announcementId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-发送
   * @method
   * @name sendFlow
   */
  sendFlow: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/send"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-抄送
   * @method
   * @name copyFor
   */
  copyFor: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/task/cc"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    if (params["id"] !== undefined) {
      form["id"] = params["id"]
    }

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["copyUser"] !== undefined) {
      form["copyUser"] = params["copyUser"]
    }

    if (params["copyUser"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: copyUser"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-用户根据任务ID终止任务
   * @method
   * @name endFlow
   */
  endFlow: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/task/{id}/end"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程处理-用户根据任务ID撤回已发送的任务（已被打开的任务不能撤回）
   * @method
   * @name recall
   */
  recall: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/run/task/{id}/recall"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存并发布工作流程
   * @method
   * @name saveAndPublishWorkflow
   */
  saveAndPublishWorkflow: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/save/and/publish"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询流程实例查询信息设置列表（分页）
   * @method
   * @name getWorkflowSearchPageList
   */
  getWorkflowSearchPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/search"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["tableName"] !== undefined) {
      queryParameters["tableName"] = params["tableName"]
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改流程实例查询信息设置
   * @method
   * @name addOrUpdateWorkflowSearch
   */
  addOrUpdateWorkflowSearch: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/search"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据流程id查询流程实例查询信息设置列表
   * @method
   * @name getWorkflowSearchListByFlowId
   */
  getWorkflowSearchListByFlowId: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/search/flow"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询流程实例查询信息设置
   * @method
   * @name getWorkflowSearchById
   */
  getWorkflowSearchById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/search/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除流程实例查询信息设置
   * @method
   * @name deleteWorkflowSearchById
   */
  deleteWorkflowSearchById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/search/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询全部工作流简短信息
   * @method
   * @name getAllWorkflowShortList
   */
  getAllWorkflowShortList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/short"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改签名模板信息
   * @method
   * @name addOrUpdateSignTemplate
   */
  addOrUpdateSignTemplate: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/sign/template"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的签名模板信息列表
   * @method
   * @name getSignTemplateList
   */
  getSignTemplateList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/sign/template/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询签名模板信息列表（分页）
   * @method
   * @name getSignTemplatePageList
   */
  getSignTemplatePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/sign/template/page/list"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询签名模板信息
   * @method
   * @name getSignTemplateById
   */
  getSignTemplateById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/sign/template/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除签名模板信息
   * @method
   * @name deleteSignTemplateById
   */
  deleteSignTemplateById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/sign/template/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询电子签章分页记录
   * @method
   * @name getSignPageList
   */
  getSignPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/signature"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["instanceId"] !== undefined) {
      queryParameters["instanceId"] = params["instanceId"]
    }

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 新增或修改电子签章记录
   * @method
   * @name addOrUpdateWorkflowSign
   */
  addOrUpdateWorkflowSign: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/signature"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    if (params["body"] !== undefined) {
      body = params["body"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 导出电子签章pdf
   * @method
   * @name getSignPdf
   */
  getSignPdf: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/signature/pdf"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["ids"] !== undefined) {
      queryParameters["ids"] = params["ids"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询电子签章记录
   * @method
   * @name getWorkflowSignById
   */
  getWorkflowSignById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/signature/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除电子签章记录
   * @method
   * @name deleteWorkflowSignById
   */
  deleteWorkflowSignById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/signature/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流程实例数据表列表
   * @method
   * @name getWorkflowTableList
   */
  getWorkflowTableList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/table"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流程实例字段信息列表
   * @method
   * @name getWorkflowTableFieldList
   */
  getWorkflowTableFieldList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/table/field"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["tableName"] !== undefined) {
      queryParameters["tableName"] = params["tableName"]
    }

    if (params["tableName"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: tableName"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流任务列表（分页）
   * @method
   * @name getWorkflowTaskPageList
   */
  getWorkflowTaskPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["title"] !== undefined) {
      queryParameters["title"] = params["title"]
    }

    if (params["sender"] !== undefined) {
      queryParameters["sender"] = params["sender"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["type"] !== undefined) {
      queryParameters["type"] = params["type"]
    }

    if (params["copy"] !== undefined) {
      queryParameters["copy"] = params["copy"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    if (params["timeSort"] !== undefined) {
      queryParameters["timeSort"] = params["timeSort"]
    }

    if (params["completedStartTime"] !== undefined) {
      queryParameters["completedStartTime"] = params["completedStartTime"]
    }

    if (params["completedEndTime"] !== undefined) {
      queryParameters["completedEndTime"] = params["completedEndTime"]
    }

    if (params["instanceStatus"] !== undefined) {
      queryParameters["instanceStatus"] = params["instanceStatus"]
    }

    if (params["stepName"] !== undefined) {
      queryParameters["stepName"] = params["stepName"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据实例ID或任务ID下载表单PDF
   * @method
   * @name exportSignFormPdf
   */
  exportSignFormPdf: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/base64/sign/pdf"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["taskId"] !== undefined) {
      queryParameters["taskId"] = params["taskId"]
    }

    if (params["instanceId"] !== undefined) {
      queryParameters["instanceId"] = params["instanceId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的流程所有待办/已办数量(查出全部的类型数量)
   * @method
   * @name getMyWorkflowTaskCategoryCount
   */
  getMyWorkflowTaskCategoryCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/category/count"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["title"] !== undefined) {
      queryParameters["title"] = params["title"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的各个分类任务处理数量
   * @method
   * @name getAllCategoryWorkflowTaskCount
   */
  getAllCategoryWorkflowTaskCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/category/count/mine"
    let body = {}
    let queryParameters = params
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流任务待办数量
   * @method
   * @name getWorkflowTaskCount
   */
  getWorkflowTaskCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/count"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 流程实例全部数据统计接口
   * @method
   * @name getWorkflowTaskCountAll
   */
  getWorkflowTaskCountAll: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/count/all"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["orgId"] !== undefined) {
      queryParameters["orgId"] = params["orgId"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的工作流任务待办数量
   * @method
   * @name getMyWorkflowTaskCount
   */
  getMyWorkflowTaskCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/count/mine"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除已终结实例的工作流任务
   * @method
   * @name deleteEndWorkflowTaskById
   */
  deleteEndWorkflowTaskById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/delete/end/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的各个流程的任务处理数量
   * @method
   * @name getMyAllWorkflowTaskCount
   */
  getMyAllWorkflowTaskCount: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/flow/count/mine"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的全部工作流类型包括任务列表前几条
   * @method
   * @name getAllWorkflowTaskTopList
   */
  getAllWorkflowTaskTopList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/flow/top/{count}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{count}", params["count"])

    if (params["count"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: count"))
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询全部工作流实例列表（分页）
   * @method
   * @name getWorkflowTaskInstancePageList
   */
  getWorkflowTaskInstancePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["title"] !== undefined) {
      queryParameters["title"] = params["title"]
    }

    if (params["sender"] !== undefined) {
      queryParameters["sender"] = params["sender"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    if (params["userIds"] !== undefined) {
      queryParameters["userIds"] = params["userIds"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我发起的工作流实例表单数据列表（分页）
   * @method
   * @name getInstanceDataList
   */
  getInstanceDataList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance/by"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["userId"] !== undefined) {
      queryParameters["userId"] = params["userId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我经办的工作流实例列表（分页）
   * @method
   * @name getWorkflowTaskInstanceHandlePageList
   */
  getWorkflowTaskInstanceHandlePageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance/handle"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["title"] !== undefined) {
      queryParameters["title"] = params["title"]
    }

    if (params["sender"] !== undefined) {
      queryParameters["sender"] = params["sender"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我经办的工作流实例列表最近几条
   * @method
   * @name getWorkflowTaskInstanceHandleTopList
   */
  getWorkflowTaskInstanceHandleTopList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance/handle/top/{count}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{count}", params["count"])

    if (params["count"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: count"))
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我发起的工作流实例列表（分页）
   * @method
   * @name getWorkflowTaskInstanceLaunchPageList
   */
  getWorkflowTaskInstanceLaunchPageList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance/launch"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["pageIndex"] !== undefined) {
      queryParameters["pageIndex"] = params["pageIndex"]
    }

    if (params["pageSize"] !== undefined) {
      queryParameters["pageSize"] = params["pageSize"]
    }

    if (params["title"] !== undefined) {
      queryParameters["title"] = params["title"]
    }

    if (params["sender"] !== undefined) {
      queryParameters["sender"] = params["sender"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["startTime"] !== undefined) {
      queryParameters["startTime"] = params["startTime"]
    }

    if (params["endTime"] !== undefined) {
      queryParameters["endTime"] = params["endTime"]
    }

    if (params["timeSort"] !== undefined) {
      queryParameters["timeSort"] = params["timeSort"]
    }

    if (params["completedStartTime"] !== undefined) {
      queryParameters["completedStartTime"] = params["completedStartTime"]
    }

    if (params["completedEndTime"] !== undefined) {
      queryParameters["completedEndTime"] = params["completedEndTime"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我发起的工作流实例列表前几条
   * @method
   * @name getWorkflowTaskInstanceLaunchTopList
   */
  getWorkflowTaskInstanceLaunchTopList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance/launch/top/{count}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{count}", params["count"])

    if (params["count"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: count"))
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据实例ID查询全部工作任务列表
   * @method
   * @name getWorkflowTaskByInstanceId
   */
  getWorkflowTaskByInstanceId: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据流程实例ID查询第一个任务信息
   * @method
   * @name getInstanceFirstTask
   */
  getInstanceFirstTask: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/instance/{instanceId}/first/task"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/json'

    path = path.replace("{instanceId}", params["instanceId"])

    if (params["instanceId"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: instanceId"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据实例ID或任务ID下载表单PDF
   * @method
   * @name exportFormPdf
   */
  exportFormPdf: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/pdf"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["taskId"] !== undefined) {
      queryParameters["taskId"] = params["taskId"]
    }

    if (params["instanceId"] !== undefined) {
      queryParameters["instanceId"] = params["instanceId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的待办延期督办提示数量
   * @method
   * @name getMyWorkflowTaskPrompt
   */
  getMyWorkflowTaskPrompt: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/prompt/mine"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询我的工作流任务列表前几条
   * @method
   * @name getWorkflowTaskTopList
   */
  getWorkflowTaskTopList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/top/{count}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{count}", params["count"])

    if (params["count"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: count"))
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["flowId"] !== undefined) {
      queryParameters["flowId"] = params["flowId"]
    }

    if (params["status"] !== undefined) {
      queryParameters["status"] = params["status"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询工作流任务
   * @method
   * @name getWorkflowTaskById
   */
  getWorkflowTaskById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据任务id查询流程轨迹
   * @method
   * @name getWorkflowTaskHistory
   */
  getWorkflowTaskHistory: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/task/{id}/history"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 查询工作流程树状结构（包含直属流程）
   * @method
   * @name getWorkflowTree
   */
  getWorkflowTree: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/tree"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    if (params["appKey"] !== undefined) {
      queryParameters["appKey"] = params["appKey"]
    }

    if (params["categoryId"] !== undefined) {
      queryParameters["categoryId"] = params["categoryId"]
    }

    if (params["categoryIds"] !== undefined) {
      queryParameters["categoryIds"] = params["categoryIds"]
    }

    if (params["allData"] !== undefined) {
      queryParameters["allData"] = params["allData"]
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询工作流程
   * @method
   * @name getWorkflowById
   */
  getWorkflowById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id删除工作流程
   * @method
   * @name deleteWorkflowById
   */
  deleteWorkflowById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/{id}"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 保存流程图 base64图片
   * @method
   * @name saveWorkflowImage
   */
  saveWorkflowImage: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/{id}/image"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'
    headers["Content-Type"] = 'application/x-www-form-urlencoded'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    if (params["base64"] !== undefined) {
      form["base64"] = params["base64"]
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询工作流程信息
   * @method
   * @name getWorkflowInfoById
   */
  getWorkflowInfoById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/{id}/info"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 发布工作流程
   * @method
   * @name publishWorkflowById
   */
  publishWorkflowById: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/{id}/publish"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'POST',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
  /**
   * 根据id查询步骤列表
   * @method
   * @name getWorkflowStepList
   */
  getWorkflowStepList: (params = {}) => {

    let domain = window.api.domain
    let path = "/workflow/{id}/step"
    let body = {}
    let queryParameters = {}
    let headers = {}
    let form = {}

    headers["Accept"] = 'application/json'

    path = path.replace("{id}", params["id"])

    if (params["id"] === undefined) {
      return Promise.reject(new Error("Missing required  parameter: id"))
    }

    let url = domain + path
    let options = {
      method: 'GET',
      url: url,
      params: queryParameters,
      data: body,
      headers: headers
    }
    if (Object.keys(form).length > 0) {
      options.data = qs.stringify(form);
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    return axios(options).then(ret => {
      return ret;
    });
  },
}