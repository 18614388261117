var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.readonly)?_c('el-upload',{staticClass:"sz-common-upload",class:{ 'limit-fill': _vm.limit == _vm.fileList.length },style:({ height: _vm.getUploadHeight }),attrs:{"action":_vm.actionUrl,"accept":_vm.accept,"name":_vm.name,"disabled":_vm.disabled,"multiple":_vm.multiple,"auto-upload":_vm.autoUpload,"list-type":_vm.listType,"limit":_vm.limit,"on-success":_vm.handleChange,"headers":_vm.headers,"on-preview":_vm.filePreview,"on-remove":_vm.removeFile,"file-list":_vm.fileList},scopedSlots:_vm._u([{key:"file",fn:function(file){return _c('div',{staticClass:"el-upload-list__item is-success"},[(_vm.listType === 'picture-card')?[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"preview-src-list":[file.file.url],"src":file.file.url}}),_c('i',{staticClass:"el-icon-delete",staticStyle:{"position":"absolute","top":"0","right":"0","color":"red","font-size":"36rpx"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.handleRemove(file.file)
}}})]:[_c('el-row',[_c('el-col',{attrs:{"span":16}},[_c('span',{staticClass:"el-upload-list__item-name"},[_c('i',{class:_vm.getIcoClass(file.file)}),_c('span',{on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.filePreview(file.file)
}}},[_vm._v(_vm._s(file.file.name))])])]),_c('el-col',{attrs:{"span":5}},[_c('span',{staticClass:"el-upload-list__item-name"},[_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.handleRemove(file.file)
}}})])])],1)]],2)}}],null,false,3279879628)},[_c('div',{staticClass:"sz-common-upload-header",style:({ top: _vm.getBtnTop }),attrs:{"slot":"default"},slot:"default"},[(!_vm.readonly)?_c('el-button',{staticClass:"sz-common-upload-btn",attrs:{"size":"small","type":"primary","icon":_vm.defaultConfig.icon}},[_vm._v("上传")]):_vm._e()],1)]):_c('div',{staticClass:"sz-common-upload-readonly"},[(_vm.fileList.length > 0)?_c('ul',{staticClass:"el-upload-list el-upload-list--text"},_vm._l((_vm.fileList),function(item){return _c('li',{staticClass:"el-upload-list__item is-success",style:({ backgroundColor: _vm.itemBg }),attrs:{"tabindex":"0"}},[(_vm.listType == 'picture-card')?[_c('el-image',{staticStyle:{"width":"200rpx","height":"200rpx","margin":"0 auto","display":"block"},attrs:{"src":_vm.imageHead + item.url,"preview-src-list":[_vm.imageHead + item.url],"fit":"cover"}})]:[_c('i',{class:_vm.getIcoClass(item),staticStyle:{"font-size":"14px","line-height":"29px"}}),_c('span',{staticClass:"el-upload-list__item-name",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.filePreview(item)
}}},[_vm._v(_vm._s(item.name))])]],2)}),0):_c('div',{staticStyle:{"height":"40px"}})])}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }