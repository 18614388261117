/**
 * 消息接口
 */
export default class MessageApi {
  constructor(vm) {
    this.vm = vm
  }

  // 查询当前用户的未读消息列表（分页）
  getUnreadMessagesList(params = {}) {
    return this.vm.$u.get('/api/message/user', params)
  }

  // 查询当前用户消息未读总数量(红点数量提示)
  getUnreadMessagesNumber() {
    if (this.vm.$store.getters.user.userId) {
      return this.vm.$u.get('/api/message/user/count').then(async(res) => {
        this.vm.$store.commit('user/SET_UNREAD', res || 0)
      });
    }
  }

  // 一键已读
  handleAllUnreadToRead() {
    return this.vm.$u.post('/api/message/user/read').then(() => {
      this.vm.$store.commit('user/SET_UNREAD', 0)
    })
  }

  // 用户根据消息id查询消息提醒详情
  handleUnreadToReadById({ id }) {
    return this.vm.$u.get(`/api/message/user/${id}`).then(() => {
      this.getUnreadMessagesNumber()
    })
  }
}
