var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticStyle:{"display":"flex"}},[(!_vm.isHiddenInput)?_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":_vm.placeholder,"disabled":true},model:{value:(_vm.userNames),callback:function ($$v) {_vm.userNames=$$v},expression:"userNames"}}):_vm._e(),_c('el-button',{staticClass:"add_btn",class:[_vm.readonly?'add_btn_readonly':''],attrs:{"disabled":_vm.readonly,"icon":"el-icon-circle-plus-outline"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.showUserDialog).apply(void 0, arguments)
}}})],1),(_vm.sortDialogVisible)?_c('el-dialog',{ref:"user_select_dialog",staticClass:"user_tree",attrs:{"title":_vm.title,"append-to-body":true,"visible":_vm.sortDialogVisible,"destroy-on-close":true},on:{"update:visible":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.sortDialogVisible=$event
}}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form',{attrs:{"inline":true}},[_c('el-input',{staticStyle:{"margin-top":"10px"},attrs:{"placeholder":"请输入内容","clearable":true},model:{value:(_vm.searchCondition.keyword),callback:function ($$v) {_vm.$set(_vm.searchCondition, "keyword", $$v)},expression:"searchCondition.keyword"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.searchUser).apply(void 0, arguments)
}},slot:"append"})],1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-tabs',{on:{"tab-click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleClick).apply(void 0, arguments)
}},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.tabConfigData.showAllOrganize)?_c('el-tab-pane',{attrs:{"label":_vm.tabConfigData.showAllOrganizeName,"name":"All"}}):_vm._e(),(_vm.tabConfigData.showCustomOrganize)?_c('el-tab-pane',{attrs:{"label":_vm.tabConfigData.showCustomOrganizeName,"name":"Ann"}}):_vm._e(),(_vm.tabConfigData.showAllWorkGroup)?_c('el-tab-pane',{attrs:{"label":_vm.tabConfigData.showAllWorkGroupName,"name":"WorkGroup"}}):_vm._e(),(_vm.tabConfigData.showPersonalWorkGroup)?_c('el-tab-pane',{attrs:{"label":_vm.tabConfigData.showPersonalWorkGroupName,"name":"MyWorkGroup"}}):_vm._e()],1)],1),_c('el-col',{staticStyle:{"min-width":"300px"}},[_c('div',{staticClass:"border_node_btn",staticStyle:{"height":"480px","margin-top":"10px"}},[(_vm.searchCondition.keyword===''&&_vm.activeName==='all')?_c('el-tree',{key:"tree_all",ref:"tree_all",staticClass:"left_tree",attrs:{"show-checkbox":_vm.isMulSelect,"node-key":"id","filter-node-method":_vm.filterNode,"highlight-current":true,"lazy":true,"load":_vm.loadNode,"expand-on-click-node":false,"default-expanded-keys":_vm.leftDefaultExpandKeys,"props":_vm.defaultProps},on:{"node-expand":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeExpand).apply(void 0, arguments)
},"node-collapse":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeCollapse).apply(void 0, arguments)
},"node-click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.nodeClick).apply(void 0, arguments)
},"check-change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftCheckChange).apply(void 0, arguments)
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('i',{class:_vm.icoClass(data)}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(data.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.type==='org'),expression:"data.type==='org'"}]},[_vm._v("("+_vm._s(data.userCount)+"人)")])])}}],null,false,3429914865)}):_vm._e(),(_vm.searchCondition.keyword===''&&_vm.activeName==='custom')?_c('el-tree',{key:"tree_custom",ref:"tree_custom",staticClass:"left_tree",attrs:{"show-checkbox":_vm.isMulSelect,"node-key":"id","filter-node-method":_vm.filterNode,"highlight-current":true,"lazy":true,"load":_vm.loadNode,"expand-on-click-node":false,"default-expanded-keys":_vm.leftDefaultExpandKeys,"props":_vm.defaultProps},on:{"node-expand":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeExpand).apply(void 0, arguments)
},"node-collapse":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeCollapse).apply(void 0, arguments)
},"node-click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.nodeClick).apply(void 0, arguments)
},"check-change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftCheckChange).apply(void 0, arguments)
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('i',{class:_vm.icoClass(data)}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(data.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.type==='org'),expression:"data.type==='org'"}]},[_vm._v("("+_vm._s(data.userCount)+"人)")])])}}],null,false,3429914865)}):_vm._e(),(_vm.searchCondition.keyword===''&&_vm.activeName==='workGroup')?_c('el-tree',{key:"tree_workGroup",ref:"tree_workGroup",staticClass:"left_tree",attrs:{"show-checkbox":_vm.isMulSelect,"node-key":"id","filter-node-method":_vm.filterNode,"highlight-current":true,"lazy":true,"load":_vm.loadNode,"expand-on-click-node":false,"default-expanded-keys":_vm.leftDefaultExpandKeys,"props":_vm.defaultProps},on:{"node-expand":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeExpand).apply(void 0, arguments)
},"node-collapse":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeCollapse).apply(void 0, arguments)
},"node-click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.nodeClick).apply(void 0, arguments)
},"check-change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftCheckChange).apply(void 0, arguments)
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('i',{class:_vm.icoClass(data)}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(data.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.type==='org'),expression:"data.type==='org'"}]},[_vm._v("("+_vm._s(data.userCount)+"人)")])])}}],null,false,3429914865)}):_vm._e(),(_vm.searchCondition.keyword===''&&_vm.activeName==='myWorkGroup')?_c('el-tree',{key:"tree_myWorkGroup",ref:"tree_myWorkGroup",staticClass:"left_tree",attrs:{"show-checkbox":_vm.isMulSelect,"node-key":"id","filter-node-method":_vm.filterNode,"highlight-current":true,"lazy":true,"load":_vm.loadNode,"expand-on-click-node":false,"default-expanded-keys":_vm.leftDefaultExpandKeys,"props":_vm.defaultProps},on:{"node-expand":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeExpand).apply(void 0, arguments)
},"node-collapse":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftTreeNodeCollapse).apply(void 0, arguments)
},"node-click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.nodeClick).apply(void 0, arguments)
},"check-change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftCheckChange).apply(void 0, arguments)
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('i',{class:_vm.icoClass(data)}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(data.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.type==='org'),expression:"data.type==='org'"}]},[_vm._v("("+_vm._s(data.userCount)+"人)")])])}}],null,false,3429914865)}):_vm._e(),_c('el-tree',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchCondition.keyword!==''),expression:"searchCondition.keyword!==''"}],ref:"treeLeftSearch",staticClass:"left_tree",attrs:{"show-checkbox":_vm.isMulSelect,"node-key":"id","highlight-current":true,"data":_vm.searchUsers,"expand-on-click-node":false,"props":_vm.defaultProps},on:{"node-click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.nodeClick).apply(void 0, arguments)
},"check-change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.leftCheckChange).apply(void 0, arguments)
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('i',{class:_vm.icoClass(data)}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(data.name))])])}}],null,false,1607191051)})],1)])],1),(_vm.containUser===1)?_c('el-row',{staticClass:"member_count",staticStyle:{"margin-top":"10px"}},[_c('el-col',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"16px"}},[_vm._v("总人数：")]),_c('span',{staticStyle:{"font-size":"16px","color":"red"}},[_vm._v(_vm._s(_vm.totalCount)+"人")])])],1):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.sortDialogVisible = false
}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.updateUsers).apply(void 0, arguments)
}}},[_vm._v("确 定")])],1)],1):_vm._e()],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }