/**
 * 通用接口
 */
export default class CommonApi {
  constructor(vm) {
    this.vm = vm
  }

  // 获取数据字典
  getDictTree({ parentId = 0, role }) {
    if (role) return this.vm.$u.get(`/api/dictionary/tree/${parentId}`, {}, { 'role': role });
    return this.vm.$u.get(`/api/dictionary/tree/${parentId}`);
  }
}
