var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-canvas',{ref:"canvas",staticClass:"signature__content",attrs:{"canvas-id":"handWriting"},on:{"touchstart":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.touchStart).apply(void 0, arguments)
},"touchmove":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.touchMove).apply(void 0, arguments)
},"touchend":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.touchEnd).apply(void 0, arguments)
}}})}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }