<template>
  <div class="city-rural-item" :class="[outSelectConfig.type]">
    <template v-if="outSelectConfig.type == 'text'">
      <el-input
        v-model="selectValue"
        :disabled="disabled || readonly"
        :placeholder="outSelectConfig.placeholder"
        @change="selectChange"
      ></el-input>
    </template>
    <template v-else>
      <el-select
        v-model="selectValue"
        :placeholder="outSelectConfig.placeholder"
        :filterable="outSelectConfig.filterable"
        :disabled="disabled || readonly"
        :size="outSelectConfig.size"
        :clearable="outSelectConfig.clearable"
        @change="selectChange"
      >
        <el-option
          v-for="item in outSelectOrigin"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </template>
    <div class="dec-text">{{ outSelectConfig.placeholder }}</div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "cityRuralItem",

  props: {
    // 选框数据源
    selectOrigin: {
      type: [Array],
      default: () => {
        return [];
      }
    },
    // 选框配置，（element组件配置）
    selectConfig: {
      type: [Object],
      default: () => {
        return {};
      }
    },
    // 选框关联标识
    selectSign: {
      type: [String, Number],
      default: ""
    },
    // 选框等级Level
    selectLevel: {
      type: [Number],
      default: 1
    },
    // 双向绑定值
    value: {
      type: [String, Number, Object, Boolean],
      default: ""
    },
    readonly: {
      type: [Boolean],
      default: false
    }, // 只读属性
    disabled: {
      type: [Boolean],
      default: false
    } // 只读属性
  },

  data() {
    return {
      // 输出数据源
      outSelectOrigin: [],
      // 默认配置（element组件配置）
      defaultSelectConfig: {
        placeholder: "请选择地址",
        filterable: true
      },
      outSelectConfig: {},
      // 选框数据
      selectValue: ""
    };
  },

  mounted() {
    this.init();
  },
  watch: {
    value: {
      handler(n) {
        if (!n) {
          return;
        }
        if (this.selectValue) {
          return;
        }
        let obj = n;
        if (typeof n == "string") {
          obj = JSON.parse(n);
        }
        this.$nextTick(() => {
          this.selectValue = obj.id || obj;
          this.outSelectOrigin = [obj];
          // this.$bus.$emit("citySelectChange", {
          //   value: obj.id,
          //   selectLevel: this.$props.selectLevel
          // });
          if (this.selectValue) {
            this.selectChange(this.selectValue);
          }
          this.$forceUpdate();
        });
      },
      immediate: true
    },
    selectConfig: {
      handler(n) {
        this.outSelectConfig = Object.assign(
          this.defaultSelectConfig,
          this.$props.selectConfig
        );
      },
      deep: true
    },
    selectLevel: {
      handler(n) {
        let obj = {
          1: {
            // placeholder: "省（自治区、直辖市）",
            type: "empty",
            selectLevel: 1
          },
          2: {
            // placeholder: "市（地）",
            type: "empty",
            selectLevel: 2
          },
          3: {
            // placeholder: "县（市、区）",
            type: "empty",
            selectLevel: 3
          },
          4: {
            // placeholder: "乡（镇、街道）",
            type: "line",
            selectLevel: 4
          },
          5: {
            // placeholder: "村（社区）",
            type: "line",
            selectLevel: 5
          },
          6: {
            // placeholder: "户籍地址",
            type: "text",
            selectLevel: 6
          }
        };
        Object.assign(this.defaultSelectConfig, obj[n]);
        if (n == 1) {
          this.$nextTick(() => {
            Object.assign(this.defaultSelectConfig, obj[n]);
          });
          this.getSelectOrigin({ level: 1, pid: 0 });
          return;
        } else {
          this.outSelectOrigin = [];
        }
      },
      immediate: true
    }
  },
  methods: {
    // 初始化
    init() {
      this.setInitData();
      this.$bus.$on("citySelectChange", (obj = {}) => {
        let { value, selectLevel } = obj;
        if (this.selectLevel == selectLevel + 1) {
          this.getSelectOrigin({ level: this.selectLevel, pid: value });
        }
      });
    },
    // 处理外部传入的数据
    setInitData() {
      this.outSelectOrigin = _.cloneDeep(this.$props.selectOrigin);
      this.outSelectConfig = Object.assign(
        this.defaultSelectConfig,
        this.$props.selectConfig
      );
      this.selectValue = this.$props.value;
    },
    // 拿取级联数据
    getSelectOrigin(obj = {}) {
      let { level, pid } = obj;
      this.API.getSysAreaByLevelAndPid({ level, pid }).then(res => {
        let { data } = res;
        this.outSelectOrigin = data;
        this.$forceUpdate();
      });
    },
    // 选框事件
    selectChange(value) {
      let obj = this.outSelectOrigin.filter(item => {
        return item.id == value;
      })[0];
      if (!obj) {
        this.$emit("input", value);
        return;
      }
      let { id, name } = obj;
      this.$emit("input", JSON.stringify({ id, name }));
      this.$bus.$emit("citySelectChange", {
        value: id,
        selectLevel: this.$props.selectLevel
      });
    }
  }
};
</script>

<style lang="scss" scoped scoped>
.city-rural-item {
  display: flex;
  align-items: center;
  .dec-text {
    margin-left: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
</style>
