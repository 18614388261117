const modulesFiles = require.context('./api/', false, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
	const value = modulesFiles(modulePath)
	modules[moduleName] = value.default
	return modules
}, {})

export let apisEnum = {}
export function getApis(vm) {
	if (Object.keys(apisEnum).length) {
		return apisEnum
	}
	const apis = {}

	for (const key in modules) {
		const value = modules[key]
		apis[key] = new value(vm)
	}

	apisEnum = apis
	
	return apis
}

const install = (Vue, vm) => {
	const apis = getApis(vm)
	// 将各个定义的接口名称，统一放进对象挂载到vm.$u.api(因为vm就是this，也即this.$u.api)下
	vm.$u.api = apis;
}

export default {
	install
}