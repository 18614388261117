var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"city-rural-item",class:[_vm.outSelectConfig.type]},[(_vm.outSelectConfig.type == 'text')?[_c('el-input',{attrs:{"disabled":_vm.disabled || _vm.readonly,"placeholder":_vm.outSelectConfig.placeholder},on:{"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.selectChange).apply(void 0, arguments)
}},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}})]:[_c('el-select',{attrs:{"placeholder":_vm.outSelectConfig.placeholder,"filterable":_vm.outSelectConfig.filterable,"disabled":_vm.disabled || _vm.readonly,"size":_vm.outSelectConfig.size,"clearable":_vm.outSelectConfig.clearable},on:{"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.selectChange).apply(void 0, arguments)
}},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},_vm._l((_vm.outSelectOrigin),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],_c('div',{staticClass:"dec-text"},[_vm._v(_vm._s(_vm.outSelectConfig.placeholder))])],2)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }