const getters = {
  user: state => state.user.user,
  serverUser: state => state.user.serverUser,
  adminUser: state => state.user.adminUser,
  disabledPersonInfo: state => state.user.disabledPersonInfo,
  userInfo: state => state.user.userInfo,
  token: state => state.user.token,
  serverToken: state => state.user.serverToken,
  adminToken: state => state.user.adminToken,
  avater: state => state.user.avater,
  openid: state => state.user.openid,
  dict: state => state.user.dict,
  unread: state => state.user.unread,
  formDataMap: state => state.workflow.formDataMap,
  supervisionItem: state => state.workflow.supervisionItem,
  mergeFormData: state => state.formDesign.mergeFormData,
  formItemChildren: state => state.formDesign.formItemChildren,
  applianceDot: state => state.provider.applianceDot,
}
export default getters
