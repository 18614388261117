// 仅OA工作流使用
import Vue from "vue";
import axios from "axios";
import store from "../store";

import api from "@/api/api";

Vue.prototype.API = api;
// Todo 将中断器重构成可配置项
axios.interceptors.request.use(
  function(config) {
    config.headers.token = store.getters.token || "";
    config.headers.userRole = "USER";
    config.headers.version = "1.0.0";
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    Vue.prototype.$u.toast(error.response.data ? error.response.data.message : '网络请求失败,请检测网络情况!');
  }
)
